const to2 = (string: string | number) => `0${string}`.slice(-2);

export const format = (date: string | number | Date, format: string) => {
    const newDate = typeof date === 'object' ? date : new Date(date);

    return (
        format
            .replace('hh', to2(newDate.getHours()))
            .replace('mm', to2(newDate.getMinutes()))
            .replace('ss', to2(newDate.getSeconds()))

            .replace('h', newDate.getHours().toString())
            .replace('m', newDate.getMinutes().toString())
            .replace('s', newDate.getSeconds().toString())

            .replace('DD', to2(newDate.getDate()))
            .replace('MM', to2(newDate.getMonth() + 1))

            .replace('MON', newDate.toLocaleString('default', { month: 'short' }))
            .replace('MONTH', newDate.toLocaleString('default', { month: 'long' }))

            // .replace('D', newDate.getDate().toString())
            // .replace('M', newDate.getMonth().toString())

            .replace('YYYY', newDate.getFullYear().toString())
            .replace('YY', newDate.getFullYear().toString().substring(2))
    );
};
