import axios from 'axios';

import { AxiosResponseError } from './types';

export const response: Parameters<typeof axios.interceptors.response.use> = [
    (value) => {
        return {
            code: value.status,
            message: value.data?.message,
            data: value.data?.data,
        };
    },
    (error: AxiosResponseError | string) => {
        if (typeof error === 'string')
            return {
                code: 0,
                message: error,
            };
        if (typeof error === 'object') {
            return {
                code: error.response?.status,
                message: error.response?.data?.message,
                data: error.response?.data?.data?.errors,
            };
        }
    },
];
