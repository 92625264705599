import { useModalContext } from '@cyberia-studio/react-modal';
import { useEffect, useState } from 'react';

import { Icon } from '@/assets/icon';
import { Dialogs, PolicyWarning } from '@/components/partials';
import { api } from '@/services';
import { RegisterRequest } from '@/services/api/methods/requests/ind/types';
import { useGeneralStore } from '@/shared/lib/stores';
import useForm from '@/shared/modules/form';
import { useFormContext } from '@/shared/modules/form/modules';
import { Button, Input, Textarea } from '@/shared/ui';
import { PhoneInput } from '@/shared/ui/phone-input';
import { CalculateForm } from '@/types';

import S from './styles.module.scss';
import { validateOptions } from './validate';

export const IndividualCalc: React.FC = () => {
    const { data } = useFormContext<CalculateForm>();
    const { reg, ifValid, set } = useForm<RegisterRequest>();
    const currentTab = useGeneralStore((state) => state.currentTab);
    const [isLoading, setLoading] = useState(false);
    const controls = useModalContext();

    const onSubmit = () => {
        ifValid((data) => {
            setLoading(true);

            api.requests()
                .ind()
                .register(data)
                .onSuccess(({ message }) => {
                    controls.set(<Dialogs.OrderResult text={message} />);
                    setLoading(false);
                })
                .onErrors(() => {
                    setLoading(false);
                });
        }, validateOptions);
    };

    useEffect(() => {
        if (!controls.isOpen) return;

        set(
            (prev) => ({
                ...prev,
                from: currentTab === 'ltl' ? data.fromCityAddress : data.ftlfromCityAddress,
                to: currentTab === 'ltl' ? data.toCityAddress : data.ftltoCityAddress,
                cargo_description: currentTab && data[`${currentTab}OptionDescription`],
            }),
            false
        );
    }, [controls.isOpen, currentTab]);

    return (
        <div className={S.individual}>
            <div className={S.close}>
                <Icon.Close className={S.close__icon} onClick={() => controls?.close()} />
            </div>
            <h2 className={S.individual__title}>Для индивидуального расчета стоимости доставки заполните форму</h2>
            <Input {...reg('from')} label="Откуда" placeholder="Введите город отправки" className={S.input} />
            <Input {...reg('to')} label="Куда" placeholder="Введите город доставки" className={S.input} />
            <Textarea {...reg('cargo_description')} label="Информация о грузе" placeholder="Введите текст" className={S.input} />
            <Input {...reg('name')} label="Имя" placeholder="Введите имя" className={S.input} />
            <PhoneInput {...reg('phone')} label="Телефон" placeholder="+7 (000) 000-00-00" />
            <Button className={S.individual__button} theme="accent" onClick={onSubmit} disabled={isLoading}>
                Отправить заявку
            </Button>
            <PolicyWarning align="center" />
        </div>
    );
};

export { validateOptions as individualValidateOptions } from './validate';
