import { Icon, SVG } from './template';

export const Plus = Icon(({ size = 30, width = size, height = size, ...props }, ref) => (
    <SVG {...props} width={width} height={height} ref={ref}>
        <path
            d="M15 27.5C21.875 27.5 27.5 21.875 27.5 15C27.5 8.125 21.875 2.5 15 2.5C8.125 2.5 2.5 8.125 2.5 15C2.5 21.875 8.125 27.5 15 27.5Z"
            fill="#F6AE02"
        />
        <path d="M14.9999 20.0028V9.99722M20.0026 15H9.99707" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </SVG>
));
