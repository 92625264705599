import React, { memo, useEffect, useId, useState } from 'react';

import { InputBase } from '@/shared/ui';

import { InputProps } from './types';

export const Input = memo(
    React.forwardRef<HTMLInputElement, InputProps>(
        ({ readOnly, onClick, onChange, value = '', regex, className, errors, name, label, required, ...props }, ref) => {
            const [state, setState] = useState<string>(value);
            const elementId = useId();

            useEffect(() => setState(value), [value]);

            const handleChange = (value: string) => {
                if (value !== '' && regex && !regex.test(value)) return;

                setState(value);
                onChange && onChange(value);
            };
            const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value);

            return (
                <InputBase
                    htmlFor={elementId}
                    className={className}
                    errors={errors}
                    label={label}
                    ref={ref}
                    required={required}
                    aria-label={props['aria-label'] as string}
                    name={name}
                >
                    <input {...props} onChange={handleInputChange} readOnly={readOnly} onClick={onClick} value={state} id={elementId} />
                </InputBase>
            );
        }
    )
);
