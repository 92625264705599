/* eslint-disable no-debugger */
import { useTrustedState } from '@cyberia-studio/react-hooks';
import React, { useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { InputBase } from '@/shared/ui';

import { NumberInputProps } from './types';

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
    (
        {
            onChange,
            max = Number.MAX_SAFE_INTEGER,
            min = 0,
            integer,
            className,
            errors,
            name,
            label,
            precision = 2,
            required,
            value,
            negative,
            onBlur,
            ...props
        },
        ref
    ) => {
        const elementId = useMemo(() => uuid(), []);

        const valueParsed = value === undefined ? '' : value.toString();

        const [localValue, setLocalValue, isTrusted] = useTrustedState(valueParsed, !valueParsed);

        useEffect(() => {
            setLocalValue(valueParsed, false);
        }, [valueParsed]);

        useEffect(() => {
            if (!isTrusted) return;

            onChange?.(localValue ? +localValue : undefined);
        }, [localValue]);

        const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
            setLocalValue((prev) => {
                const currentValue = e.target.value.replaceAll(',', '.');

                if (currentValue === '') return '';

                const numberValue = integer ? Number.parseInt(currentValue) : Number.parseFloat(currentValue);

                if (Number.isNaN(numberValue)) return prev;
                if ((max && numberValue > max) || (min && numberValue < min)) return prev;

                if (!new RegExp(`^${negative ? '(-)?' : ''}\\d+${integer ? '' : `(\\.\\d{0,${precision}})?`}$`).test(currentValue)) return prev;

                return currentValue;
            });
        };

        const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
            setLocalValue(integer ? localValue : (+localValue).toFixed(precision));
            onBlur?.(event);
        };

        return (
            <InputBase errors={errors} className={className} label={label} htmlFor={elementId} ref={ref} required={required}>
                <input {...props} name={name} value={localValue} onInput={handleInput} onBlur={handleBlur} id={elementId} />
            </InputBase>
        );
    }
);
