import { Icon, SVG } from './template';

export const Info = Icon(({ size = 16, width = size, height = size, ...props }, ref) => (
    <SVG {...props} width={width} height={height} ref={ref}>
        <path
            d="M8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M8 11V7.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M8 4.625C8.34518 4.625 8.625 4.90482 8.625 5.25C8.625 5.59518 8.34518 5.875 8 5.875C7.65482 5.875 7.375 5.59518 7.375 5.25C7.375 4.90482 7.65482 4.625 8 4.625Z"
            fill="currentColor"
        />
    </SVG>
));
