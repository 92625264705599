import { YandexMaps } from '@/services';
import { Cities, Location, Terminals } from '@/types';

export type AddressState = {
    address?: string;
    terminal?: Terminals.Terminal;
    city?: Cities.City;
};

export type PlaceSelectProps = {
    direction: Location;
};

export enum PlaceTab {
    Terminal,
    Address,
}

export interface MapRef {
    map: ymaps.Map;
    api: YandexMaps.YMaps;
}
