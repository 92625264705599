import { useEvents } from '@cyberia-studio/react-hooks';
import { useEffect, useMemo, useRef, useState } from 'react';

import { YMaps } from './types';

type Callback = (api: YMaps) => void | (() => void);

export const useReady = (cb: Callback) => {
    const [isLoaded, setLoaded] = useState(false);
    const cbRef = useRef<Callback | null | undefined>(undefined);

    useEffect(() => {
        if (!window.ymaps) return;

        setLoaded(true);
    }, []);

    const api = useMemo(() => window.ymaps as YMaps, [isLoaded]);

    useEffect(() => {
        if (cbRef.current === null) return;

        cbRef.current = cb;

        if (!api) return;

        const tempCb = cbRef.current;
        cbRef.current = null;

        return tempCb(api);
    }, [api]);
};

export const useApi = (cb?: Callback): [undefined, false] | [YMaps, true] => {
    const apiRef = useRef<YMaps | undefined>(undefined);
    const [isLoaded, setLoaded] = useState(false);

    const handleLoad = () => {
        if (apiRef.current !== undefined) return;

        const api = window.ymaps;

        if (!api) return;

        apiRef.current = api as YMaps;

        setLoaded(true);
    };

    useEvents('yandex-api-loaded', handleLoad, document, []);

    useEffect(handleLoad, []);

    useEffect(() => {
        if (isLoaded) cb?.(apiRef.current as YMaps);
    }, [isLoaded]);

    return apiRef.current ? [apiRef.current, true] : [undefined, false];
};
