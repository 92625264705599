import { useRefEffect } from '@cyberia-studio/react-hooks';
import cn from 'classnames';
import { FC, useRef } from 'react';

import { Tools } from '@/services';
import { useCurrentStore } from '@/shared/lib/hooks';
import { useFormContext } from '@/shared/modules/form/modules';
import { Checkbox, DatePicker, PageUI, Select } from '@/shared/ui';
import { CalculateForm, Entity as EntityType } from '@/types';

import { Entity } from './parts';
import S from './styles.module.scss';

const timeArray = Array(10)
    .fill(undefined)
    .map((_, index) => 10 + index);
const timeOptions = Array.from(timeArray, (_v, i) => ({ id: i, time: `${`0${timeArray[i]}`.slice(-2)}:00` }));

export const Registration: FC<{ tabId: string }> = ({ tabId }) => {
    const { reg, set, data, field } = useFormContext<CalculateForm>();
    const { isScrolled, isOrderDataLoaded } = useCurrentStore();
    const isScrolledRef = useRef(false);

    const blockRef = useRef<HTMLDivElement>(null);

    useRefEffect(
        (block) => {
            if (!isScrolled || isScrolledRef.current || isOrderDataLoaded) {
                return;
            }

            const offsetTop = block.offsetHeight;

            if (!offsetTop) {
                return;
            }

            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
            isScrolledRef.current = true;

            return;
        },
        blockRef,
        [isScrolled]
    );

    const autocomplete = (name: EntityType.Types) => {
        set(
            (prev) => ({
                ...prev,
                [`${name}Type`]: prev['customerType'],
                [`${name}CompanyPhone`]: prev['customerCompanyPhone'],
                [`${name}CompanyName`]: prev['customerCompanyName'],
                [`${name}CompanyOrganization`]: prev['customerCompanyOrganization'],
                [`${name}CompanyEmail`]: prev['customerCompanyEmail'],
                [`${name}CompanyTin`]: prev['customerCompanyTin'],
                [`${name}PersonPhone`]: prev['customerPersonPhone'],
                [`${name}PersonName`]: prev['customerPersonName'],
                [`${name}PersonIssuedBy`]: prev['customerPersonIssuedBy'],
                [`${name}PersonPassportDate`]: prev['customerPersonPassportDate'],
                [`${name}PersonPassportRegistrationAddress`]: prev['customerPersonPassportRegistrationAddress'],
                [`${name}PersonPassportSerial`]: prev['customerPersonPassportSerial'],
            }),
            false
        );
    };

    const handleUpdate = () => {
        if (data.customerIsSender) autocomplete('sender');
        if (data.customerIsRecipient) autocomplete('recipient');
    };

    const handleResetChange = (name: EntityType.Types) => {
        const getEntity = (entity: EntityType.Types) => {
            const entityType = data[`${entity}Type`] || EntityType.Type.Person;
            const propertyName = `${entity}${entityType === EntityType.Type.Company ? 'Company' : 'Person'}` as `${EntityType.Types}Company` &
                `${EntityType.Types}Person`;

            return {
                type: entityType,
                name: data[`${propertyName}Name`],
                tin: data[`${propertyName}Tin`],
                organization: data[`${propertyName}Organization`],
                phone: data[`${propertyName}Phone`],
                email: data[`${propertyName}Email`],
                passportSerial: data[`${propertyName}PassportSerial`],
                passportDate: data[`${propertyName}PassportDate`],
                passportRegistrationAddress: data[`${propertyName}PassportRegistrationAddress`],
                issuedBy: data[`${propertyName}IssuedBy`],
            };
        };

        const compareEntity = (entities: EntityType.Types[]) => {
            return entities.every((entity) => {
                const entityData = getEntity(entity);

                return entities.every((currentEntity) => {
                    const currentEntityData = getEntity(currentEntity);

                    return Object.entries(entityData).every(([key]) => {
                        const property = key as keyof ReturnType<typeof getEntity>;

                        return entityData[property] === currentEntityData[property];
                    });
                });
            });
        };

        const fieldIs = field(`customerIs${Tools.string.uppercaseFirstLetter(name)}` as 'customerIsSender' | 'customerIsRecipient');
        const currentValue = fieldIs.value();

        if (currentValue === true) {
            fieldIs.value(compareEntity(['customer', name]));
        }

        handleUpdate();
    };

    return (
        <PageUI.Block className={cn(S.registration, { [S.registration__hidden]: !isScrolled })} id={tabId} ref={blockRef}>
            <PageUI.BlockItem title="Оформление заявки">
                <PageUI.Row>
                    <div className={S.time}>
                        <DatePicker text="Дата отправки груза" placeholder="дд.мм.гггг" {...reg('sendDate')} pastSelect={false} required />
                        <Select label="Время отправки груза" placeholder="00:00" {...reg('sendTime')}>
                            {timeOptions.map(({ id, time }) => ({
                                id,
                                value: time,
                                name: time,
                            }))}
                        </Select>
                    </div>
                </PageUI.Row>
            </PageUI.BlockItem>
            <Entity
                name="customer"
                title="Заказчик"
                onChange={handleUpdate}
                inForm={
                    <Select {...reg('payer')} label="Плательщик" placeholder="Укажите плательщика" required>
                        {[
                            {
                                id: 1,
                                name: 'Заказчик',
                                value: EntityType.Kind.Customer,
                            },
                            {
                                id: 2,
                                name: 'Отправитель',
                                value: EntityType.Kind.Sender,
                            },
                            {
                                id: 3,
                                name: 'Получатель',
                                value: EntityType.Kind.Recipient,
                            },
                        ]}
                    </Select>
                }
            >
                <div className={S.autocomplete}>
                    <Checkbox {...reg('customerIsSender', { onChange: handleUpdate })}>Заказчик является отправителем</Checkbox>
                    <Checkbox {...reg('customerIsRecipient', { onChange: handleUpdate })}>Заказчик является получателем</Checkbox>
                </div>
            </Entity>
            <Entity name="sender" title="Отправитель" onChange={() => handleResetChange('sender')} />
            <Entity name="recipient" title="Получатель" onChange={() => handleResetChange('recipient')} />
        </PageUI.Block>
    );
};
