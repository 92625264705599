import { useTrustedState } from '@cyberia-studio/react-hooks';
import cn from 'classnames';
import { memo, useCallback, useEffect, useMemo } from 'react';

import S from './styles.module.scss';
import { CheckboxModuleProps, CheckboxProps } from './types';

const Text = memo<CheckboxModuleProps>(({ children }) => <label className={S.text}>{children}</label>);

const Additional = memo<CheckboxModuleProps>(({ children }) => <span className={cn(S.text, S.text__additional)}>{children}</span>);

export const Checkbox = memo<CheckboxProps>(({ children, onChange, className, theme, disabled, value = false }) => {
    const [checked, setChecked, isTrusted] = useTrustedState(value, value !== undefined);

    const content = useMemo(() => {
        if (Array.isArray(children))
            return (
                <>
                    <Text>{children[0]}</Text>
                    <Additional>{children[1]}</Additional>
                </>
            );

        return <Text>{children}</Text>;
    }, [children]);

    useEffect(() => {
        setChecked(value, false);
    }, [value]);

    useEffect(() => {
        if (isTrusted && onChange) onChange(checked);
    }, [checked]);

    const handleClick = useCallback(() => {
        setChecked((prev) => !prev, true);
    }, []);

    return (
        <button className={cn(S.checkbox, S[`theme__${theme}`], { [S.checkbox__disabled]: disabled }, className)} onClick={handleClick}>
            <div className={cn(S.box, { [S.box__selected]: checked })}>
                <div className={S.check}></div>
            </div>
            <div className={S.content}>{content}</div>
        </button>
    );
});
