import cn from 'classnames';
import React, { forwardRef } from 'react';

import S from './styles.module.scss';
import { ButtonProps } from './types';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ children, width = 'content', theme = 'default', size = 'default', className, ...props }, ref) => {
        const buttonClassName = cn(className, S.Button, S[`ButtonTheme_${theme}`], S[`ButtonSize_${size}`], S[`ButtonWidth_${width}`]);

        return (
            <button {...props} className={buttonClassName} ref={ref}>
                {children}
            </button>
        );
    }
);
