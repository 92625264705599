import cn from 'classnames';
import { FC } from 'react';

import { useFormContext } from '@/shared/modules/form/modules';
import { CalculateForm, Vehicles } from '@/types';

import S from './style.module.scss';

export const Vehicle: FC<Vehicles.Vehicle> = ({ id, is_demo, ...vehicle }) => {
    const { data, field } = useFormContext<CalculateForm>();

    const isChecked = id === data.vehicleId;

    const handleSelect = () => {
        field('vehicleId').value(id);
    };

    return (
        <label className={cn(S.vehicle, { [S.vehicle_disabled]: is_demo })}>
            <input
                checked={isChecked}
                disabled={is_demo}
                className={cn('visually-hidden', S.vehicle__radio)}
                type="radio"
                name="vehicle-type"
                onChange={handleSelect}
            />
            <div className={cn(S.vehicle__inner)}>
                <h3 className={S.vehicle__title}>{vehicle.name}</h3>
                <dl className={S.vehicle__props}>
                    <dt className={S.vehicle__dt}>Вес, т</dt>
                    <dd className={S.vehicle__dd}>{Math.round((vehicle.max_weight / 1000) * 1000) / 1000}</dd>
                    <dt className={S.vehicle__dt}>
                        Объем,м<sup>3</sup>
                    </dt>
                    <dd className={S.vehicle__dd}>{vehicle.max_volume}</dd>
                    <dt className={S.vehicle__dt}>Габариты,м</dt>
                    <dd className={S.vehicle__dd}>{`${vehicle.max_length} x ${vehicle.max_width} x ${vehicle.max_height}`}</dd>
                    <dt className={S.vehicle__dt}>Палет, шт</dt>
                    <dd className={S.vehicle__dd}>{vehicle.max_pallets}</dd>
                </dl>
                <span className={cn(S.vehicle__icon)} aria-hidden>
                    <img className={S['vehicle__icon-image']} src={vehicle.icon} alt="" aria-hidden />
                </span>
            </div>
        </label>
    );
};
