import useModal from '@cyberia-studio/react-modal';
import cn from 'classnames';
import React, { useEffect, useMemo } from 'react';

import { Icon } from '@/assets/icon';
import { Dialogs, PolicyWarning, Preloader, WidgetIndividual } from '@/components/partials';
import { setDialog } from '@/helpers';
import { useCurrentRegister, useCurrentStore } from '@/shared/lib/hooks';
import { useFtlStore, useGeneralStore, useLtlStore } from '@/shared/lib/stores';
import { isMultiCargoValid, isSingleCargoValid } from '@/shared/lib/stores/calculate/ltl';
import { useFormContext } from '@/shared/modules/form/modules';
import { Button } from '@/shared/ui';
import { CalculateForm, Cargo } from '@/types';

import { Delivery, Option } from './parts';
import S from './styles.module.scss';
import { SidebarState } from './types';

export const Sidebar = React.forwardRef<HTMLElement>((_, ref) => {
    const { currentTab } = useGeneralStore();
    const { isCompleted, isHidden, isLoading, isScrolled, isSpecial, setScrolled } = useCurrentStore();
    const ltl = useLtlStore();
    const ftl = useFtlStore();
    const { data, shouldUpdate, field } = useFormContext<CalculateForm>();

    const [modal, controls] = useModal();
    const [isRegistrationLoading, register] = useCurrentRegister(controls);

    const currentVehicle = ftl.vehicles.find((vehicle) => vehicle.id === data.vehicleId);

    const state = useMemo<SidebarState>(() => {
        if (isHidden) return 'hidden';
        if (isSpecial || (currentTab === 'ftl' && ftl.isVehiclesSpecial)) return 'special';
        if (isCompleted) return 'completed';

        return 'empty';
    }, [ltl, isCompleted, isHidden, isSpecial, currentTab, shouldUpdate, ftl.isVehiclesSpecial]);

    useEffect(() => {
        field('tariff').value((prev) => {
            if (!prev) return ltl.deliveries.at(0)?.id;

            return ltl.deliveries.find(({ id }) => prev === id)?.id;
        });
    }, [ltl.deliveries]);

    const handleSelect = (deliveryId: number) => {
        field('tariff').value(deliveryId);
    };

    const cParameters = useMemo(() => {
        const { cargoMultiItems = [], cargoType, cargoSingle } = data;

        const parameters = (() => {
            if (cargoType === Cargo.Type.SINGLE) {
                if (!isSingleCargoValid(cargoSingle)) return;

                const { places, volume, weight } = cargoSingle;

                return <p className={S.parameters__item}>{`Мест ${places}, вес ${weight} кг, объем ${volume} м³`}</p>;
            }

            if (cargoType === Cargo.Type.MULTI) {
                return cargoMultiItems
                    .map((item) => {
                        if (!isMultiCargoValid(item)) return undefined;

                        const { places = 1, weight = 1, volume = 1, id } = item;

                        return (
                            <p className={S.parameters__item} key={`parameter-${id}`}>{`Мест ${places}, вес ${
                                Math.ceil(weight * places * 100) / 100
                            } кг, объем ${Math.ceil(volume * places * 100) / 100} м³`}</p>
                        );
                    })
                    .filter((item) => item !== undefined);
            }

            return <></>;
        })();

        return (
            <>
                {currentTab === 'ltl' && (
                    <div className={S.parameters}>
                        <h3 className={S.parameters__title}>Параметры груза</h3>
                        <div className={S.parameters__items}>{parameters}</div>
                    </div>
                )}
                {currentTab === 'ftl' && !!currentVehicle && (
                    <div className={S.parameters}>
                        <h3 className={S.parameters__title}>Тип автомобиля</h3>
                        <p className={S.parameters__item}>{currentVehicle.name}</p>
                    </div>
                )}
            </>
        );
    }, [shouldUpdate]);

    const [fromAddress, toAddress] = currentTab === 'ltl' ? [data.fromCityAddress, data.toCityAddress] : [data.ftlfromCityAddress, data.ftltoCityAddress];

    const cContent = (() => {
        switch (state) {
            case 'special':
                return (
                    <>
                        <Icon.Calculator className={S.icon} />
                        <p className={S.description}>
                            <span onClick={setDialog(controls, <Dialogs.IndividualCalc />)}>Данное направление рассчитывается индивидуально</span>
                        </p>
                    </>
                );
            case 'completed': {
                const currentDelivery = (() => {
                    if (currentTab === 'ltl') return ltl.deliveries.find((item) => item.id === data.tariff);

                    return ftl.delivery;
                })();

                const entries = currentDelivery?.entries.filter((entry) => entry.name !== 'НДС');

                return (
                    <>
                        <div>
                            <div className={S.place}>
                                {fromAddress}
                                <Icon.ArrowRight className={S.arrow} />
                                {toAddress}
                            </div>
                            {cParameters}
                        </div>
                        {currentDelivery && (
                            <div className={S.prices__wrapper}>
                                <div className={S.prices}>
                                    <h3 className={S.prices__title}>Расчёт стоимости</h3>
                                    <div className={S.prices__items}>
                                        {entries?.map((item) => (
                                            <Option {...item} key={`delivery-${item.key}`} />
                                        ))}
                                    </div>
                                </div>
                                <div className={S.prices__calculated}>
                                    <h3>Итого</h3>
                                    <span>{currentDelivery.total} ₽ </span>
                                </div>
                            </div>
                        )}

                        {currentTab === 'ltl' && (
                            <div className={S.deliveries}>
                                {ltl.deliveries.map((item) => (
                                    <Delivery {...item} key={`delivery-${item.id}`} isSelected={data.tariff === item.id} onSelect={handleSelect} />
                                ))}
                            </div>
                        )}

                        {isScrolled ? (
                            <>
                                <Button theme="accent" className={S.button} onClick={register} disabled={isRegistrationLoading}>
                                    Отправить заявку
                                </Button>
                                <PolicyWarning />
                            </>
                        ) : (
                            <Button theme="accent" className={S.button} onClick={() => setScrolled(true)}>
                                Оформить заявку
                            </Button>
                        )}
                    </>
                );
            }

            case 'empty':
                return (
                    <>
                        <Icon.Calculator className={S.icon} />
                        <p className={S.description}>Для расчета стоимости перевозки груза укажите пункты отправления и прибытия, а также параметры груза.</p>
                    </>
                );
        }
    })();

    return (
        <aside className={cn(S.sidebar, S[`state__${state}`])} ref={ref}>
            {modal}
            <WidgetIndividual widgetClassName={S.individual} />
            <div className={S.calculation}>
                <div className={cn(S.wrapper, { [S.default]: state === 'special' || state === 'empty' })}>
                    <Preloader className={S.preloader} isLoading={isLoading} />
                    <div className={S.content}>{cContent}</div>
                </div>
                <div className={S.warning}>
                    <p className={S.warning__text}>* Стоимость доставки рассчитывается ориентировочно, точную стоимость вам сообщит менеджер</p>
                </div>
            </div>
        </aside>
    );
});

export type { SidebarState };
