import { v4 as uuid } from 'uuid';

import { api, Tools } from '@/services';
import { useLtlStore } from '@/shared/lib/stores';
import { Code } from '@/shared/modules/api/types';
import { CalculateForm } from '@/types';

import { parseAddresses, parseAddressWithoutCity, parseCargo, parsePersons, parseTerminal } from './utils';

const loadCitiesIfNotLoaded = async () => {
    const cities = useLtlStore.getState().cities;

    if (cities.length) {
        return;
    }

    const response = await api.info().cities().promise;

    if (response.code !== Code.Success) {
        return;
    }

    useLtlStore.setState({
        cities: response.data.items.map((city) => ({
            ...city,
            terminals: city.terminals.map((terminal) => ({
                ...terminal,
                schedules: terminal.schedules.map((item) => ({ id: uuid(), content: item })),
                contacts: terminal.contacts.map((item) => ({ id: uuid(), content: item })),
            })),
        })),
    });
};

const loadLtlBxOrderIfNotLoaded = async (bxId: string | null) => {
    const { isOrderDataLoaded, city, orderDataLoadError } = useLtlStore.getState();

    if (!bxId || isOrderDataLoaded || orderDataLoadError?.bx24id === bxId) {
        return;
    }

    const response = await api.requests().ltl().getCalculateByBxId(bxId).promise;

    if (response.code !== Code.Success) {
        useLtlStore.setState({
            orderDataLoadError: {
                bx24id: bxId,
                responseCode: response.code,
            },
        });
        return;
    }

    const {
        customer,
        sender,
        recipient,
        terminal,
        route: { city_from_id: cityFromId, city_to_id: cityToId },
        cargo,
        cargo_type: cargoType,
        departure_date,
        address_from,
        address_to,
        payment_type,
        general_options,
        cargo_description,
        is_fragile,
        is_oversized,
        insurance_mem_price,
        tariff_id,
        payer,
    } = response.data;

    const addresses = parseAddresses({ cityFromId, cityToId, getCityById: city.byId });
    const cargos = parseCargo({ cargo, cargo_type: cargoType });
    const terminals = parseTerminal(terminal);
    const persons = parsePersons({ customer, recipient, sender });

    const customerIsRecipient = customer && recipient ? JSON.stringify(customer) === JSON.stringify(recipient) : undefined;
    const customerIsSender = customer && recipient ? JSON.stringify(customer) === JSON.stringify(sender) : undefined;

    const sendDate = departure_date ? Tools.date.create(departure_date * 1000).toGMT() : undefined;

    useLtlStore.setState({
        isOrderDataLoaded: true,
    });

    return Object.fromEntries(
        Object.entries({
            ...addresses,
            ...cargos,
            ...terminals,
            ...persons,
            ltlfromAddress: parseAddressWithoutCity({ address: address_from, city: addresses.fromCityAddress }),
            ltltoAddress: parseAddressWithoutCity({ address: address_to, city: addresses.toCityAddress }),
            fromAddress: parseAddressWithoutCity({ address: address_from, city: addresses.fromCityAddress }),
            toAddress: parseAddressWithoutCity({ address: address_to, city: addresses.toCityAddress }),
            ltlPaymentMethod: payment_type,
            ltlPaymentTin: customer?.tin || undefined,
            customerIsRecipient,
            customerIsSender,
            sendDate: sendDate && Tools.date.format(sendDate, 'DD.MM.YYYY'),
            // sendDate: sendDate && `${sendDate.getDate()}.${sendDate.getMonth() + 1}.${sendDate.getFullYear().toString().slice(-2)}`,
            sendTime: sendDate && `${sendDate.getHours().toString().padStart(2, '0')}:00`,
            ltlOptions: general_options,
            ltlOptionDescription: cargo_description,
            ltlOptionIsFragile: !!is_fragile,
            ltlOptionIsOversized: !!is_oversized,
            ltlOptionIsInsured: !!insurance_mem_price,
            ltlOptionInsurance: +insurance_mem_price,
            tariff: tariff_id,
            payer: payer,
        }).filter(([_, value]) => value !== undefined && value !== null)
    ) as Partial<CalculateForm>;
};

export const loadLtl = async ({ bxId }: { bxId: string | null }) => {
    await loadCitiesIfNotLoaded();
    const [orderData] = await Promise.all([loadLtlBxOrderIfNotLoaded(bxId)]);

    return {
        ...orderData,
    };
};
