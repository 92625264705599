export enum OptionType {
    Regular = 1,
    Discount = 2,
    Hidden = 3,
}

export interface Option {
    name: string;
    price: number;
    full_price?: number;
    type?: OptionType;
    is_stage?: boolean;
}

export interface OptionExtended extends Option {
    key: string;
}
