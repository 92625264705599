import { memo, useCallback, useMemo } from 'react';

import { useGeneralStore } from '@/shared/lib/stores';
import { useFormContext } from '@/shared/modules/form/modules';
import { Checkbox, Tooltip } from '@/shared/ui';
import { CalculateForm, Options } from '@/types';

import { BaseGeneralPart } from '../../../types';
import S from './styles.module.scss';

export const Item = memo<Options.Option & Omit<BaseGeneralPart, 'title'>>(({ name, price, price_type, tooltip = '', id, tab }) => {
    const { shouldUpdate, data, field } = useFormContext<CalculateForm>();
    const priceTypes = useGeneralStore((state) => state.optionPriceTypes);

    const dataName = useMemo(() => `${tab}Options` as const, [tab]);

    const isSelected = useMemo(() => {
        const options = data[dataName];

        if (!options) return false;

        return options.includes(id);
    }, [shouldUpdate]);

    const handleChange = (value: boolean) => {
        field(dataName).value((prev = []) => {
            if (value) {
                if (prev.includes(id)) return prev;

                return [...prev, id];
            }

            return prev.filter((option) => option !== id);
        });
    };

    const getSymbol = useCallback((option: number) => priceTypes.find((item) => item.id === option)?.symbol ?? '', [priceTypes]);

    const cTooltip = useMemo(() => <Tooltip>{tooltip}</Tooltip>, [tooltip]);

    return (
        <div>
            <div className={S.item}>
                <Checkbox value={isSelected} onChange={handleChange}>
                    {[name, price ? `+${price} ${getSymbol(price_type)}` : 'без предоплаты']}
                </Checkbox>
                {cTooltip}
            </div>
        </div>
    );
});
