import { IconProps } from './types';

export const BurgerClose: React.FC<IconProps> = ({ className, height = 24, width = 24 }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.6095 0.390524C24.1302 0.911223 24.1302 1.75544 23.6095 2.27614L2.27614 23.6095C1.75544 24.1302 0.911223 24.1302 0.390524 23.6095C-0.130175 23.0888 -0.130175 22.2446 0.390524 21.7239L21.7239 0.390524C22.2446 -0.130175 23.0888 -0.130175 23.6095 0.390524Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.390524 0.390524C0.911223 -0.130175 1.75544 -0.130175 2.27614 0.390524L23.6095 21.7239C24.1302 22.2446 24.1302 23.0888 23.6095 23.6095C23.0888 24.1302 22.2446 24.1302 21.7239 23.6095L0.390524 2.27614C-0.130175 1.75544 -0.130175 0.911223 0.390524 0.390524Z"
                fill="currentColor"
            />
        </svg>
    );
};
