import { FC } from 'react';

import { useFormContext } from '@/shared/modules/form/modules';
import { DatePicker, Input, MaskInput } from '@/shared/ui';
import { PhoneInput } from '@/shared/ui/phone-input';
import { CalculateForm } from '@/types';

import { BaseEntityProps } from '../types';

export const Person: FC<BaseEntityProps> = ({ name, onChange, inForm }) => {
    const { reg } = useFormContext<CalculateForm>();

    return (
        <>
            <Input {...reg(`${name}PersonName`, { onChange })} label="ФИО" placeholder="Введите ФИО" />
            <PhoneInput {...reg(`${name}PersonPhone`, { onChange })} label="Телефон" placeholder="Введите телефон" required />
            <MaskInput {...reg(`${name}PersonPassportSerial`, { onChange })} label="Серия и номер паспорта" placeholder="0000 000000" mask="9999 999999" />
            <Input {...reg(`${name}PersonPassportRegistrationAddress`, { onChange })} label="Адрес регистрации" placeholder="Введите адрес" />
            <Input {...reg(`${name}PersonIssuedBy`, { onChange })} label="Кем выдан" placeholder="Введите кем выдан паспорт" />
            <DatePicker {...reg(`${name}PersonPassportDate`, { onChange })} text="Дата выдачи" placeholder="дд.мм.гггг" reverse setUndefinedValue />
            {inForm}
        </>
    );
};
