import { api, Tools } from '@/services';
import { useFtlStore } from '@/shared/lib/stores';
import { Code } from '@/shared/modules/api/types';
import { CalculateForm } from '@/types';

import { parsePersons } from './utils';

const loadFtlBxOrderIfNotLoaded = async (bxId: string | null) => {
    const { isOrderDataLoaded, orderDataLoadError } = useFtlStore.getState();

    if (!bxId || isOrderDataLoaded || orderDataLoadError?.bx24id === bxId) {
        return;
    }

    const response = await api.requests().ftl().getCalculateByBxId(bxId).promise;

    if (response.code !== Code.Success) {
        useFtlStore.setState({
            orderDataLoadError: {
                bx24id: bxId,
                responseCode: response.code,
            },
        });
        return;
    }

    const { data } = response;
    const { customer, sender, recipient } = data;

    const persons = parsePersons({ customer, recipient, sender });

    const customerIsRecipient = customer && recipient ? JSON.stringify(customer) === JSON.stringify(recipient) : undefined;
    const customerIsSender = customer && recipient ? JSON.stringify(customer) === JSON.stringify(sender) : undefined;
    const sendDate = data.departure_date ? Tools.date.create(data.departure_date * 1000).toGMT() : undefined;

    useFtlStore.setState({
        isOrderDataLoaded: true,
    });

    return Object.fromEntries(
        Object.entries({
            ...persons,
            ftlfromAddress: data.address_from,
            ftltoAddress: data.address_to,
            ftlfromCityAddress: data.address_from,
            ftltoCityAddress: data.address_to,
            ftlPaymentMethod: data.payment_type,
            ftlPaymentTin: customer?.tin || undefined,
            customerIsRecipient,
            customerIsSender,
            sendDate: sendDate && Tools.date.format(sendDate, 'DD.MM.YYYY'),
            sendTime: sendDate && `${sendDate.getHours().toString().padStart(2, '0')}:00`,
            ftlOptions: data.result.options.general_options,
            ftlOptionDescription: data.cargo_description,
            payer: data.result.registration.entities.payer,
            vehicleId: data.car_id,
        }).filter(([, value]) => value !== undefined && value !== null)
    ) as Partial<CalculateForm>;
};

export const loadFtl = async ({ bxId }: { bxId: string | null }) => {
    const [orderData] = await Promise.all([loadFtlBxOrderIfNotLoaded(bxId)]);

    return {
        ...orderData,
    };
};
