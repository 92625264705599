import '@/styles/index.scss';

import { FC, Fragment, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { YandexMaps } from '@/services';
import { ChildrenProps } from '@/types';

const GlobalLayout: FC<Partial<ChildrenProps>> = ({ children }) => {
    useEffect(() => {
        YandexMaps.load();
        // return () => YandexMaps.unload();
    }, []);

    return (
        <Fragment>
            <Outlet />
            {children}
        </Fragment>
    );
};

export default GlobalLayout;
