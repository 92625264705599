import { CalculateForm, Cities } from '@/types';

export const parseAddresses = ({
    cityFromId,
    cityToId,
    getCityById,
}: {
    cityFromId: number | undefined;
    cityToId: number | undefined;
    getCityById: (id: number) => Cities.CityExtended | undefined;
}): Partial<Pick<CalculateForm, 'fromCity' | 'toCity' | 'ltlfromCityAddress' | 'ltltoCityAddress' | 'fromCityAddress' | 'toCityAddress'>> => {
    const fromCity = cityFromId !== undefined ? getCityById(cityFromId) : undefined;
    const toCity = cityToId !== undefined ? getCityById(cityToId) : undefined;

    const transformCity = (city: Cities.CityExtended | undefined): Cities.City | undefined => {
        if (!city) {
            return;
        }

        return {
            ...city,
            terminals: city.terminals.map(({ contacts, schedules, ...terminal }) => ({
                ...terminal,
                contacts: contacts.map(({ content }) => content),
                schedules: schedules.map(({ content }) => content),
            })),
        };
    };

    return {
        fromCity: transformCity(fromCity),
        toCity: transformCity(toCity),
        ltlfromCityAddress: fromCity?.name,
        ltltoCityAddress: toCity?.name,
        fromCityAddress: fromCity?.name,
        toCityAddress: toCity?.name,
    };
};

export const parseAddressWithoutCity = ({ address, city }: { address: string; city: string | undefined }) => {
    const pattern = new RegExp(`^${city}, `);

    if (city && address.startsWith(city)) {
        return address.replace(pattern, '');
    }

    return address;
};
