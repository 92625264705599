import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';

import { api } from '@/services';
import { useGeneralStore } from '@/shared/lib/stores';
import { Code } from '@/shared/modules/api/types';

import { loadFtl } from './loadFtl';
import { loadLtl } from './loadLtl';

const getQuery = (request: LoaderFunctionArgs['request']) => {
    return new URL(request.url).searchParams;
};

const loadDictionariesIfNotLoaded = async () => {
    const optionPriceTypes = useGeneralStore.getState().optionPriceTypes;

    if (optionPriceTypes.length) {
        return;
    }

    const response = await api.dictionary().optionPriceType().promise;

    if (response.code !== Code.Success) {
        return;
    }

    useGeneralStore.setState({
        optionPriceTypes: response.data.items,
    });
};

export const calculatorLoader = async ({ request }: LoaderFunctionArgs) => {
    const query = getQuery(request);
    const currentTab = query.get('tab') === 'ftl' ? 'ftl' : 'ltl';
    const bxOrderId = query.get('bx24_id');

    useGeneralStore.setState({
        currentTab,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, data] = await Promise.all([loadDictionariesIfNotLoaded(), currentTab === 'ftl' ? loadFtl({ bxId: bxOrderId }) : loadLtl({ bxId: bxOrderId })]);

    return {
        data,
    };
};

export const useCalculatorLoaderData = () => {
    return useLoaderData() as Awaited<ReturnType<typeof calculatorLoader>>;
};
