import { create } from 'zustand';

import { resolveValue } from '@/shared/modules/form/modules/functions';

import { LtlStore, LtlStoreSet } from './types';

export const useLtlStore = create<LtlStore & LtlStoreSet>((set, get) => ({
    deliveries: [],
    isLoading: false,
    isCompleted: false,
    isSpecial: false,
    isHidden: false,
    isScrolled: false,
    isOversized: false,
    cities: [],
    options: [],
    isOrderDataLoading: false,
    isOrderDataLoaded: false,
    isCitiesDataLoading: false,
    isOptionsDataLoading: true,
    orderDataLoadError: null,

    setOrderDataLoading: (value: boolean) => set(({ isOrderDataLoading }) => ({ isOrderDataLoading: resolveValue(isOrderDataLoading, value) })),
    setOrderDataLoaded: (value: boolean) => set(({ isOrderDataLoaded }) => ({ isOrderDataLoaded: resolveValue(isOrderDataLoaded, value) })),
    setCitiesDataLoading: (value: boolean) => set(({ isCitiesDataLoading }) => ({ isCitiesDataLoading: resolveValue(isCitiesDataLoading, value) })),
    setOptionsDataLoading: (value: boolean) => set(({ isOptionsDataLoading }) => ({ isOptionsDataLoading: resolveValue(isOptionsDataLoading, value) })),
    set: (value) => set(({ deliveries }) => ({ deliveries: resolveValue(deliveries, value), isLoading: false })),
    setCompleted: (value) =>
        set(({ isCompleted }) => ({
            isCompleted: resolveValue(isCompleted, value),
        })),
    setSpecial: (value) => set(({ isSpecial }) => ({ isSpecial: resolveValue(isSpecial, value) })),
    setHidden: (value) => set(({ isHidden }) => ({ isHidden: resolveValue(isHidden, value) })),
    setScrolled: (value) => set(({ isScrolled }) => ({ isScrolled: resolveValue(isScrolled, value) })),
    setOversized: (value) => set(({ isOversized }) => ({ isOversized: resolveValue(isOversized, value) })),
    setLoading: (value) => set(({ isLoading }) => ({ isLoading: resolveValue(isLoading, value) })),
    setCities: (value) => set(({ cities }) => ({ cities: resolveValue(cities, value) })),
    setOptions: (value) => set(({ options }) => ({ options: resolveValue(options, value) })),
    city: {
        byId: (id) => get().cities.find((city) => city.id === id),
        byName: (name) => get().cities.find((city) => city.name === name),
        terminal: {
            byId: (id) =>
                get()
                    .cities.find((city) => city.terminals.some((terminal) => terminal.id === id))
                    ?.terminals.find((terminal) => terminal.id === id),
            default: (cityId) =>
                get()
                    .cities.find((city) => city.id === cityId)
                    ?.terminals.at(0),
        },
    },
}));
