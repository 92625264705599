// import { Tools } from '@/Services';

import { CustomDate } from './class';

export const create = (date?: number | 'now' | undefined, offset?: number | 'month' | '-month') => {
    // let offsetNum = 0;

    // if (offset)

    // return new Date(typeof date === 'string' ? undefined : date);

    // let created: number;

    // if (date === 'now' || date === undefined) created = new Date().getTime();
    // else created = new Date(date).getTime();

    // if (offset) {
    //     let offsetNum = 0;

    //     if (typeof offset === 'number') offsetNum = offset;
    //     else {
    //         switch (offset) {
    //             case 'month':
    //                 offsetNum = Tools.date.groups.month;
    //                 break;
    //             case '-month':
    //                 offsetNum = -Tools.date.groups.month;
    //                 break;
    //         }
    //     }

    //     created += offsetNum;
    // }

    return new CustomDate(date, offset);
};
