import { Icon, SVG } from './template';

export const Constellator = Icon(({ size = 30, width = size, height = size, ...props }, ref) => (
    <SVG {...props} width={width} height={height} ref={ref}>
        <circle cx="18" cy="24" r="5" stroke="currentColor" strokeWidth="1.5" />
        <circle cx="6" cy="26" r="3" stroke="currentColor" strokeWidth="1.5" />
        <circle cx="18" cy="24" r="2" stroke="currentColor" strokeWidth="1.5" />
        <path
            d="M20 19V16.8462C20 16.2939 19.5523 15.8462 19 15.8462H13.3292C13.1154 15.8462 12.9072 15.7776 12.7352 15.6506L10.7648 14.1956C10.5928 14.0685 10.3846 14 10.1708 14H2C1.44772 14 1 14.4477 1 15V25C1 25.5523 1.44772 26 2 26H2.5M13 26H9"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3 13.2857V4C3 3.44772 3.44772 3 4 3H11.3996C11.8335 3 12.218 3.2799 12.3513 3.6929L16 15"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M20 1V11C20 11.5523 20.4477 12 21 12H29" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M23 8V4C23 3.44772 23.4477 3 24 3H27C27.5523 3 28 3.44772 28 4V8C28 8.55228 27.5523 9 27 9H24C23.4477 9 23 8.55228 23 8Z"
            fill="#FCCD5E"
            stroke="#FCCD5E"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </SVG>
));
