import { LocateStrings } from './types';

export const translate: LocateStrings = {
    from: {
        label: 'Откуда',
        placeholder: 'Введите город, улицу и дом',
    },
    to: {
        label: 'Куда',
        placeholder: 'Введите город, улицу и дом',
    },
};
