import cn from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { api } from '@/services';
import { useSearch } from '@/shared/lib/hooks/useDebouncedInputChange';
import { useFormContext } from '@/shared/modules/form/modules';
import { Input, PageUI, Select, Tooltip } from '@/shared/ui';
import { CalculateForm, Payment } from '@/types';
import { Entity } from '@/types';

import { BaseGeneralPart } from '../types';
import S from './styles.module.scss';
import { tinValidate } from './validate';

export const PaymentForm: FC<BaseGeneralPart> = ({ tab, title }) => {
    const { ifValid, reg, set, field } = useFormContext<CalculateForm>();
    const [searchParams] = useSearchParams();
    const searchParamsTin = searchParams.get('tin') || undefined;

    const [tinFound, setTinFound] = useState<boolean>();

    const [dataTin, dataMethod] = useMemo(() => [`${tab}PaymentTin`, `${tab}PaymentMethod`] as const, [tab]);

    const searchTin = () => {
        ifValid((data) => {
            const tin = data[dataTin];

            if (!tin) {
                return;
            }

            api.info()
                .client(tin)
                .onSuccess(({ email, issued_by, name, organization, passport_date, passport_registration_address, passport_serial, phone, tin, type }) => {
                    set((prev) => {
                        const getQueryType = (): Entity.Type | undefined => {
                            const type = searchParams.get('customer');

                            if (type === 'company') {
                                return Entity.Type.Company;
                            }

                            if (type === 'person') {
                                return Entity.Type.Person;
                            }
                        };

                        return {
                            ...prev,
                            customerCompanyEmail: searchParams.get('customer_email') || email || '',
                            customerCompanyName: searchParams.get('customer_name') || name || '',
                            customerCompanyOrganization: searchParams.get('customer_organization') || organization || '',
                            customerCompanyPhone: searchParams.get('customer_phone') || phone || '',
                            customerCompanyTin: searchParams.get('customer_tin') || tin || '',
                            customerPersonIssuedBy: searchParams.get('customer_issued_by') || issued_by || '',
                            customerPersonName: searchParams.get('customer_name') || name || '',
                            customerPersonPassportDate: searchParams.get('customer_passport_date') || passport_date || '',
                            customerPersonPassportRegistrationAddress:
                                searchParams.get('customer_passport_registration_address') || passport_registration_address || '',
                            customerPersonPassportSerial: searchParams.get('customer_passport_serial') || passport_serial || '',
                            customerPersonPhone: searchParams.get('customer_phone') || phone || '',
                            customerType: getQueryType() || type,
                        };
                    });
                    setTinFound(true);
                })
                .onNotFound(() => {
                    setTinFound(false);
                    field(dataTin).errors(['ИНН не найден']);
                });
        }, tinValidate).onErrors(() => setTinFound(false));
    };

    const { setSearch, handleInput, search } = useSearch({
        delay: 500,
        onSearch: searchTin,
    });

    useEffect(() => {
        set((prev) => ({ ...prev, ltlPaymentTin: searchParamsTin }));
        setSearch(searchParamsTin);
        setTimeout(() => searchTin(), 100);
    }, [searchParamsTin]);

    return (
        <PageUI.BlockItem title={title}>
            <PageUI.Row>
                <Select<Payment.Type> label="Способ расчета" {...reg(dataMethod)}>
                    {[
                        {
                            id: 1,
                            name: 'Безналичный расчет с НДС',
                            value: Payment.Type.CASHLESS_VAT,
                        },
                        {
                            id: 2,
                            name: 'Безналичный расчет без НДС',
                            value: Payment.Type.CASHLESS_VATLESS,
                        },
                        {
                            id: 3,
                            name: 'Оплата картой',
                            value: Payment.Type.BANK_CARD,
                        },
                    ]}
                </Select>
                <div className={S.tin}>
                    <Input
                        {...reg(dataTin)}
                        onInput={handleInput}
                        value={search}
                        className={cn(S.input, { [S.tin_found]: tinFound })}
                        label={
                            <div className={S.tin__label}>
                                <span>{'ИНН (для юрлиц)'}</span>
                                <Tooltip className={S.tooltip}>
                                    Заполните, если являетесь нашим действующим клиентом, для применения персонального тарифа
                                </Tooltip>
                            </div>
                        }
                        aria-label={'ИНН (для юрлиц)'}
                        placeholder="Введите ИНН"
                        regex={/^\d+$/}
                    />
                    {tinFound && <span className={S.tin_found_label}>ИНН успешно найден</span>}
                </div>
            </PageUI.Row>
        </PageUI.BlockItem>
    );
};
