import { useRefEffect } from '@cyberia-studio/react-hooks';
import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import S from './styles.module.scss';

export type ScrollProps = {
    children?: React.ReactNode;
    className?: string;
};

export const Scroll: React.FC<ScrollProps> = ({ className, children }) => {
    const scrollbarRef = useRef<HTMLDivElement>(null),
        thumbRef = useRef<HTMLDivElement>(null),
        blockRef = useRef<HTMLDivElement>(null);
    const [isDragging, setDrag] = useState(false),
        [height, setHeight] = useState(0),
        [thumbHeight, setThumbHeight] = useState(100),
        [scroll, setScroll] = useState(0),
        [blockHeight, setBlockHeight] = useState(0);

    useRefEffect(
        (block) => {
            const scrollHeight = block.scrollHeight - 30;

            setHeight(scrollHeight);
        },
        blockRef,
        [children]
    );

    useEffect(() => {
        setThumbHeight((blockHeight * 100) / height);
    }, [height, blockHeight]);

    const handleResize = (entries: ResizeObserverEntry[]) => {
        const block = entries[0];

        if (!block) return;

        setBlockHeight(block.contentRect.height - 30);
    };

    useRefEffect(
        (block) => {
            const observer = new ResizeObserver(handleResize);

            observer.observe(block);

            return () => {
                observer.disconnect();
            };
        },
        blockRef,
        [height]
    );

    const handleMouseMove = (event: MouseEvent) => {
        const block = blockRef.current;

        if (!block) return;

        const offset = event.clientY - block.getBoundingClientRect().top - 7;

        block.scroll({
            top: (offset * height) / blockHeight,
            behavior: 'auto',
        });
    };

    useEffect(() => {
        if (isDragging) document.addEventListener('mousemove', handleMouseMove);
        else document.removeEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isDragging, blockHeight, height]);

    useEffect(() => {
        document.addEventListener('mouseup', handleStop);

        return () => {
            document.removeEventListener('mouseup', handleStop);
        };
    }, []);

    const handleDragStart = (event: React.MouseEvent) => {
        event.preventDefault();

        setDrag(true);
    };
    const handleStop = () => setDrag(false);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollHeight = event.currentTarget.scrollTop;

        if (scrollHeight < 0) setScroll(0);
        else if (scrollHeight > height - 14) setScroll(height - 14);
        else setScroll(scrollHeight);
    };

    const offset = useMemo(() => (scroll * 100) / height, [scroll, height]);

    const hasScroll = useMemo(() => {
        const block = blockRef.current;

        if (!block) return;

        return block.scrollHeight > 240;
    }, [blockHeight]);

    return (
        <div className={cn(S['custom-scroll'], { [S.hidden_thumb]: !hasScroll }, className)}>
            <div className={S['custom-scroll__scroll-box']}>
                <div className={S['custom-scroll__scrollbar']} ref={scrollbarRef} style={{ height: blockHeight }}>
                    <div
                        className={S['custom-scroll__scrollbar-thumb']}
                        ref={thumbRef}
                        onMouseDown={handleDragStart}
                        onMouseUp={handleStop}
                        style={{ height: `${thumbHeight}%`, top: `${offset}%` }}
                    />
                </div>
            </div>
            <div className={S['custom-scroll__content']} ref={blockRef} onScroll={handleScroll}>
                {children}
            </div>
        </div>
    );
};
