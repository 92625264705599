import cn from 'classnames';
import { FC } from 'react';

import { Icon } from '@/assets/icon';
import { Preloader } from '@/components/partials';
import { YandexMaps } from '@/services';
import { PageUI } from '@/shared/ui';
import { ButtonWrapper } from '@/shared/ui';

import S from './styles.module.scss';
import { PlacesProps } from './types';

export const Places: FC<PlacesProps> = ({ from, to, onSwap }) => {
    const [, isYmapsLoaded] = YandexMaps.useApi();

    return (
        <PageUI.BlockItem title="Направление">
            <PageUI.Row className={cn(S.place)}>
                <Preloader className={S.place__preloader} isLoading={!isYmapsLoaded} />
                <div className={S.place__icon}>
                    {from}
                    <ButtonWrapper className={S.swap} onClick={onSwap}>
                        <Icon.Exchange className={S.swap__icon} />
                    </ButtonWrapper>
                </div>
                {to}
            </PageUI.Row>
        </PageUI.BlockItem>
    );
};
