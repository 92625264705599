import { useCallback, useMemo, useRef, useState } from 'react';

import { resolveValue } from '../functions';
import { toStateFormat } from '../functions/toStateFormat';
import { validator } from '../functions/validator';
import { useRender } from '../hooks';
import {
    BaseField,
    CallHandler,
    ErrorFn,
    Errors,
    ErrorsFn,
    FieldFunc,
    Form,
    FormChangeEvent,
    FormFieldChangeEvent,
    IfValid,
    Options,
    Register,
    RuleMessage,
    Set,
    UseForm,
    Validate,
    ValidateOptionAll,
    ValidateOptions,
} from './types';

export const useForm: UseForm = <Data>(initialValue?: Partial<Data>, options?: Options<Partial<Data>>) => {
    const [isLoading, setLoading] = useState(false);

    // types
    type FormData = Partial<Data>;

    // local

    const defaultField = useMemo(() => ({ errors: undefined, value: undefined, required: undefined }), []);

    // handlers
    const callHandler: CallHandler<FormData> = useCallback((event, handler) => {
        if (event.isTrusted && handler) handler(event as FormChangeEvent & FormFieldChangeEvent<Data>);
    }, []);

    const handleFormChange = useCallback(
        (event: FormChangeEvent) => {
            form.current.isTrusted = event.isTrusted;

            form.current.data = Object.fromEntries(
                Object.entries(form.current.state)
                    .filter(([key]) => key !== 'error' && key !== 'isTrusted')
                    .map(([key, field]) => {
                        if (key !== 'error' && key !== 'isTrusted') return [key, (field as unknown as BaseField<unknown>).value];
                        return [];
                    })
            ) as FormData;

            callHandler(event, options?.onChange);
            render();
        },
        [callHandler]
    );
    const handleFieldChange = useCallback(
        (event: FormFieldChangeEvent<FormData>) => {
            const { type, target, isTrusted } = event;

            form.current.isTrusted = isTrusted;

            if (type === 'value' && event.isTrusted) {
                form.current.error = undefined;
                form.current.state[target.name].errors = undefined;
            }

            form.current.data[target.name] = target.value as FormData[keyof FormData];

            callHandler(event, options?.onFieldChange);
            render();
        },
        [callHandler]
    );
    //
    const getField = useCallback(
        <N extends keyof FormData>(name: N): BaseField<FormData[N]> => ((form.current.state[name] as BaseField<FormData[N]>) ??= { ...defaultField }),
        []
    );

    // state & hooks
    const [shouldUpdate, render] = useRender();

    // const state = useRef<FormState<FormData>>({ ...toStateFormat(initialValue as FormData) });
    // const dirty = useRef<FormData>(initialValue as FormData);

    const form = useRef<Form<FormData>>({
        data: (initialValue ?? {}) as FormData,
        state: { ...toStateFormat(initialValue as FormData, options?.validate) },
        error: undefined,
        isTrusted: true,
    });

    // returns

    const set: Set<FormData> = useCallback((value, isTrusted = true) => {
        form.current.state = toStateFormat(resolveValue(form.current.data, value));

        handleFormChange({ isTrusted, type: 'data', targetType: 'form' });
    }, []);

    const reg: Register<FormData> = useCallback(
        (name, options) => {
            const field = getField(name);

            return {
                ...field,
                onChange: (value) => {
                    (form.current.state[name] ??= { ...defaultField }).value = value;

                    const event: FormFieldChangeEvent<FormData> = { isTrusted: true, targetType: 'field', target: { ...field, name }, type: 'value' };

                    handleFieldChange(event);
                    if (options && options.onChange) options.onChange(event);
                },
                name,
            };
        },
        [shouldUpdate, handleFieldChange, options?.onChange, getField]
    );

    const field: FieldFunc<FormData> = useCallback(
        (name) => {
            type N = typeof name;

            const field = getField(name);

            return {
                value: function (value, isTrusted = true) {
                    if (arguments.length === 0) return field.value as FormData[N];

                    const res = resolveValue(form.current.state[name].value, value as FormData[N]);

                    form.current.state[name].value = res;

                    handleFieldChange({ isTrusted, targetType: 'field', target: { ...form.current.state[name], name }, type: 'value' });

                    return res as FormData[N];
                },
                errors: function (value, isTrusted = true) {
                    if (arguments.length === 0) return field.errors;

                    const res = resolveValue(form.current.state[name].errors, value);

                    form.current.state[name].errors = res;

                    handleFieldChange({ isTrusted, targetType: 'field', target: { ...form.current.state[name], name }, type: 'errors' });

                    return res;
                },
                required: function (value, isTrusted = true) {
                    if (arguments.length === 0) return field.required;

                    const res = resolveValue(form.current.state[name].required, value);

                    form.current.state[name].required = res;

                    handleFieldChange({ isTrusted, targetType: 'field', target: { ...form.current.state[name], name }, type: 'required' });

                    return res;
                },
            };
        },
        [handleFieldChange]
    );

    const errors: ErrorsFn<Data> = useCallback(
        (value, isTrusted = true) => {
            const res = resolveValue(
                Object.fromEntries(
                    Object.entries(form.current.state).map(([name, field]): [keyof Data, Errors] => [name as keyof Data, (field as BaseField<unknown>).errors])
                ) as Record<keyof Data, Errors>,
                value
            );

            Object.entries(res).forEach(([key, value]) => {
                const name = key as keyof Data;
                const errors = value as Errors;

                (form.current.state[name] ??= { ...defaultField }).errors = errors;
            });

            handleFormChange({ isTrusted, targetType: 'form', type: 'errors' });
        },
        [handleFormChange]
    );

    const error: ErrorFn = useCallback(
        (value, isTrusted = true) => {
            form.current.error = resolveValue(form.current.error, value);

            handleFormChange({ isTrusted, targetType: 'form', type: 'error' });
        },
        [handleFormChange]
    );

    const validate: Validate<Data> = useCallback(
        <VOD extends Partial<ValidateOptions<Data>>>(validateOptions?: VOD) => {
            error(undefined);
            errors({});

            const { data, errors: errorList } = validator<FormData, Data>(form.current.state, validateOptions ?? (options?.validate as typeof validateOptions));

            const errs = Object.entries(errorList);

            if (errs.length === 0) {
                return {
                    isValid: true,
                    errors: {},
                    data: data as {
                        [name in keyof Data]: VOD[name] extends ValidateOptionAll<Data, Data[name]>
                            ? VOD[name]['required'] extends RuleMessage<infer RV>
                                ? RV extends true
                                    ? Data[name]
                                    : Data[name] | undefined
                                : Data[name] | undefined
                            : Data[name] | undefined;
                    },
                };
            }

            errs.forEach(([name, err]) => {
                (form.current.state[name as keyof FormData] ??= { ...defaultField }).errors = err as Errors;
            });

            render();

            return {
                isValid: false,
                errors: errorList,
                data: data as { [name in keyof Data]: Data[name] | undefined },
            };
        },
        [error, errors]
    );

    const ifValid: IfValid<Data> = useCallback(
        (fn, options) => {
            const { data, errors, isValid } = validate(options);

            if (isValid === false) {
                return {
                    onErrors: (cb) => {
                        cb(errors);
                    },
                };
            }

            const cbRes = fn(data);

            if (cbRes instanceof Promise) {
                cbRes.finally(() => {
                    setLoading(true);
                });
            }

            return {
                onErrors: () => {
                    //
                },
            };
        },
        [validate]
    );

    const clear = useCallback(() => {
        form.current.data = {};
        form.current.error = undefined;
        form.current.isTrusted = true;
        form.current.state = { ...toStateFormat(initialValue as FormData, options?.validate) };

        render();
    }, []);

    const data = useMemo(() => form.current.data, [shouldUpdate]);

    return { reg, form, set, field, validate, ifValid, errors, error, clear, shouldUpdate, isLoading, data };
};
