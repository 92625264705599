import cn from 'classnames';
import { useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { memo } from '@/shared/lib/generic';

import S from './styles.module.scss';
import { TabsProps, TabType } from './types';

export const Tabs = memo(<T extends TabType[]>({ children, onChange, value, tabClassName }: TabsProps<T>) => {
    const [query, setQuery] = useSearchParams();

    const defaultTab = useMemo<string | undefined>(
        () =>
            (
                (value && children.find(({ path }) => path === value)) ??
                children.find(({ path }) => path === query.get('tab')) ??
                children.find(({ is_default }) => is_default === true) ??
                children[0]
            )?.path,
        [children]
    );

    const prevTab = useRef<string | null>(null);

    useEffect(() => {
        if (!value) return;

        handleTab(value);
    }, [value]);

    useEffect(() => {
        if (!defaultTab) {
            return;
        }

        query.set('tab', defaultTab);

        setQuery(query, { replace: true });
        onChange?.(defaultTab);
    }, []);

    const currentTab = useMemo(() => {
        const queryTab = query.get('tab') ?? '';

        return children.find((item) => item.path === queryTab)?.path;
    }, [query, onChange]);

    useEffect(() => {
        if (!currentTab || currentTab === prevTab.current) {
            prevTab.current = currentTab || null;

            return;
        }

        prevTab.current = currentTab;

        onChange?.(currentTab);
    }, [currentTab]);

    const handleTab = (path: string) => {
        query.set('tab', path);
        setQuery(query, { replace: true });
    };

    const cTabs = useMemo(
        () =>
            children.map(({ path, name }) => (
                <span onClick={() => handleTab(path)} className={cn(S.tabs__tab, { [S.active]: currentTab === path }, tabClassName)} key={`tabs-tab-${path}`}>
                    {name}
                </span>
            )),
        [children, handleTab, currentTab]
    );

    const cList = useMemo(
        () =>
            children.map(({ path, content, className }) => (
                <div className={cn(S.tabs__item, { [S.active]: currentTab === path }, className)} key={`tabs-item-${path}`}>
                    {content}
                </div>
            )),
        [children, currentTab]
    );

    return (
        <div className={S.tabs}>
            <div className={S.tabs__list}>{cTabs}</div>
            <div>{cList}</div>
        </div>
    );
});
