import { ModalControls } from '@cyberia-studio/react-modal';

import { useFtlRegister, useFtlStore, useGeneralStore, useLtlRegister, useLtlStore } from '@/shared/lib/stores';

export const useCurrentStore = () => {
    const ltl = useLtlStore();
    const ftl = useFtlStore();
    const { currentTab } = useGeneralStore();

    if (currentTab === 'ftl') return ftl;
    return ltl;
};

export const useCurrentRegister = (controls: ModalControls) => {
    const ltl = useLtlRegister(controls);
    const ftl = useFtlRegister(controls);

    const { currentTab } = useGeneralStore();

    if (currentTab === 'ftl') return ftl;
    return ltl;
};
