import { Config, PartialConfig } from '../../types';
import { Call } from '../call';

export abstract class Base {
    protected basePath: Config['basePath'];

    protected config: Config = {
        service: Call,
    };
    public constructor(config: PartialConfig) {
        this.config = { ...this.config, ...config };

        if (config.basePath) this.basePath = this.basePath ? this.basePath + '/' + config.basePath : config.basePath;
    }

    protected set = {
        config: (config: Config) => (this.config = config),
        basePath: (pathname: Config['basePath']) => (this.config.basePath = pathname),
        path: (pathname: Config['path']) => (this.config.path = pathname),
        data: (data: Config['data']) => (this.config.data = data),
        query: (data: Config['query']) => (this.config.query = data),
        formData: (data: Config['formData']) => (this.config.formData = data),
        handlers: (handlers: Config['handlers']) => (this.config.handlers = handlers),
        id: (id: Config['id']) => (this.config.id = id),
        responseType: (type: Config['responseType']) => (this.config.responseType = type),
        service: (callService: Config['service']) => (this.config.service = callService),
    };

    private api = () => new this.config.service({ ...this.config, basePath: this.basePath ?? this.config.basePath });

    protected request = {
        get: <SuccessData extends object = object, CreatedData extends object = object>() => this.api().get<SuccessData, CreatedData>(),
        delete: <SuccessData extends object = object, CreatedData extends object = object>() => this.api().delete<SuccessData, CreatedData>(),
        post: <SuccessData extends object = object, CreatedData extends object = object>() => this.api().post<SuccessData, CreatedData>(),
        put: <SuccessData extends object = object, CreatedData extends object = object>() => this.api().put<SuccessData, CreatedData>(),
    };
}
