import cn from 'classnames';
import { FC, useEffect } from 'react';

import { Tools } from '@/services';
import { useLtlStore } from '@/shared/lib/stores';
import { useFormContext } from '@/shared/modules/form/modules';
import { Checkbox, Input, NumberInput, Radios } from '@/shared/ui';
import { CalculateForm, Cargo } from '@/types';

import S from './styles.module.scss';

export const Customs: FC = () => {
    const { data, reg, field } = useFormContext<CalculateForm>();
    const { isOversized, options } = useLtlStore(({ isOversized, options }) => ({ isOversized, options }));

    const optionCategory = options.find((category) => category.key === 'pack')?.options;
    const option = optionCategory?.find((option) => option.key === 'upakovka-obresetka')?.id;

    useEffect(() => {
        field('ltlOptionIsOversized').value(isOversized, false);
    }, [isOversized]);

    useEffect(() => {
        if (data.ltlOptionIsFragile && option) {
            field('ltlOptions').value((prev = []) => {
                return [...prev, option];
            });
        }
    }, [data.ltlOptionIsFragile]);

    return (
        <div className={S.customs}>
            <div className={S.custom}>
                <Input {...reg('ltlOptionDescription')} label="Характер груза" placeholder="Введите характер груза" />
            </div>
            <div className={S.custom}>
                <Checkbox {...reg('ltlOptionIsFragile')}>{Tools.string.lineWrap('Хрупкий, жидкий или изделие\nиз пластмассы')}</Checkbox>
            </div>
            <div className={cn(S.custom, S.custom_column, { [S.custom_hidden]: !isOversized })}>
                <Radios
                    className={S.radios}
                    optionClassName={S.radios__option}
                    labelClassName={S.radios__label}
                    additionalClassName={S.radios__additional}
                    value={Cargo.OptionSizeType.OVERSIZED}
                    allowEmpty={false}
                >
                    <Radios.Item id={Cargo.OptionSizeType.OVERSIZED} name={'Негабаритное отправление'} additional={'+25 %'} />
                </Radios>
            </div>

            <div className={cn(S.custom, S.custom_column, S.custom_offset, { [S.custom_collapsed]: !data.ltlOptionIsInsured })}>
                <Checkbox {...reg('ltlOptionIsInsured')}>{'Страхование груза'}</Checkbox>
                <NumberInput
                    className={cn(S.insurance, { [S.insurance__visible]: data.ltlOptionIsInsured })}
                    {...reg('ltlOptionInsurance')}
                    label="Объявленная стоимость, ₽"
                    placeholder="0"
                />
            </div>
        </div>
    );
};
