import { Base } from '@/services/api/base';

import { RegisterRequest } from './types';

export class IND extends Base {
    protected basePath = 'requests/ind';

    public register = (data: RegisterRequest) => {
        this.set.data(data);
        this.set.path('register');

        return this.request.post<{ message: string }>();
    };
}
