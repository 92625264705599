import cn from 'classnames';
import { memo } from 'react';

import S from './styles.module.scss';
import { PolicyWarningProps } from './types';

export const PolicyWarning = memo<PolicyWarningProps>(({ align }) => (
    <span className={cn(S.policy_warning, S[`align__${align}`])}>
        {'Отправляя заявку, вы соглашаетесь с '}
        <a href={'https://smart-logistic.pro/policy'} target="_blank" rel="noreferrer">
            политикой конфиденциальности
        </a>
        {' компании'}
    </span>
));
