import { IconProps } from './types';

export const Calculator: React.FC<IconProps> = ({ className, height = 30, width = 30, filledClassName }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="25.2666" width="6.53333" height="3.73333" rx="1" stroke="currentColor" strokeWidth="1.5" />
            <rect x="11.2666" y="25.2666" width="7.46667" height="3.73333" rx="1" stroke="currentColor" strokeWidth="1.5" />
            <rect
                x="22.4668"
                y="25.2666"
                width="6.53333"
                height="3.73333"
                rx="1"
                stroke="currentColor"
                strokeWidth="1.5"
                fill="transparent"
                className={filledClassName}
            />
            <rect x="1" y="18.7334" width="6.53333" height="3.73333" rx="1" stroke="currentColor" strokeWidth="1.5" />
            <rect x="11.2666" y="18.7334" width="7.46667" height="3.73333" rx="1" stroke="currentColor" strokeWidth="1.5" />
            <rect x="22.4668" y="18.7334" width="6.53333" height="3.73333" rx="1" stroke="currentColor" strokeWidth="1.5" />
            <rect x="1" y="12.2002" width="6.53333" height="3.73333" rx="1" stroke="currentColor" strokeWidth="1.5" />
            <rect x="11.2666" y="12.2002" width="7.46667" height="3.73333" rx="1" stroke="currentColor" strokeWidth="1.5" />
            <rect x="22.4668" y="12.2002" width="6.53333" height="3.73333" rx="1" stroke="currentColor" strokeWidth="1.5" />
            <rect x="1" y="1" width="28" height="8.4" rx="1" stroke="currentColor" strokeWidth="1.5" />
        </svg>
    );
};
