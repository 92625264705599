import cn from 'classnames';
import React, { memo } from 'react';

import S from './styles.module.scss';
import { ButtonWrapperProps } from './types';

export const ButtonWrapper = memo(
    React.forwardRef<HTMLButtonElement, ButtonWrapperProps>(({ className, ...props }, ref) => (
        <button className={cn(S.button_wrapper, className)} {...props} ref={ref} />
    ))
);
