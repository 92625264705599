import { ModalControls } from '@cyberia-studio/react-modal';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Dialogs } from '@/components/partials';
import { api } from '@/services';
import { useFormContext } from '@/shared/modules/form/modules';
import { CalculateForm } from '@/types';

import { transformDataRegister, useGeneralStore } from '../../general';
import { demoVehicles } from '../demoVehicles';
import { useFtlStore } from '../store';
import { transformData } from '../transform';
import { validateOptions } from './validate';

export const useFtlCalculate = () => {
    const { data, shouldUpdate } = useFormContext<CalculateForm>();
    const { setCompleted, setSpecial, vehicles, isVehiclesSpecial, setLoading, options, setDelivery, setError } = useFtlStore();
    const prevData = useRef<string | null>(null);
    const { currentTab } = useGeneralStore();

    useEffect(() => {
        if (currentTab !== 'ftl') return;

        if (isVehiclesSpecial) {
            return;
        }

        const validatedData = transformData(data, options);

        if (!validatedData) {
            setCompleted(false);
            setLoading(false);

            prevData.current = '';

            return;
        }

        const stringified = JSON.stringify(validatedData);

        if (stringified === prevData.current) return;

        prevData.current = stringified;

        setLoading(true);

        api.requests()
            .ftl()
            .calculate(validatedData)
            .onSuccess(({ items }) => {
                const firstItem = items.at(0);

                setSpecial(!firstItem);
                setCompleted(!!firstItem);
                setLoading(false);

                if (!firstItem) {
                    return;
                }

                setDelivery({
                    total: firstItem.total,
                    entries: firstItem.entries.map((item) => ({ ...item, key: uuid() })),
                });
            })
            .onErrors(() => {
                setLoading(false);
                setCompleted(false);
                setError(true);
            });
    }, [shouldUpdate, vehicles, isVehiclesSpecial]);
};

export const useFtlOptions = () => {
    const { data, shouldUpdate } = useFormContext<CalculateForm>();
    const { setOptions, setOptionsDataLoading } = useFtlStore();
    const { currentTab } = useGeneralStore();
    const prevData = useRef<string | null>(null);

    useEffect(() => {
        if (currentTab !== 'ftl') return;

        const currentData = {
            addressFrom: data.ftlfromAddress,
            addressTo: data.ftltoAddress,
            vehicle: data.vehicleId,
        };

        const stringified = JSON.stringify(currentData);

        if (stringified === prevData.current) return;

        prevData.current = stringified;

        setOptionsDataLoading(true);

        api.info()
            .options({
                transitType: 'ftl',
                car_id: data.vehicleId,
            })
            .onSuccess(({ items }) => {
                setOptions(items);
            })
            .finally(() => {
                setOptionsDataLoading(false);
            });
    }, [shouldUpdate, currentTab]);
};

export const useFtlVehicles = () => {
    const { vehicles, setVehicles, setVehiclesLoading, setLocationEmpty, setVehiclesSpecial } = useFtlStore(
        ({ vehicles, setVehicles, setVehiclesLoading, setLocationEmpty, setVehiclesSpecial }) => ({
            vehicles,
            setVehicles,
            setVehiclesLoading,
            setLocationEmpty,
            setVehiclesSpecial,
        })
    );
    const { field } = useFormContext<CalculateForm>();
    const cityFrom = field('ftlfromCityAddress').value();
    const cityTo = field('ftltoCityAddress').value();

    useEffect(() => {
        setLocationEmpty(!cityFrom || !cityTo);
    }, [cityFrom, cityTo]);

    useEffect(() => {
        if (vehicles.length === 0) return;

        field('vehicleId').value((prev) => {
            const firstVehicle = vehicles[0];
            if (!prev) {
                return firstVehicle.is_demo ? prev : firstVehicle.id;
            }

            if (vehicles.some((v) => v.id === prev)) return prev;
            return firstVehicle.is_demo ? prev : firstVehicle.id;
        });
    }, [vehicles]);

    useEffect(() => {
        if (!cityFrom || !cityTo) {
            setVehicles(demoVehicles);
            setVehiclesSpecial(false);
            return;
        }

        setVehiclesLoading(true);
        api.requests()
            .ftl()
            .indexCars({ from: cityFrom, to: cityTo })
            .onSuccess(({ items }) => {
                if (items.length === 0) {
                    setVehicles(demoVehicles);
                    setVehiclesSpecial(true);
                    return;
                }

                setVehicles(items);
                setVehiclesSpecial(false);
            })
            .finally(() => setVehiclesLoading(false));
    }, [cityFrom, cityTo]);
};

export const useFtlRegister = (controls: ModalControls): [boolean, () => void] => {
    const { ifValid } = useFormContext<CalculateForm>();
    const { options } = useFtlStore();
    const [isLoading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const bx24_id = searchParams.get('bx24_id') || undefined;

    const register = () => {
        ifValid((validated) => {
            const mainData = transformData(validated, options);

            if (!mainData) return;

            const transformed = {
                ...mainData,
                ...transformDataRegister(validated),
                bx24_id,
            };

            if (!transformed) return;

            setLoading(true);

            api.requests()
                .ftl()
                .register(transformed)
                .onSuccess(({ message }) => {
                    setLoading(false);

                    controls.set(<Dialogs.OrderResult text={message} />);
                })
                .onErrors(() => {
                    setLoading(false);

                    controls.set(<Dialogs.OrderResult type="error" text="Повторите попытку позднее." />);
                });
        }, validateOptions).onErrors((errors) => {
            const inputName = Object.keys(errors).at(0);

            if (!inputName) return;

            const tab = document.getElementById('ftl');

            tab &&
                tab.querySelector(`[data-input-name="${inputName}"]`)?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
        });
    };

    return [isLoading, register];
};
