import { Code, Responses } from '../../../types';

interface ICreateResponseCallback<Res extends object | undefined = undefined> {
    (res: Res): void;
}

export const createResponse = <SuccessData extends object = object, CreatedData extends object = SuccessData>(
    promise: Promise<Responses.Base<SuccessData>>
) => {
    const then = <Data extends object | undefined>(code: Code | Code[] | number | number[] | undefined, fn: ICreateResponseCallback<Data>) => {
        promise.then((res) => {
            if (!code || (typeof code === 'object' && code.includes(res.code)) || res.code === code) {
                if (res.code === Code.Success || res.code === Code.Created) fn({ message: 'message' in res ? res.message : undefined, ...res.data } as Data);
                else fn(undefined as Data);
            }
        });
        return createResponse(promise);
    };

    return {
        onSuccess: (fn: (res: SuccessData) => void) => then(Code.Success, fn),
        onCreated: (fn: (res: CreatedData) => void) => then(Code.Created, fn),
        onErrors: (fn: (res: undefined) => void) => then([Code.Error, Code.Forbidden, Code.Local, Code.Unauthorized, Code.ValidationError], fn),
        onUnauthorized: (fn: (res: undefined) => void) => then(Code.Unauthorized, fn),
        onForbidden: (fn: (res: undefined) => void) => then(Code.Forbidden, fn),
        onError: (fn: (res: undefined) => void) => then(Code.Error, fn),
        onNotFound: (fn: (res: undefined) => void) => then(Code.NotFound, fn),
        onValidationError: (fn: (res: Responses.ValidationData) => void) => then(Code.ValidationError, fn),
        onLocal: (fn: (res: undefined) => void) => then(Code.Local, fn),
        onCode: (fn: (res: undefined) => void, codes: Code | Code[] | number | number[]) => then(codes, fn),
        finally: (fn: () => void) => {
            promise.finally(() => {
                fn();
            });

            return createResponse(promise);
        },
        abort: undefined as (() => void) | undefined,
        promise,
    };
};
