import { create } from 'zustand';

import { resolveValue } from '@/shared/modules/form/modules/functions';

import { demoVehicles } from '../demoVehicles';
import { FtlStore, FtlStoreSet } from './types';

export const useFtlStore = create<FtlStore & FtlStoreSet>((set) => ({
    isLocationEmpty: true,
    isVehiclesLoading: false,
    isVehiclesSpecial: true,
    isLoading: false,
    isCompleted: false,
    isSpecial: false,
    isHidden: false,
    isScrolled: false,
    delivery: undefined,
    vehicles: demoVehicles,
    options: [],
    isOrderDataLoading: false,
    isOrderDataLoaded: false,
    isOptionsDataLoading: true,
    error: undefined,
    orderDataLoadError: null,

    setOrderDataLoading: (value) => set(({ isOrderDataLoading }) => ({ isOrderDataLoading: resolveValue(isOrderDataLoading, value) })),
    setOrderDataLoaded: (value) => set(({ isOrderDataLoaded }) => ({ isOrderDataLoaded: resolveValue(isOrderDataLoaded, value) })),
    setOptionsDataLoading: (value) => set(({ isOptionsDataLoading }) => ({ isOptionsDataLoading: resolveValue(isOptionsDataLoading, value) })),
    setLocationEmpty: (value) => set(({ isLocationEmpty }) => ({ isLocationEmpty: resolveValue(isLocationEmpty, value) })),
    setCompleted: (value) =>
        set(({ isCompleted }) => ({
            isCompleted: resolveValue(isCompleted, value),
        })),
    setVehiclesSpecial: (value) => set(({ isVehiclesSpecial: isVehiclesDemo }) => ({ isVehiclesSpecial: resolveValue(isVehiclesDemo, value) })),
    setVehiclesLoading: (value) => set(({ isVehiclesLoading }) => ({ isVehiclesLoading: resolveValue(isVehiclesLoading, value) })),
    setSpecial: (value) => set(({ isSpecial }) => ({ isSpecial: resolveValue(isSpecial, value) })),
    setHidden: (value) => set(({ isHidden }) => ({ isHidden: resolveValue(isHidden, value) })),
    setScrolled: (value) => set(({ isScrolled }) => ({ isScrolled: resolveValue(isScrolled, value) })),
    setLoading: (value) => set(({ isLoading }) => ({ isLoading: resolveValue(isLoading, value) })),
    setVehicles: (value) => set(({ vehicles }) => ({ vehicles: resolveValue(vehicles, value) })),
    setOptions: (value) => set(({ options }) => ({ options: resolveValue(options, value) })),
    setDelivery: (value) => set(({ delivery }) => ({ delivery: resolveValue(delivery, value) })),
    setError: (value) => set(({ error }) => ({ error: resolveValue(error, value) })),
}));
