import { useModalContext } from '@cyberia-studio/react-modal';
import { useEffect } from 'react';

import { Icon } from '@/assets/icon';
import { ButtonWrapper } from '@/shared/ui';

import S from './styles.module.scss';
import { OrderResultProps } from './types';

export const OrderResult: React.FC<OrderResultProps> = ({ text, type = 'success' }) => {
    const { set, close } = useModalContext();

    useEffect(() => {
        set(
            <div className={S.result}>
                <ButtonWrapper className={S.close}>
                    <Icon.Close className={S.close__icon} onClick={() => close()} />
                </ButtonWrapper>
                {type === 'success' ? (
                    <>
                        <Icon.PackedSuccess className={S.icon} />
                        <h2 className={S.result__title}>Ваш заказ успешно оформлен</h2>
                    </>
                ) : (
                    <>
                        <Icon.PackedError className={S.icon} />
                        <h2 className={S.result__title}>Не удалось оформить заказ</h2>
                    </>
                )}
                <p className={S.result__text}>{text}</p>
            </div>
        );
    }, []);

    return <></>;
};
