import { FC } from 'react';

import { useFormContext } from '@/shared/modules/form/modules';
import { Input } from '@/shared/ui';
import { PhoneInput } from '@/shared/ui/phone-input';
import { CalculateForm } from '@/types';

import { BaseEntityProps } from '../types';

export const Company: FC<BaseEntityProps> = ({ name, onChange, inForm }) => {
    const { reg } = useFormContext<CalculateForm>();

    return (
        <>
            <Input {...reg(`${name}CompanyOrganization`, { onChange })} label="Наименование организации" placeholder="Введите наименование организации" />
            <Input {...reg(`${name}CompanyTin`, { onChange })} label="ИНН" placeholder="Введите ИНН" regex={/^\d+$/} />
            <Input {...reg(`${name}CompanyName`, { onChange })} label="ФИО представителя" placeholder="Введите ФИО" />
            <PhoneInput {...reg(`${name}CompanyPhone`, { onChange })} label="Телефон" placeholder="Введите телефон" required />
            <Input {...reg(`${name}CompanyEmail`, { onChange })} label="Email" placeholder="Введите email" />
            {inForm}
        </>
    );
};
