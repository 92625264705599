import { LocateStrings } from './types';

export const translate: LocateStrings = {
    from: {
        label: 'Откуда',
        placeholder: 'Введите населенный пункт',
        addressSelect: 'Забрать по адресу',
        terminal: 'Сдать груз в терминал',
        addressInput: 'Адрес забора груза',
        addressPlaceholder: 'Введите адрес забора груза',
        terminalInput: 'Адрес отделения',
    },
    to: {
        label: 'Куда',
        placeholder: 'Введите населенный пункт',
        addressSelect: 'Доставить по адресу',
        terminal: 'Получить груз в терминале',
        addressInput: 'Адрес доставки груза',
        addressPlaceholder: 'Введите адрес доставки груза',
        terminalInput: 'Адрес отделения',
    },
};
