import { useTrustedState } from '@cyberia-studio/react-hooks';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { InputBase } from '@/shared/ui';

import { TextareaProps } from './types';

export const Textarea: React.FC<TextareaProps> = ({ className, errors, name, label, readOnly, onClick, onChange, isDisabled, value = '', ...props }) => {
    const [inputText, setInputText, isTrusted] = useTrustedState(value);

    useEffect(() => {
        setInputText(value ?? '', false);
    }, [value]);

    useEffect(() => {
        if (isTrusted && onChange) onChange(inputText);
    }, [inputText]);

    const handleChange = (value: string) => setInputText(value, true);
    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(event.target.value);

    const elementId = useMemo(() => uuid(), []);

    return (
        <InputBase className={className} errors={errors} label={label} htmlFor={elementId}>
            <textarea {...props} name={name} disabled={isDisabled} value={value} onChange={handleInputChange} readOnly={readOnly} id={elementId} />
        </InputBase>
    );
};
