import cn from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';

import { Icon } from '@/assets/icon';

import S from './styles.module.scss';

export interface IWarning {
    onButtonClick?: MouseEventHandler<HTMLButtonElement>;
    messageText: string;
    buttonText?: string;
    buttonClassName?: string;
    tooltip?: ReactNode;
}

export const Warning: React.FC<IWarning> = ({ onButtonClick, buttonText, messageText, buttonClassName, tooltip }) => (
    <p className={S.warning}>
        <Icon.Info className={S.warning__icon} />
        <div>
            <span>{`${messageText} `}</span>
            {buttonText && (
                <>
                    <button
                        type="button"
                        className={cn(S.warning__button, { [S.warning__button_colored]: !onButtonClick && !tooltip }, buttonClassName)}
                        onClick={onButtonClick}
                    >
                        {buttonText}
                    </button>
                    {tooltip && <div className={S.tooltip}>{tooltip}</div>}
                </>
            )}
        </div>
    </p>
);
