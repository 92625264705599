import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';

import router from './router';
import Sentry from './sentry';

const App: FC = () => {
    return <RouterProvider router={router} />;
};

export default Sentry.withErrorBoundary(App, {});
