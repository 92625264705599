import { Base } from '../../base';
import { CitiesResponse, ClientResponse, OptionsRequestQuery, OptionsResponse } from './types';

export class Info extends Base {
    protected basePath = 'info';

    public cities = () => {
        this.set.path('cities');

        return this.request.get<CitiesResponse>();
    };

    public options = (query?: OptionsRequestQuery) => {
        this.set.path('options');

        query && this.set.query(query);

        return this.request.get<OptionsResponse>();
    };

    public client = (tin: string) => {
        this.set.path('client');
        this.set.query({ tin });

        return this.request.get<ClientResponse>();
    };
}
