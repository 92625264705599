import { CalculateForm, FTLCalculate, Options, Payment } from '@/types';

import { getDataNameByTab, transformGeneralData } from '../../general';

export const transformData = (data: Partial<CalculateForm>, optionCategories: Options.Category[]): FTLCalculate | false => {
    const currentTab = 'ftl';

    const from = data.ftlfromAddress;
    const to = data.ftltoAddress;

    if (!from || !to || !data.vehicleId) return false;

    const { generalOptions } = transformGeneralData(data, { optionCategories, currentTab });

    return {
        route: {
            from: {
                address: from,
            },
            to: {
                address: to,
            },
        },
        client: {
            tin: data.ftlPaymentTin,
        },
        options: {
            cargo_options: {
                description: data[getDataNameByTab(currentTab, 'OptionDescription')] ?? '',
                insurance: data[getDataNameByTab(currentTab, 'OptionIsInsured')] ? data[getDataNameByTab(currentTab, 'OptionInsurance')] ?? 0 : null,
            },
            general_options: generalOptions,
        },
        car_id: data.vehicleId,
        payment_type: data[getDataNameByTab(currentTab, 'PaymentMethod')] ?? Payment.Type.CASHLESS_VATLESS,
    };
};
