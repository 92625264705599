import { IRuleMessage } from '../useForm/types';

export const requiredRuleName: IRuleMessage<true> = {
    value: true,
    message: 'Поле «$field» обязательно для заполнения',
};

export const requiredRule: IRuleMessage<true> = {
    value: true,
    message: 'Поле обязательно для заполнения',
};

export const maxLengthRule: IRuleMessage<255> = {
    value: 255,
    message: 'Значение должно быть не более 255 символов',
};
