import { useObjectMemo } from '@cyberia-studio/react-hooks';
import { useModalContext } from '@cyberia-studio/react-modal';
import { FC, useMemo, useState } from 'react';

import { Icon } from '@/assets/icon';

import S from './styles.module.scss';
import { Terminal } from './terminal';
import { PlaceSelectProps, PlaceTab } from './types';

const strings = {
    from: {
        direction: 'Откуда',
        tab: {
            terminal: 'Отправить из отделения',
            address: 'Отправить от адреса',
        },
    },
    to: {
        direction: 'Куда',
        tab: {
            terminal: 'Забрать из отделения',
            address: 'Забрать по адресу',
        },
    },
};

export const PlaceSelect: FC<PlaceSelectProps> = ({ direction }) => {
    const modal = useModalContext();
    const [tab] = useState<PlaceTab>(PlaceTab.Terminal);

    const str = useMemo(() => strings[direction], [direction]);

    const content = useObjectMemo(() => {
        switch (tab) {
            case PlaceTab.Terminal:
                return <Terminal location={direction} />;
            case PlaceTab.Address:
                return <></>;
        }
    }, [tab, direction]);

    return (
        <div className={S.place}>
            <header className={S.header}>
                <h2 className={S.header__title}>{str.direction}</h2>
                <div className={S.actions}>
                    {/*

                    DO NOT DELETE
                    probably will be added back in the future

                    <p onClick={() => handleTab(PlaceTab.Terminal)} className={tab === PlaceTab.Terminal ? 'active' : ''}>
                        {str.tab.terminal}
                    </p>
                    <p onClick={() => handleTab(PlaceTab.Address)} className={tab === PlaceTab.Address ? 'active' : ''}>
                        {str.tab.address}
                    </p>

                    */}
                    <div className={S.close}>
                        <Icon.Close className={S.close__icon} onClick={() => modal.close()} />
                    </div>
                </div>
            </header>
            <div className={S.content}>{content}</div>
        </div>
    );
};
