import cn from 'classnames';
import { forwardRef } from 'react';

import { BaseProps } from '@/types';

import S from './styles.module.scss';

export const Block = forwardRef<HTMLDivElement, Partial<BaseProps>>(({ children, className, id }, ref) => (
    <div className={cn(S.block, className)} id={id} ref={ref}>
        {children}
    </div>
));
