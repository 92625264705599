import Api from '@/shared/modules/api';

import { Service } from './service';

export class Base extends Api.Base {
    constructor(config?: Api.Types.PartialConfig) {
        super({
            ...config,
            service: Service,
        });
    }
}
