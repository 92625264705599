import { Preloader } from '@/components/partials';
import { useLtlStore } from '@/shared/lib/stores';
import { useLtlCalculate, useLtlOptions } from '@/shared/lib/stores';
import { useFormContext } from '@/shared/modules/form/modules';
import { PageUI } from '@/shared/ui';
import { CalculateForm } from '@/types';

import { Options, PaymentForm, Places, Registration } from '../general';
import { Cargo, Customs, Place } from './parts';
import S from './styles.module.scss';

export const Ltl: React.FC = () => {
    useLtlCalculate();
    useLtlOptions();

    const { set } = useFormContext<CalculateForm>();
    const { isOrderDataLoading: isOrderDataFetching } = useLtlStore();

    const handleSwap = () => {
        set((prev) => ({
            // other form data
            ...prev,
            // from
            fromAddress: prev.toAddress,
            fromCity: prev.toCity,
            fromCityAddress: prev.toCityAddress,
            fromTerminal: prev.toTerminal,
            fromType: prev.toType,
            fromBounds: prev.toBounds,
            // to
            toAddress: prev.fromAddress,
            toCity: prev.fromCity,
            toCityAddress: prev.fromCityAddress,
            toTerminal: prev.fromTerminal,
            toType: prev.fromType,
            toBounds: prev.fromBounds,
        }));
    };

    return (
        <>
            <PageUI.Block className={S.Root}>
                <Preloader isLoading={isOrderDataFetching} />
                {/* Addresses */}
                <Places from={<Place location="from" required />} to={<Place location="to" required />} onSwap={handleSwap} />
                {/* Cargos */}
                <Cargo />
                {/* Form of payment */}
                <PaymentForm tab={'ltl'} title="Форма оплаты" />
                {/* Additional options */}
                <Options tab={'ltl'}>
                    <Customs />
                </Options>
            </PageUI.Block>
            {/* Registration form */}
            <Registration tabId={'ltl'} />
        </>
    );
};
