import useModal from '@cyberia-studio/react-modal';
import { FC, useEffect, useMemo } from 'react';

import { Dialogs, IWarning, Warning } from '@/components/partials';
import W from '@/components/partials/warning/warnings.module.scss';
import { setDialog } from '@/helpers';
import { useLtlStore } from '@/shared/lib/stores';
import { useForm, useFormContext } from '@/shared/modules/form/modules';
import { NumberInput, Select } from '@/shared/ui';
import { CalculateForm, Cargo } from '@/types';

import { tooltip } from '../oversized-tooltip';
import S from './styles.module.scss';

export const Single: FC = () => {
    const { field, data, shouldUpdate } = useFormContext<CalculateForm>();

    const { reg, data: singleData, field: formField, set } = useForm<Cargo.Single>(data.cargoSingle);
    const { setOversized, setSpecial } = useLtlStore(({ setOversized, setSpecial }) => ({
        setOversized,
        setSpecial,
    }));
    const [modal, controls] = useModal();

    const maxSize = data.cargoSingle?.dimension === 'height' ? 2.5 : data.cargoSingle?.dimension === 'length' ? 6 : 2.45;

    const handleChanges = () => {
        field('cargoSingle').value(() => {
            const places = singleData.places && singleData.places >= 5000 ? 5000 : singleData.places;
            formField('places').value(places);
            return {
                ...singleData,
                places,
            };
        });
    };

    useEffect(() => {
        set({ ...field('cargoSingle').value() }, false);
    }, [data]);

    const warnings = useMemo(() => {
        const warns: IWarning[] = [];
        const { weight, volume, max, places, dimension } = singleData;

        if ((weight !== undefined && weight > 10000) || (volume !== undefined && volume > 50))
            warns.push({
                messageText: 'Для грузов > 10т или 50 м³ необходим ',
                buttonText: 'индивидуальный расчет',
                onButtonClick: setDialog(controls, <Dialogs.IndividualCalc />),
            });

        if (max !== undefined && max > maxSize)
            warns.push({
                messageText: `Для груза с максимальной ${
                    dimension === 'height' ? 'высотой' : dimension === 'length' ? 'длиной' : 'шириной'
                } > ${maxSize}м необходим `,
                buttonText: 'индивидуальный расчет',
                onButtonClick: setDialog(controls, <Dialogs.IndividualCalc />),
            });

        if (places && weight && weight / places > 2000 && weight <= 10000)
            warns.push({
                messageText: 'Для груза весом больше 2000 кг необходим ',
                buttonText: 'индивидуальный расчет',
                onButtonClick: setDialog(controls, <Dialogs.IndividualCalc />),
            });

        if (warns.length === 0) {
            const oversizedWarns: IWarning[] = [];

            if (dimension === 'height' && max && max >= 2.1 && max <= 2.5)
                oversizedWarns.push({
                    messageText: 'Груз высотой от 2.1 до 2.5 м является ',
                    buttonText: 'негабаритным отправлением',
                    tooltip,
                });

            if (dimension === 'width' && max && max >= 2 && max <= 2.45)
                oversizedWarns.push({
                    messageText: 'Груз шириной от 2 до 2.45 м является',
                    buttonText: 'негабаритным отправлением',
                    tooltip,
                });

            if (dimension === 'length' && max && max >= 3 && max <= 6)
                oversizedWarns.push({
                    messageText: 'Груз длиной от 3 до 6 м является ',
                    buttonText: 'негабаритным отправлением',
                    tooltip,
                });

            if (weight && places && weight / places >= 1000 && weight / places <= 2000)
                oversizedWarns.push({
                    messageText: 'Груз весом от 1000 до 2000 кг является ',
                    buttonText: 'негабаритным отправлением',
                    tooltip,
                });

            //TODO: проверить, почему не сеттится isOversized
            setOversized(!!oversizedWarns.length);
            setSpecial(false);
            warns.push(...oversizedWarns);
        } else {
            setSpecial(true);
        }

        return warns;
    }, [shouldUpdate, setOversized, setSpecial]);

    return (
        <div className={S.single}>
            {modal}
            <NumberInput className={S.input} onBlur={handleChanges} {...reg('places')} label="Количество мест" placeholder="1" integer required />
            <NumberInput className={S.input} onBlur={handleChanges} {...reg('weight')} label="Вес, кг" placeholder="0.00" precision={2} required />
            <NumberInput className={S.input} onBlur={handleChanges} {...reg('volume')} label="Объем, м&sup3;" placeholder="0.00" precision={2} required />
            <div className={S.select_input}>
                <h3 className={S.select_input__title}>Макс. габарит, м</h3>
                <div className={S.select_input__content}>
                    <Select<Cargo.SingleDimension> className={S.select_input__select} {...reg('dimension', { onChange: handleChanges })}>
                        {[
                            {
                                id: 1,
                                name: 'Длина',
                                value: 'length',
                            },
                            {
                                id: 2,
                                name: 'Высота',
                                value: 'height',
                            },
                            {
                                id: 3,
                                name: 'Ширина',
                                value: 'width',
                            },
                        ]}
                    </Select>
                    <NumberInput className={S.select_input__input} onBlur={handleChanges} {...reg('max')} placeholder="0.01" precision={2} />
                </div>
            </div>
            {!!warnings.length && (
                <div className={W.warnings}>
                    {warnings.map((item) => (
                        <Warning key={`warn-${item.messageText}`} {...item} />
                    ))}
                </div>
            )}
        </div>
    );
};
