import { IconProps } from './types';

export const Tracking: React.FC<IconProps> = ({ className, height = 30, width = 30, filledClassName }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 27.8789L4.5 25.5002" stroke="currentColor" strokeWidth="3" strokeLinecap="round" className={filledClassName} />
            <circle cx="15" cy="15" r="14" stroke="currentColor" strokeWidth="1.5" />
            <path
                d="M7 10.6V17.2573C7 17.5958 7.17122 17.9113 7.45501 18.0958L15 23M7 10.6L14.5233 7.21449C14.8219 7.08012 15.1674 7.10045 15.4482 7.26892L19 9.4M7 10.6L11 13M15 23V15.4M15 23L22.4472 19.2764C22.786 19.107 23 18.7607 23 18.382V11.8M15 15.4L23 11.8M15 15.4L11 13M23 11.8L19 9.4M19 9.4L11 13M11 13V16.6"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                fill="transparent"
                strokeLinejoin="round"
            />
        </svg>
    );
};
