import { Entity } from '..';

export interface Entities {
    customer: Entity.Data;
    sender: Entity.Data;
    recipient: Entity.Data;
}

export enum Kind {
    Customer = 1,
    Sender = 2,
    Recipient = 3,
}
