import { FC, useMemo } from 'react';

import { Options } from '@/types';

import { BaseGeneralPart } from '../../../types';
import { Item } from '../item';
import S from './styles.module.scss';

export const Category: FC<Options.Category & Omit<BaseGeneralPart, 'title'>> = ({ name, options, tab }) => {
    const sortedOptions = useMemo(() => options.sort((a, b) => a.id - b.id), [options]);

    return (
        <div className={S.category}>
            <h3 className={S.category__title}>{name}</h3>
            <div className={S.category__items}>
                {sortedOptions.map((item) => (
                    <Item {...item} key={`category-option-${item.id}`} tab={tab} />
                ))}
            </div>
        </div>
    );
};
