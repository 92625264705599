import cn from 'classnames';
import { useId, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Icon } from '@/assets/icon';

import S from './styles.module.scss';
import { TooltipProps } from './types';
import useTooltipPosition from './useTooltipPosition';

export const Tooltip: React.FC<TooltipProps> = ({ children, className, position = 'right', icon }) => {
    const tooltipId = `tooltip-id-${useId().replace(':', '')}`;
    const [showContent, setShowContent] = useState<boolean>(false);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const { positionX, positionY, relativeX } = useTooltipPosition(tooltipRef.current, position);

    const activityHandlers = useMemo(
        () => ({
            onMouseEnter: () => setShowContent(true),
            onMouseLeave: () => setShowContent(false),
            onFocus: () => setShowContent(true),
            onBlur: () => setShowContent(false),
            onTouchStart: () => setShowContent(true),
        }),
        []
    );

    const width = document.getElementById(tooltipId)?.clientWidth;

    return children ? (
        <div ref={tooltipRef} tabIndex={0} className={cn(S.tooltip, className)} aria-labelledby={tooltipId} {...activityHandlers}>
            {createPortal(
                <div
                    {...activityHandlers}
                    id={tooltipId}
                    className={cn(S.TooltipContent, S[`TooltipContent_Position-${position === 'right' ? relativeX : position}`])}
                    style={
                        {
                            '--tooltip-y-position': `${positionY}px`,
                            '--tooltip-x-position': `${positionX}px`,
                            '--tooltip-width': `${width}px`,
                            visibility: showContent ? 'visible' : 'hidden',
                        } as React.CSSProperties
                    }
                >
                    {children}
                </div>,
                document.body
            )}
            {icon || <Icon.Warning className={S.tooltip__icon} />}
        </div>
    ) : null;
};
