import cn from 'classnames';
import React from 'react';

import S from './styles.module.scss';
import { InputBaseLocalProps, InputBaseProps } from './types';

export const InputBase = React.forwardRef<HTMLDivElement, InputBaseLocalProps>(
    ({ children, className, errors, label, htmlFor, 'aria-label': ariaLabel, required, disabled, name }, ref) => (
        <div className={cn(S.input, { [S.input_error]: errors?.length }, { [S.input_disabled]: disabled }, className)} data-input-name={name} ref={ref}>
            {label && (
                <div className={S.heading}>
                    <label htmlFor={htmlFor} aria-label={ariaLabel} className={cn(S.label, { [S.label_required]: required })}>
                        {label}
                    </label>
                </div>
            )}
            <div className={cn(S.content)}>{children instanceof Function ? children(!!errors?.length) : children}</div>
            {errors && (
                <div className={S.errors}>
                    {errors.map((err) => (
                        <span className={S['input__errors-item']} key={`input-error-${err}`}>
                            {err}
                        </span>
                    ))}
                </div>
            )}
        </div>
    )
);

export type { InputBaseProps };
