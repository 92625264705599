import React, { ChangeEvent, useMemo, useState } from 'react';
import { useEffect } from 'react';
import ReactInputMask from 'react-input-mask';
import { v4 as uuid } from 'uuid';

import { InputBase } from '../input-base';
import { MaskInputProps } from './types';

export const MaskInput = React.forwardRef<ReactInputMask, MaskInputProps>(
    ({ onChange, className, errors, value = '', name, label, required, ...props }, ref) => {
        const [text, setText] = useState(value);

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;

            setText(value);
            onChange && onChange(value);
        };

        useEffect(() => {
            setText(value);
        }, [value]);

        const elementId = useMemo(() => uuid(), []);

        return (
            <InputBase errors={errors} className={className} label={label} htmlFor={elementId} required={required} name={name} aria-label={''}>
                <ReactInputMask {...props} value={text} onChange={handleChange} ref={ref} id={elementId} />
            </InputBase>
        );
    }
);
