import useModal from '@cyberia-studio/react-modal';
import { FC, useMemo } from 'react';

import { Dialogs, IWarning, Warning } from '@/components/partials';
import W from '@/components/partials/warning/warnings.module.scss';
import { setDialog } from '@/helpers';
import { useLtlStore } from '@/shared/lib/stores';
import { useFormContext } from '@/shared/modules/form/modules';
import { CalculateForm } from '@/types';

import { tooltip } from '../oversized-tooltip';
import { Item } from './parts';
import S from './styles.module.scss';

export const Multi: FC = () => {
    const { data, shouldUpdate } = useFormContext<CalculateForm>();
    const { setOversized, setSpecial } = useLtlStore(({ setOversized, setSpecial }) => ({
        setOversized,
        setSpecial,
    }));
    const [modal, controls] = useModal();

    const items = useMemo(() => {
        if (!data.cargoMultiItems) return [[], []];

        return data.cargoMultiItems.map(({ id }, index) => <Item id={id} isDeletable={index !== 0} key={`cargo-${id}`} />);
    }, [shouldUpdate]);

    const warnings = useMemo(() => {
        const warns: IWarning[] = [];
        const values = data.cargoMultiItems ?? [];

        if (
            values.some(({ weight }) => weight !== undefined && weight > 10000) ||
            values.some(({ height, length, width }) => height && length && width && height * length * width > 50)
        )
            warns.push({
                messageText: 'Для грузов > 10т или 50 м³ необходим ',
                buttonText: 'индивидуальный расчет',
                onButtonClick: setDialog(controls, <Dialogs.IndividualCalc />),
            });

        if (values.some(({ height }) => height && height > 2.5))
            warns.push({
                messageText: 'Для груза с высотой > 2.5 м необходим ',
                buttonText: 'индивидуальный расчет',
                onButtonClick: setDialog(controls, <Dialogs.IndividualCalc />),
            });

        if (values.some(({ width }) => width && width > 2.45))
            warns.push({
                messageText: 'Для груза шириной > 2.45 м необходим ',
                buttonText: 'индивидуальный расчет',
                onButtonClick: setDialog(controls, <Dialogs.IndividualCalc />),
            });

        if (values.some(({ length }) => length && length > 6))
            warns.push({
                messageText: 'Для груза длиной > 6 м необходим ',
                buttonText: 'индивидуальный расчет',
                onButtonClick: setDialog(controls, <Dialogs.IndividualCalc />),
            });

        if (values.some(({ weight, places }) => places && weight && weight / places > 2000)) {
            warns.push({
                messageText: 'Для груза весом больше 2000 кг необходим ',
                buttonText: 'индивидуальный расчет',
                onButtonClick: setDialog(controls, <Dialogs.IndividualCalc />),
            });
        }

        if (warns.length === 0) {
            const oversizedWarns: IWarning[] = [];

            if (values.some(({ height }) => height && height >= 2.1 && height <= 2.5))
                oversizedWarns.push({
                    messageText: 'Груз высотой от 2.1 до 2.5 м является ',
                    buttonText: 'негабаритным отправлением',
                    tooltip,
                });

            if (values.some(({ width }) => width && width >= 2 && width <= 2.45))
                oversizedWarns.push({
                    messageText: 'Груз шириной от 2 до 2.45 м является',
                    buttonText: 'негабаритным отправлением',
                    tooltip,
                });

            if (values.some(({ length }) => length && length >= 3 && length <= 6))
                oversizedWarns.push({
                    messageText: 'Груз длиной от 3 до 6 м является ',
                    buttonText: 'негабаритным отправлением',
                    tooltip,
                });

            if (values.some(({ weight, places }) => weight && places && weight / places >= 1000 && weight <= 2000))
                oversizedWarns.push({
                    messageText: 'Груз весом от 1000 до 2000 кг является ',
                    buttonText: 'негабаритным отправлением',
                    tooltip,
                });

            //TODO: проверить, почему не сеттится isOversized
            setOversized(!!oversizedWarns.length);
            setSpecial(false);
            warns.push(...oversizedWarns);
        } else {
            setSpecial(true);
        }

        return warns;
    }, [shouldUpdate, setSpecial, setOversized]);

    return (
        <>
            {modal}
            <div className={S.items}>{items}</div>
            {!!warnings.length && (
                <div className={W.warnings}>
                    {warnings.map((item) => (
                        <Warning key={`warn-${item.messageText}`} {...item} />
                    ))}
                </div>
            )}
        </>
    );
};
