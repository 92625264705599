import env from '@fipnooone/react-env';

export const load = () => {
    return new Promise((resolve, reject) => {
        const apiKey = env('YANDEX_KEY');
        const suggestKey = env('SUGGEST_KEY');

        const ymapsScript = document.createElement('script');
        ymapsScript.id = 'ymapsScript';
        ymapsScript.type = 'text/javascript';
        ymapsScript.src = `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${apiKey}&suggest_apikey=${suggestKey}`;
        ymapsScript.addEventListener('load', resolve);
        ymapsScript.addEventListener('error', reject);

        document.body.appendChild(ymapsScript);
    })
        .then(() => {
            document.dispatchEvent(new Event('yandex-api-loaded'));
        })
        .catch(console.warn);
};

export const unload = () => {
    document.getElementById('ymapsScript')?.remove();
};
