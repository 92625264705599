import { Icon, SVG } from './template';

export const Minus = Icon(({ size = 26, width = size, height = size, ...props }, ref) => (
    <SVG {...props} width={width} height={height} ref={ref}>
        <path
            d="M13 22.75C18.3848 22.75 22.75 18.3848 22.75 13C22.75 7.61522 18.3848 3.25 13 3.25C7.61522 3.25 3.25 7.61522 3.25 13C3.25 18.3848 7.61522 22.75 13 22.75Z"
            fill="#F6AE02"
        />
        <path d="M8.9375 13H17.0625" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </SVG>
));
