import { RegisterRequest } from '@/services/api/methods/requests/ind/types';
import { requiredRule, ValidateOptions } from '@/shared/modules/form';

export const validateOptions = {
    from: {
        type: 'string',
        required: requiredRule,
        regex: {
            value: /^(?!\s+$).*/,
            message: 'Поле обязательно для заполнения',
        },
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    to: {
        type: 'string',
        required: requiredRule,
        regex: {
            value: /^(?!\s+$).*/,
            message: 'Поле обязательно для заполнения',
        },
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    cargo_description: {
        type: 'string',
        required: requiredRule,
        regex: {
            value: /^(?!\s+$).*/,
            message: 'Поле обязательно для заполнения',
        },
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    name: {
        type: 'string',
        required: requiredRule,
        regex: {
            value: /^(?!\s+$).*/,
            message: 'Поле не может состоять только из пробелов',
        },
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    phone: {
        type: 'string',
        required: requiredRule,
    },
} satisfies ValidateOptions<RegisterRequest>;
