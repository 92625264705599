import 'react-international-phone/style.css';

import { useTrustedState } from '@cyberia-studio/react-hooks';
import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { buildCountryData, CountryData, defaultCountries, parseCountry, PhoneInput as InputPhone, PhoneInputRefType } from 'react-international-phone';
import { v4 as uuid } from 'uuid';

import { InputBase } from '../input-base';
import S from './styles.module.scss';
import { PhoneInputProps } from './types';

const translatedCountries = defaultCountries
    .map((country) => {
        const parsed = parseCountry(country);

        const diplayNames = new Intl.DisplayNames(['ru'], { type: 'region' });

        return buildCountryData({
            ...parsed,
            name: diplayNames.of(parsed.iso2.toUpperCase()) ?? parsed.name,
        });
    })
    .sort((a, b) => {
        const aName = parseCountry(a);
        const bName = parseCountry(b);

        return aName.name.localeCompare(bName.name);
    });

const sortCountries = (countries: CountryData[]) => {
    const ruIndex = countries.findIndex((country) => parseCountry(country).iso2 === 'ru');

    const ruCountry = countries.splice(ruIndex, 1)[0];
    const ruCountryParsed = parseCountry(ruCountry);
    const ruCountryData = buildCountryData({
        ...ruCountryParsed,
        priority: 0,
    });
    return [ruCountryData, ...countries];
};

const sortedCountries = sortCountries(translatedCountries);

export const PhoneInput = React.forwardRef<PhoneInputRefType, PhoneInputProps>(
    ({ onChange, className, errors, value = '', name, label, required, ...props }, ref) => {
        const [state, setState, isTrusted] = useTrustedState(value ? value : '+7', !value);
        const [isTouched, setIsTouched] = useState(false);

        const handleChange = (phone: string) => {
            setState(phone);
            setIsTouched(true);
        };

        useEffect(() => {
            setState(value, false);
        }, [value]);

        useEffect(() => {
            if (!isTrusted) {
                return;
            }

            onChange?.(state);
        }, [state]);

        const elementId = useMemo(() => uuid(), []);

        return (
            <InputBase errors={errors} className={cn(S.wrapper, className)} label={label} htmlFor={elementId} required={required} name={name} aria-label={''}>
                <InputPhone
                    {...props}
                    value={state}
                    onChange={handleChange}
                    disableDialCodePrefill={isTouched}
                    defaultCountry="ru"
                    ref={ref}
                    inputClassName={S.input}
                    className={S.content}
                    key={elementId}
                    countries={sortedCountries}
                    preferredCountries={['ru']}
                />
            </InputBase>
        );
    }
);
