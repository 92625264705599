import { AxiosRequestConfig } from 'axios';

import { Headers, Responses } from '../../types';
import { PartialConfig } from '../../types/config';
import { instance } from '../axios';
import { createResponse, toFormData } from './functions';

export class Call {
    protected config: PartialConfig = {};
    protected headers: Headers = {};

    public constructor(config?: PartialConfig) {
        const abortController = new AbortController();

        if (config)
            this.config = {
                ...config,
                basePath: this.config.basePath && config.basePath ? this.config.basePath + '/' + config.basePath : undefined,
                abort: () => abortController.abort(),
                signal: abortController.signal,
            };
    }

    private addHeader(name: keyof typeof this.headers, value: string) {
        this.headers = { ...this.headers, [name]: value };
    }

    private process<SuccessData extends object = object, CreatedData extends object = object>(method: 'get' | 'post' | 'delete' | 'put' | 'patch') {
        const axiosConfig: AxiosRequestConfig = {};

        const config = this.config;

        if (config.data) axiosConfig.data = config.data;
        else if (config.formData) {
            axiosConfig.data = toFormData(config.formData);
            this.addHeader('Content-Type', 'multipart/form-data');
        }

        config.query && (axiosConfig.params = config.query);
        config.responseType && (axiosConfig.responseType = config.responseType);
        config.headers && (axiosConfig.headers = { ...axiosConfig.headers, ...config.headers });

        config.basePath && (axiosConfig.baseURL = config.basePath);
        config.path && (axiosConfig.url = config.path);
        axiosConfig.method = method;
        axiosConfig.signal = config.signal;

        const promise = instance.request(axiosConfig).catch((err) => ({ code: err?.code ?? 0, message: '' })) as Promise<
            Responses.Base<SuccessData, CreatedData>
        >;

        const response = createResponse<SuccessData, CreatedData>(promise);

        response.abort = config.abort;

        return response;
    }

    public get = <SuccessData extends object, CreatedData extends object>() => this.process<SuccessData, CreatedData>('get');
    public post = <SuccessData extends object, CreatedData extends object>() => this.process<SuccessData, CreatedData>('post');
    public put = <SuccessData extends object, CreatedData extends object>() => this.process<SuccessData, CreatedData>('put');
    public delete = <SuccessData extends object, CreatedData extends object>() => this.process<SuccessData, CreatedData>('delete');
}
