import { GetCalculateByBxIdResponse } from '@/services/api/methods/requests/ltl/types';
import { CalculateForm, Entity } from '@/types';

const parsePersonData = (data: Entity.BxPerson | null, personType: Entity.Types): Partial<CalculateForm> => {
    if (!data) {
        return {};
    }

    if (data.type === Entity.Type.Company) {
        return {
            [`${personType}CompanyEmail`]: data.email,
            [`${personType}CompanyName`]: data.name,
            [`${personType}CompanyOrganization`]: data.organization,
            [`${personType}CompanyPhone`]: data.phone,
            [`${personType}CompanyTin`]: data.tin,
            [`${personType}Type`]: Entity.Type.Company,
        };
    }

    return {
        [`${personType}PersonIssuedBy`]: data.issued_by,
        [`${personType}PersonName`]: data.name,
        [`${personType}PersonPassportDate`]: data.passport_date,
        [`${personType}PersonPassportRegistrationAddress`]: data.passport_registration_address,
        [`${personType}PersonPassportSerial`]: data.passport_serial,
        [`${personType}PersonPhone`]: data.phone,
        [`${personType}Type`]: Entity.Type.Person,
    };
};

export const parsePersons = (data: Pick<GetCalculateByBxIdResponse, Entity.Types>): Partial<CalculateForm> => {
    const types: Entity.Types[] = ['customer', 'recipient', 'sender'];

    return Object.assign({}, ...types.map((type) => parsePersonData(data[type], type)));
};
