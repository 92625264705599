import { Icon, SVG } from './template';

export const ArrowRight = Icon(({ size = 24, width = size, height = size, ...props }, ref) => (
    <SVG {...props} width={width} height={height} ref={ref}>
        <path
            d="M14.4302 6.35645L20.5002 12.2186L14.4302 18.0807"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M3.5 12.2188H20.33" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </SVG>
));
