import { ValidateOptions } from '@/shared/modules/form';
import { CalculateForm } from '@/types';

import { boolean, custom, number, string, validateOptionsRegistration } from '../../general';

export const validateOptions: ValidateOptions<Partial<CalculateForm>> = {
    fromAddress: string,
    fromCity: custom,
    fromCityAddress: string,
    fromTerminal: number,
    fromType: number,
    toAddress: string,
    toCity: custom,
    toCityAddress: string,
    toTerminal: number,
    toType: number,
    cargoType: number,
    cargoSingle: custom,
    cargoMultiItems: custom,
    ltlPaymentMethod: number,
    ltlPaymentTin: string,
    ltlOptions: custom,
    ltlOptionIsOversized: boolean,
    ltlOptionIsFragile: boolean,
    ltlOptionIsInsured: boolean,
    ltlOptionInsurance: number,
    ltlOptionDescription: {
        type: 'string',
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    tariff: number,
    payer: number,
    ...validateOptionsRegistration,
};
