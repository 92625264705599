import { forwardRef, memo, useImperativeHandle, useRef } from 'react';
import React from 'react';

import { YandexMaps } from '@/services';

import { MapRef } from '../types';
import S from './styles.module.scss';

export const Map = memo(
    forwardRef<MapRef | null>((_, ref) => {
        const blockRef = useRef<HTMLDivElement>(null),
            mapRef = useRef<MapRef | null>(null);

        useImperativeHandle(ref, () => mapRef.current as MapRef);

        YandexMaps.useReady((maps) => {
            if (!blockRef.current) return;

            const map = new maps.Map(
                blockRef.current,
                {
                    bounds: [
                        [55, 25],
                        [60, 60],
                    ],
                    controls: ['zoomControl'],
                },
                {}
            );

            mapRef.current = {
                api: maps,
                map,
            };

            return () => {
                map.destroy();
            };
        });

        return <div className={S.map} ref={blockRef} />;
    })
);
