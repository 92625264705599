import { AddressType, CalculateForm, Cargo, Location, LTLCalculate, Options, Payment, Required } from '@/types';

import { getDataNameByTab, transformGeneralData } from '../../general';
import { AddressData, CargoData } from './types';

export const getValidAddress = (data: Partial<AddressData>, location: Location): LTLCalculate['route'][Location] | undefined => {
    if (data[`${location}Type`] === AddressType.ADDRESS) {
        const [cityAddress, address] = [data[`${location}CityAddress`], data[`${location}Address`]];

        if (!address || !cityAddress) return;

        return {
            address: `${cityAddress}, ${address}`,
            terminal: null,
        };
    }

    const terminal = data[`${location}Terminal`];

    if (!terminal) return;

    return {
        terminal,
        address: null,
    };
};

export const isSingleCargoValid = (data: Partial<Cargo.Single> | undefined): data is Cargo.Single => {
    if (!data) return false;

    return Object.values(data).every((item) => item !== undefined && item !== 0);
};

export const isMultiCargoValid = (data: Partial<Cargo.MultiExtended> | undefined): data is Required<Cargo.MultiExtended> => {
    if (!data) return false;

    return Object.values(data).every((item) => item !== undefined && item !== 0);
};

export const isMultiCargoItemsValid = (data: Partial<Cargo.MultiExtended>[] | undefined): data is Required<Cargo.MultiExtended>[] => {
    if (!data) return false;

    return Object.values(data).every((item) => item !== undefined && isMultiCargoValid(item));
};

export const transformData = (data: Partial<CalculateForm>, optionCategories: Options.Category[]): LTLCalculate | false => {
    const currentTab = 'ltl';

    const PACK_MULTIPLIER = 1.2;

    const from = getValidAddress(data, 'from');
    const to = getValidAddress(data, 'to');

    if (!from || !to) return false;

    const cargo: CargoData | undefined = (() => {
        if (data.cargoType === Cargo.Type.MULTI) {
            const items = data.cargoMultiItems;

            if (!isMultiCargoItemsValid(items)) return;

            return {
                items:
                    (items.map(({ height, weight, places, ...item }) => ({
                        ...item,
                        places,
                        // This probably should not be sent to backend
                        id: undefined,
                        volume: undefined,
                        // Multiply height & weight by number of places
                        height,
                        weight,
                        original_height: height,
                        original_weight: weight,
                        original_length: item.length,
                        original_width: item.width,
                    })) as Partial<Cargo.MultiExtended>[]) ?? [],
                type: Cargo.Type.MULTI,
            };
        }

        if (data.cargoType === Cargo.Type.SINGLE) {
            if (!isSingleCargoValid(data.cargoSingle)) return;

            return {
                items: [
                    {
                        ...data.cargoSingle,
                        weight: data.ltlOptionIsOversized ? data.cargoSingle.weight / data.cargoSingle.places : data.cargoSingle.weight,
                        volume: data.ltlOptionIsOversized ? data.cargoSingle.volume / data.cargoSingle.places : data.cargoSingle.volume,
                        original_volume: data.cargoSingle.volume,
                        original_weight: data.cargoSingle.weight,
                    },
                ] as [Cargo.Single],
                type: Cargo.Type.SINGLE,
            };
        }
    })();

    const { generalOptions } = transformGeneralData(data, { optionCategories, currentTab });

    if (!cargo) return false;

    generalOptions.forEach((optionId) => {
        const optionCategory = optionCategories.find(({ options }) => options.some((option) => option.id === optionId));
        const optionKey = optionCategory?.options.find((item) => item.id === optionId)?.key;

        if (optionCategory?.key === 'pack' && optionKey === 'upakovka-obresetka') {
            if (cargo.type === Cargo.Type.SINGLE) {
                const cargoData = cargo.items[0];

                cargoData.volume *= PACK_MULTIPLIER;
                cargoData.weight *= PACK_MULTIPLIER;
            } else {
                cargo.items.forEach((item) => {
                    if (!item.height || !item.weight) return;

                    item.height *= PACK_MULTIPLIER;
                    item.weight *= PACK_MULTIPLIER;
                });
            }
        }
    });
    return {
        route: {
            from,
            to,
        },
        cargo: cargo as Cargo.Data,
        client: {
            tin: data.ltlPaymentTin,
        },
        options: {
            cargo_options: {
                description: data[getDataNameByTab(currentTab, 'OptionDescription')] ?? '',
                insurance: data[getDataNameByTab(currentTab, 'OptionIsInsured')] ? data[getDataNameByTab(currentTab, 'OptionInsurance')] ?? 0 : null,
                is_fragile: currentTab === 'ltl' ? data.ltlOptionIsFragile ?? false : false,
                is_oversized: currentTab === 'ltl' ? data.ltlOptionIsOversized ?? false : false,
            },
            general_options: generalOptions,
        },
        payment: data[getDataNameByTab(currentTab, 'PaymentMethod')] ?? Payment.Type.CASHLESS_VAT,
        tariff: data.tariff,
    };
};
