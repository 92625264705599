import { isDateSmaller, tinValidateOption } from '@/helpers';
import { ValidateOptions } from '@/shared/modules/form';
import { StringValidateOption } from '@/shared/modules/form/modules/useForm/types';
import { CalculateForm, Entity } from '@/types';

import { boolean, number, string } from './base-types';

const entityPhone = (type: Entity.Type, entityTypeField: keyof CalculateForm): StringValidateOption<Partial<CalculateForm>, string | undefined> => ({
    type: 'string',
    custom: (value, form) => {
        if (form[entityTypeField] === type) {
            if (!value) return ['Введите номер телефона'];

            if (!/([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})$/.test(value)) return ['Введите корректный номер телефона'];
        }
    },
});

export const validateOptionsRegistration: ValidateOptions<Partial<CalculateForm>> = {
    sendDate: {
        type: 'string',
        required: {
            value: true,
            message: 'Укажите дату отправки',
        },
        custom: (value) => {
            if (!value) return ['Введите корректную дату'];

            if (isDateSmaller(value)) return ['Введите корректную дату'];

            return true;
        },
    },
    sendTime: {
        type: 'string',
        custom: (value, { sendDate }) => {
            if (!sendDate || !value) return true;

            if (isDateSmaller(sendDate, value.toString())) return ['Выберите корректное время отправки'];
        },
    },
    customerIsSender: boolean,
    customerIsRecipient: boolean,
    customerType: number,
    customerCompanyPhone: entityPhone(Entity.Type.Company, 'customerType'),
    customerCompanyName: string,
    customerCompanyOrganization: string,
    customerCompanyEmail: string,
    customerCompanyTin: tinValidateOption,
    customerPersonPhone: entityPhone(Entity.Type.Person, 'customerType'),
    customerPersonName: {
        type: 'string',
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    customerPersonIssuedBy: {
        type: 'string',
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    customerPersonPassportDate: string,
    customerPersonPassportRegistrationAddress: {
        type: 'string',
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    customerPersonPassportSerial: string,
    senderType: number,
    senderCompanyPhone: entityPhone(Entity.Type.Company, 'senderType'),
    senderCompanyName: string,
    senderCompanyOrganization: string,
    senderCompanyEmail: string,
    senderCompanyTin: tinValidateOption,
    senderPersonPhone: entityPhone(Entity.Type.Person, 'senderType'),
    senderPersonName: {
        type: 'string',
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    senderPersonIssuedBy: {
        type: 'string',
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    senderPersonPassportDate: string,
    senderPersonPassportRegistrationAddress: {
        type: 'string',
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    senderPersonPassportSerial: string,
    recipientType: number,
    recipientCompanyPhone: entityPhone(Entity.Type.Company, 'recipientType'),
    recipientCompanyName: string,
    recipientCompanyOrganization: string,
    recipientCompanyEmail: string,
    recipientCompanyTin: tinValidateOption,
    recipientPersonPhone: entityPhone(Entity.Type.Person, 'recipientType'),
    recipientPersonName: {
        type: 'string',
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    recipientPersonIssuedBy: {
        type: 'string',
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    recipientPersonPassportDate: string,
    recipientPersonPassportRegistrationAddress: {
        type: 'string',
        custom: (value) => {
            if (!value) return true;

            if (value.length > 255) return ['Значение должно быть не более 255 символов'];
        },
    },
    recipientPersonPassportSerial: string,
};
