import { Base } from '@/services/api/base';
import { FTLCalculate, FtlRegister } from '@/types';
import { Vehicle } from '@/types/interfaces/vehicle';

import { FTLCalculateResponse, FTLIndexCarsRequestData, GetCalculateByBxIdResponse } from './types';

export class FTL extends Base {
    protected basePath = 'requests/ftl';

    public register = (data: FtlRegister) => {
        this.set.data(data);
        this.set.path('register');

        return this.request.post<{ message: string }>();
    };

    public calculate = (data: FTLCalculate) => {
        this.set.data(data);
        this.set.path('calculate');

        return this.request.post<FTLCalculateResponse>();
    };

    public indexCars = (data: FTLIndexCarsRequestData) => {
        this.set.data(data);
        this.set.path('index_cars');

        return this.request.post<{ items: Vehicle[] }>();
    };

    public getCalculateByBxId = (id: string) => {
        this.set.path(`${id}`);

        return this.request.get<GetCalculateByBxIdResponse>();
    };
}
