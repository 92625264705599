import { ChangeEventHandler, useCallback, useRef, useState } from 'react';

interface SearchArgs {
    onInput?: ChangeEventHandler<HTMLInputElement>;
    onSearch?: (value: string) => void;
    delay?: number;
}

export const useSearch = ({ delay, onInput, onSearch }: SearchArgs) => {
    const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
    const [search, setSearch] = useState<string>();

    const handleInput: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            onInput?.(event);

            if (event.isDefaultPrevented()) {
                return;
            }

            const { value } = event.currentTarget;

            setSearch(value);

            if (!delay) {
                onSearch?.(value);
                return;
            }

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = undefined;
            }

            timeoutRef.current = setTimeout(() => {
                onSearch?.(value);
            }, delay);
        },
        [delay, onInput, onSearch]
    );

    return {
        handleInput,
        search,
        setSearch,
    };
};
