import { Base } from '../../base';
import { FTL } from './ftl';
import { IND } from './ind';
import { LTL } from './ltl';

export class Requests extends Base {
    public ind = () => new IND({ basePath: this.basePath });

    public ltl = () => new LTL({ basePath: this.basePath });
    public ftl = () => new FTL({ basePath: this.basePath });
}
