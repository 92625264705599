export const isDateSmaller = (value: string, time?: string) => {
    const [day, month, year] = value.split('.');
    const today = new Date();

    const currentYear = today.getFullYear().toString();
    const fullYear = currentYear.slice(0, currentYear.length - year.length) + year;

    if (time) {
        const [hours, minutes] = time.split(':');

        return (
            Date.UTC(Number(fullYear), Number(month) - 1, Number(day), Number(hours), Number(minutes)) <
            Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes()) + 1000 * 60 * 60
        );
    }

    return Date.UTC(Number(fullYear), Number(month) - 1, Number(day)) < Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
};
