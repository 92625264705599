import { Data } from '../../../types';

const toString = (value: Data.FormField): string => {
    if (typeof value === 'object') return JSON.stringify(value);
    if (typeof value !== 'string' || value === null) return String(value);
    return value;
};

export const toFormData = (data: Data.Form): FormData => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) value.forEach((v) => formData.append(`${key}[]`, toString(v)));
        else formData.append(key, toString(value));
    });

    return formData;
};
