import { Base } from '@/services/api/base';
import { LTLCalculate, LtlRegister } from '@/types';

import { CalculateResponse, GetCalculateByBxIdResponse } from './types';

export class LTL extends Base {
    protected basePath = 'requests/ltl';

    public register = (data: LtlRegister) => {
        this.set.data(data);
        this.set.path('register');

        return this.request.post<{ message: string }>();
    };

    public calculate = (data: LTLCalculate) => {
        this.set.data(data);
        this.set.path('calculate');

        return this.request.post<CalculateResponse>();
    };

    public getCalculateByBxId = (id: string) => {
        this.set.path(`${id}`);

        return this.request.get<GetCalculateByBxIdResponse>();
    };
}
