import cn from 'classnames';
import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { BurgerClose, Calculator, Forklift, Tracking } from '@/assets/icons';
import { useGeneralStore } from '@/shared/lib/stores';
import { Tooltip } from '@/shared/ui';

import S from './styles.module.scss';

export const MobileMenu: React.FC = () => {
    const isOpen = useGeneralStore((state) => state.isMenuOpen);
    const toggleMenu = useGeneralStore((state) => state.toggleMenu);

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (isOpen && event.key === 'Escape') toggleMenu(false);
        },
        [toggleMenu, isOpen]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <div className={cn(S.menu, { [S.open]: isOpen })}>
            <header className={S.menu__header}>
                <a className={cn(S.phone, S.interactive)} href="tel:+78045551045">
                    8 804 555 1045
                </a>
                <button className={cn(S.button, S.interactive)} onClick={() => toggleMenu(false)}>
                    <BurgerClose />
                </button>
            </header>
            <nav className={S.menu__nav}>
                <Link className={S.nav__link} to="/">
                    О компании
                </Link>
                <Link className={S.nav__link} to="/">
                    Услуги
                </Link>
                <Link className={S.nav__link} to="/">
                    Франшиза
                </Link>
                <Link className={S.nav__link} to="/">
                    Новости
                </Link>
                <Link className={S.nav__link} to="/">
                    Оферта
                </Link>
                <Link className={S.nav__link} to="/">
                    Политика
                </Link>
            </nav>
            <div className={S.menu__services}>
                <Link className={S.link} to="/" aria-label="Отслеживание">
                    <Tooltip className={S.tooltip} position="bottom" icon={<Tracking filledClassName={S.tooltip__icon} />}>
                        Отслеживание
                    </Tooltip>
                    <p className={S.link__name}>Отслеживание</p>
                </Link>
                <Link className={S.link} to="/" aria-label="Калькулятор">
                    <Tooltip className={S.tooltip} position="bottom" icon={<Calculator filledClassName={S.tooltip__icon} />}>
                        Калькулятор
                    </Tooltip>
                    <p className={S.link__name}>Калькулятор</p>
                </Link>
                <Link className={S.link} to="/" aria-label="Расстановщик">
                    <Tooltip className={S.tooltip} position="bottom" icon={<Forklift filledClassName={S.tooltip__icon} />}>
                        Расстановщик
                    </Tooltip>
                    <p className={S.link__name}>Расстановщик</p>
                </Link>
            </div>
        </div>
    );
};
