import { memo } from 'react';
import { v4 as uuid } from 'uuid';

import { Icon } from '@/assets/icon';
import { useForm, useFormContext } from '@/shared/modules/form/modules';
import { ButtonWrapper, NumberInput } from '@/shared/ui';
import { CalculateForm, Cargo } from '@/types';

import S from './styles.module.scss';
import { ItemProps } from './types';

export const Item = memo<ItemProps>(({ id, isDeletable }) => {
    const { data, field } = useFormContext<CalculateForm>();

    const { data: itemData, field: itemField, reg } = useForm<Omit<Cargo.MultiExtended, 'id'>>(data.cargoMultiItems?.find((item) => item.id === id));

    const handleNew = () => {
        field('cargoMultiItems').value((prev = []) => [
            ...prev,
            {
                // volume: 1,
                id: uuid(),
            },
        ]);
    };

    const handleDelete = () => {
        field('cargoMultiItems').value((prev = []) => prev.filter((item) => item.id !== id));
    };

    const handleBlur = () => {
        field('cargoMultiItems').value((prev = []) => {
            const found = prev.find((item) => item.id === id);

            if (!found) return prev;

            const { height, length, places, weight, width } = itemData;

            const volume = height && length && width && Math.ceil(height * length * width * 100) / 100;

            found.height = height;
            found.length = length;
            found.places = places && places >= 5000 ? 5000 : places;
            found.volume = volume;
            found.weight = weight;
            found.width = width;

            itemField('volume').value(volume);
            itemField('places').value(found.places);

            return prev;
        });
    };

    return (
        <div className={S.item}>
            <div className={S.dimensions}>
                <NumberInput {...reg('length')} className={S.input} label="Длина, м" placeholder="0.00" onBlur={handleBlur} precision={2} required />
                <Icon.Multiply className={S.calc_icon} />
                <NumberInput {...reg('width')} className={S.input} label="Ширина, м" placeholder="0.00" onBlur={handleBlur} precision={2} required />
                <Icon.Multiply className={S.calc_icon} />
                <NumberInput {...reg('height')} className={S.input} label="Высота, м" placeholder="0.00" onBlur={handleBlur} precision={2} required />
                <Icon.Equal className={S.calc_icon} name="equals" />
                <NumberInput {...reg('volume')} className={S.input} label="Объем, м&sup3;" placeholder="0" readOnly />
            </div>
            <div className={S.right}>
                <div className={S.right__inputs}>
                    <NumberInput {...reg('weight')} className={S.input} label="Вес, кг" placeholder="0.00" onBlur={handleBlur} precision={2} required />
                    <NumberInput {...reg('places')} className={S.input} label="Количество мест" placeholder="1" onBlur={handleBlur} integer required />
                </div>
                <div className={S.right__actions}>
                    {isDeletable ? (
                        <>
                            <ButtonWrapper className={S.action} onClick={handleDelete}>
                                <Icon.Close className={S.action_icon} />
                                <span>Удалить грузовое место</span>
                            </ButtonWrapper>
                        </>
                    ) : (
                        <>
                            <ButtonWrapper className={S.action} onClick={handleNew}>
                                <Icon.Plus className={S.action_icon} />
                                <span>Добавить грузовое место</span>
                            </ButtonWrapper>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});
