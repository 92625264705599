import useModal from '@cyberia-studio/react-modal';
import cn from 'classnames';

import { Dialogs } from '@/components/partials';
import { setDialog } from '@/helpers';
import { Button } from '@/shared/ui';

import S from './styles.module.scss';

interface WidgetIndividualProps {
    widgetClassName?: string;
}

export const WidgetIndividual: React.FC<WidgetIndividualProps> = ({ widgetClassName }) => {
    const [modal, controls] = useModal();

    return (
        <div className={cn(S.individual, widgetClassName)}>
            {modal}
            <p className={S.description}>Можете заказать индивидуальный расчёт вашей грузоперевозки</p>
            <Button className={S.button} theme="bright" onClick={setDialog(controls, <Dialogs.IndividualCalc />)}>
                Сделать расчёт
            </Button>
        </div>
    );
};
