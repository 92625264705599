import { useEffect, useState } from 'react';

import S from './styles.module.scss';
import { Media } from './types';

export const useMedia = (resolution: Media) => {
    const RES = typeof resolution === 'number' ? `${resolution}px` : S[resolution];
    const [isDevice, setIsDevice] = useState(window.matchMedia(`(max-width: ${RES})`).matches);

    useEffect(() => {
        window.matchMedia(`(max-width: ${RES})`).addEventListener('change', (event) => setIsDevice(event.matches));
    }, []);

    return isDevice;
};
