import { useOutside, useTrustedState } from '@cyberia-studio/react-hooks';
import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Icon } from '@/assets/icon';
import { ButtonWrapper, InputBase, Scroll } from '@/shared/ui';

import S from './styles.module.scss';
import { OptionProps, SelectProps } from './types';

export const Option = ({ id, name, isSelected, onSelect }: OptionProps) => {
    const handleClick = () => onSelect(id);

    return (
        <div className={cn(S.select__option, { [S.selected]: isSelected })} onClick={handleClick}>
            {name}
        </div>
    );
};
export const SelectEvents = {
    clear: (id: number | string) => window.dispatchEvent(new Event(`select-clear-${id}`)),
};

export const Select = <T extends string | number>({
    id,
    onBlur,
    text = '',
    callbackDelay,
    onChange,
    onSearch,
    isLoading,
    onClear,
    errors,
    onEnter,
    className,
    children,
    value,
    fillSelected = true,
    'aria-label': ariaLabel,
    ...props
}: SelectProps<T>) => {
    const options = useMemo(() => {
        if (!children) return [];

        return children.map(({ id, name, value }) => ({
            id,
            name,
            value,
        }));
    }, [children]);

    const [isOpen, setOpen] = useState(false),
        [selected, setSelected] = useState<string | number | undefined>(options.find((item) => item.value === value)?.id),
        // [options, setOptions] = useState<{ id: number | string; name: string; value?: T; element: React.ReactNode }[]>(),
        [currentInput, setInput, isInputTrusted] = useTrustedState<string>(text),
        [isInTimeout, setInTimeout] = useState(false),
        [, setSelectOptionIndex] = useState<number>(0),
        [isInputFocused, setInputFocused] = useState(false); //selectOptionInde,
    const blockRef = useRef<HTMLDivElement>(null),
        isCleared = useRef(false),
        timeoutRef = useRef<NodeJS.Timeout | undefined>();

    const handleClear = useCallback(() => {
        isCleared.current = true;

        setInTimeout(false);
        setSelected(undefined);
        setInput('');

        onClear && onClear();
    }, [onClear]);

    useEffect(() => {
        window.addEventListener(`select-clear-${id}`, handleClear);

        return () => {
            window.removeEventListener(`select-clear-${id}`, handleClear);
        };
    }, [handleClear, id]);

    useEffect(() => setSelected(() => options.find((option) => option.value === value)?.id), [value]);
    useEffect(() => setInput(text, false), [text]);
    useEffect(() => {
        fillSelected && setInput(options.find(({ id }) => id === selected)?.name ?? text);
        setOpen(false);
    }, [selected]);

    useEffect(() => {
        if (!currentInput && props.search) setOpen(false);
        else if (!selected && currentInput && isInputTrusted) setOpen(true);
    }, [currentInput]);

    const changedSearch = useCallback(
        (value = '') => {
            if (isCleared.current === true) {
                isCleared.current = false;
                return;
            }

            if (!onSearch) return;

            onSearch(value);
        },
        [onSearch]
    );

    useOutside(
        blockRef,
        () => {
            setOpen(false);
            onBlur?.();
        },
        [isOpen, onBlur]
    );

    const handleKeySelect = (event: KeyboardEvent) => {
        switch (event.key) {
            case 'ArrowUp':
                setSelectOptionIndex((prev) => (prev ? prev - 1 : prev));
                break;
            case 'ArrowDown':
                setSelectOptionIndex((prev) => (options !== undefined && prev < options.length - 1 ? prev + 1 : prev));
                break;
            case 'Enter':
                if (isInputFocused) onEnter?.();
                // if (options !== undefined) {
                //     setSelected((prev) => {
                //         const { id, name, value, element } = options[selectOptionIndex];

                //         // prev?.element.classList.remove('selected');
                //         setOpen(false);

                //         if (id === prev?.id) return;
                //         return { id: id, name: name, value: value, element: element };
                //     });
                // }
                break;
            case 'Escape':
            case 'Tab':
                setOpen(false);
                break;
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeySelect);

        return () => {
            window.removeEventListener('keydown', handleKeySelect);
        };
    }, [handleKeySelect]);

    const handleInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setOpen(true);
            const value = event.currentTarget.value;

            if (onSearch) {
                if (callbackDelay) {
                    clearTimeout(timeoutRef.current);
                    setInTimeout(true);

                    timeoutRef.current = setTimeout(() => {
                        setInTimeout(false);

                        changedSearch(value);
                    }, callbackDelay);
                } else {
                    changedSearch(value);
                }
            }
            setInput(value);
        },
        [callbackDelay, onSearch, changedSearch]
    );

    const handleClick = () => {
        if (currentInput && props.search) {
            setOpen((prev) => !prev);
        } else if (!props.search) {
            setOpen((prev) => !prev);
        }
    };

    const handleOptionClick = (id: number | string) => {
        setSelected(id);

        onChange?.(options.find((item) => item.id === id)?.value);
    };

    const hasInput = useMemo(() => !!(selected || currentInput), [selected, currentInput]);

    const cClear = useMemo(
        () =>
            onClear && (
                <ButtonWrapper className={cn(S.select__clear, { [S.select__clear_active]: hasInput })} onClick={handleClear}>
                    <Icon.Close />
                </ButtonWrapper>
            ),
        [handleClear, hasInput, onClear]
    );

    const elementId = useMemo(() => uuid(), []);

    const handleFocus = () => {
        setInputFocused(true);
    };
    const handleBlur = () => {
        setInputFocused(false);
        onBlur?.();
    };

    return (
        <div ref={blockRef} className={cn(S.select_base, className)}>
            {props.element}
            <InputBase
                className={cn(S.select, { [S.open]: isOpen }, { [S['padding-right']]: onClear })}
                label={props.label}
                aria-label={ariaLabel as string}
                errors={errors}
                required={props.required}
                htmlFor={elementId}
            >
                <input
                    aria-label={ariaLabel}
                    placeholder={props.placeholder}
                    value={currentInput}
                    readOnly={!props.search}
                    onClick={handleClick}
                    onChange={handleInput}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    autoComplete="off"
                    type="text"
                    id={elementId}
                />
                {!props.search ? <Icon.ChevronDown className={S.select__icon} /> : <></>}
                {options && options.length ? (
                    <Scroll className={S.select__options}>
                        {currentInput && !options.length && !isLoading && !props.withoutNotFound ? (
                            <p className={S['select__not-found']}>Нет результатов</p>
                        ) : (
                            <>
                                <div className={cn(S.select__preloader, { [S.visible]: (isLoading || isInTimeout) && props.search })}>
                                    <div className={cn(S['select__preloader-circle'])} />
                                </div>

                                {options.map((option) => (
                                    <Option {...option} onSelect={handleOptionClick} key={`option-${option.id}`} isSelected={selected === option.id} />
                                ))}
                            </>
                        )}

                        {/* {(props.isLoading || isInTimeout) && props.search ? (
                            <div className="select__preloader" />
                        ) : currentInput && !options?.length && !props.isLoading && !props.withoutNotFound ? (
                            <p className="select__not-found">Нет результатов</p>
                        ) : (
                            options?.map((option) => option.element)
                        )} */}
                    </Scroll>
                ) : (
                    <></>
                )}
                {/* {currentInput && !options?.length && !props.isLoading && props.withoutNotFound && (
                    <p className="select__individual-calc">
                        Данное направление <span onClick={() => modal?.set(<Dialogs.IndividualCalc />)}>рассчитывается индивидуально</span>
                    </p>
                )} */}
                {cClear}
            </InputBase>
        </div>
    );
};
