import React from 'react';

export const lineWrap = (list: string[] | string) => {
    if (typeof list === 'string') list = list.split('\n');

    return (
        <>
            {list.map((el, i) => (
                <React.Fragment key={`WithBr_${i + el}`}>
                    {i !== 0 && <br />}
                    {el}
                </React.Fragment>
            ))}
        </>
    );
};
