import { ValidateOptionAll } from '@/shared/modules/form/modules/useForm/types';
import { CalculateForm } from '@/types';

export const tinValidateOption: ValidateOptionAll<Partial<CalculateForm>, string | undefined> = {
    type: 'string',
    custom: (value) => {
        if (!value) return true;

        if (!/^(\d{10}|\d{12})$/.test(value)) return ['ИНН должен содержать 10 или 12 цифр'];

        return true;
    },
};
