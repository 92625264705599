enum Code {
    Success = 200,
    Created = 201,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    ValidationError = 422,
    Error = 500,
    Local = 0,
}

export { Code };
