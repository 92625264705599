import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { YandexMaps } from '@/services';
import { Select } from '@/shared/ui';
import { ISuggestOptions } from '@/types';

import { AddressSearchProps, StateOption } from './types';

export const addressSearchRefresh = () => document.dispatchEvent(new Event('address-search-event-refresh'));

export const AddressSearch = memo<AddressSearchProps>(
    ({
        callbackDelay = 500,
        label,
        autoClear,
        onChange,
        onInput,
        onBlur,
        onEnter,
        onGeocode,
        placeholder,
        onClear,
        required,
        value = '',
        bounds,
        fillSelected = true,
        startWith,
    }) => {
        const wasSelected = useRef(true);

        const [search, setSearch] = useState(value),
            [isLoading, setLoading] = useState(false),
            [options, setOptions] = useState<StateOption[]>([]);
        const [ymaps, isYmapsLoaded] = YandexMaps.useApi();

        useEffect(() => {
            setSearch(value);
        }, [value]);

        const changed = useCallback(
            (value: string) => {
                setSearch(value);

                onChange && onChange(value);
            },
            [onChange]
        );

        const handleSearch = useCallback(
            (value = '') => {
                onInput && onInput(value);
                setSearch(value);
                wasSelected.current = false;

                if (!ymaps) return;

                if (!value) {
                    setOptions([]);
                    return;
                }

                setLoading(true);

                ymaps
                    .suggest(startWith ? `${startWith}, ${value}` : value, {
                        boundedBy: bounds,
                        strictBounds: true,
                    } as ISuggestOptions)
                    .then((res) => {
                        setOptions(() => {
                            if (!res || !res.length) return [];

                            return res.map(({ displayName, value }) => ({ name: displayName, value }));
                        });

                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                        setOptions([]);
                    });
            },
            [isYmapsLoaded, onInput, startWith]
        );
        const handleSelect = useCallback(
            (value: string | number | undefined) => {
                if (typeof value !== 'string' || !ymaps) return;

                wasSelected.current = true;

                fillSelected && changed(value);

                if (!onGeocode) return;

                ymaps
                    .geocode(value, {
                        kind: 'locality',
                    })
                    .then((res) => {
                        const firstObject = res.geoObjects.toArray().at(0);

                        if (!firstObject) return;

                        onGeocode(firstObject);
                    });
            },
            [isYmapsLoaded, fillSelected]
        );
        const handleClear = () => {
            setLoading(false);
            setSearch('');
            setOptions([]);

            wasSelected.current = false;

            onClear && onClear();
        };

        const handleBlur = () => {
            onBlur?.(search);

            search && options && handleSelect(options[0]?.value);

            if (!autoClear || wasSelected.current === true) return;

            handleClear();
        };

        const handleEnter = () => {
            onEnter?.(search);
        };

        const cOptions = useMemo(() => options.map(({ name, value }) => ({ id: name, value, name })), [options]);

        return (
            <Select
                callbackDelay={callbackDelay}
                onSearch={handleSearch}
                onChange={handleSelect}
                isLoading={isLoading}
                onClear={handleClear}
                onBlur={handleBlur}
                onEnter={handleEnter}
                text={search}
                label={label}
                placeholder={placeholder}
                required={required}
                fillSelected={fillSelected}
                search
                withoutNotFound
            >
                {cOptions}
            </Select>
        );
    }
);
