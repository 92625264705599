import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FormContextProvider } from '@/shared/modules/form/modules';
import { AddressType, CalculateForm, Cargo, ChildrenProps, Entity, Payment } from '@/types';

import { useCalculatorLoaderData } from './calculatorLoader';

export const CalculatorContext: FC<ChildrenProps> = ({ children }) => {
    const { data } = useCalculatorLoaderData();
    const [searchParams] = useSearchParams();

    const initialState = useMemo<Partial<CalculateForm>>(() => {
        const getEntityQuery = (entity: Entity.Types, query?: string) => {
            return searchParams.get(`${entity}${query ? `_${query}` : ''}`)?.trim();
        };

        const getEntityData = (data: Partial<CalculateForm>, entity: Entity.Types) => {
            const propertyName = `${entity}${data[`${entity}Type`] === Entity.Type.Company ? 'Company' : 'Person'}` as `${typeof entity}{${'Company' &
                'Person'}}`;

            return {
                [`${entity}Type`]: data[`${entity}Type`],
                [`${propertyName}Name`]: data[`${propertyName}Name`],
                [`${propertyName}Tin`]: data[`${propertyName}Tin`],
                [`${propertyName}Organization`]: data[`${propertyName}Organization`],
                [`${propertyName}Phone`]: data[`${propertyName}Phone`],
                [`${propertyName}Email`]: data[`${propertyName}Email`],
                [`${propertyName}Name`]: data[`${propertyName}Name`],
                [`${propertyName}PassportSerial`]: data[`${propertyName}PassportSerial`],
                [`${propertyName}PassportDate`]: data[`${propertyName}PassportDate`],
                [`${propertyName}PassportRegistrationAddress`]: data[`${propertyName}PassportRegistrationAddress`],
                [`${propertyName}IssuedBy`]: data[`${propertyName}IssuedBy`],
                [`${propertyName}Phone`]: data[`${propertyName}Phone`],
            };
        };

        const copyEntity = (data: Partial<CalculateForm>, from: Entity.Types, to: Entity.Types) => {
            return Object.fromEntries(Object.entries(getEntityData(data, from)).map(([key, value]) => [key.replace(from, to), value]));
        };

        const isEntitySame = (data: Partial<CalculateForm>, entities: ('sender' | 'recipient' | 'customer')[]) => {
            return entities.every((entity) => {
                const entityData = getEntityData(data, entity);

                return entities.every((currentEntity) => {
                    const currentEntityData = copyEntity(data, currentEntity, entity);

                    return Object.entries(entityData).every(([key]) => {
                        return entityData[key] === currentEntityData[key];
                    });
                });
            });
        };

        const isEntityEmpty = (data: Partial<CalculateForm>, entity: Entity.Types) => {
            const entityType = data[`${entity}Type`];

            return [
                'Name',
                'Tin',
                'Organization',
                'Phone',
                'Email',
                'Name',
                'PassportSerial',
                'PassportDate',
                'PassportRegistrationAddress',
                'IssuedBy',
                'Phone',
            ].every((property) => {
                return data[`${entity}${entityType === Entity.Type.Company ? 'Company' : 'Person'}${property}` as keyof CalculateForm] === undefined;
            });
        };

        const getEntity = (entity: Entity.Types, defaultType = Entity.Type.Person): Partial<CalculateForm> => {
            const entityTypeQuery = getEntityQuery(entity);
            const entityType = entityTypeQuery === 'company' ? Entity.Type.Company : entityTypeQuery === 'person' ? Entity.Type.Person : defaultType;

            const propertyName = `${entity}${entityType === Entity.Type.Company ? 'Company' : 'Person'}`;

            return {
                [`${entity}Type`]: entityType,
                [`${propertyName}Name`]: getEntityQuery(entity, 'name'),
                [`${propertyName}Tin`]: getEntityQuery(entity, 'tin'),
                [`${propertyName}Organization`]: getEntityQuery(entity, 'organization'),
                [`${propertyName}Phone`]: getEntityQuery(entity, 'phone'),
                [`${propertyName}Email`]: getEntityQuery(entity, 'email'),
                [`${propertyName}Name`]: getEntityQuery(entity, 'name'),
                [`${propertyName}PassportSerial`]: getEntityQuery(entity, 'passport_serial'),
                [`${propertyName}PassportDate`]: getEntityQuery(entity, 'passport_date'),
                [`${propertyName}PassportRegistrationAddress`]: getEntityQuery(entity, 'passport_registration_address'),
                [`${propertyName}IssuedBy`]: getEntityQuery(entity, 'issued_by'),
                [`${propertyName}Phone`]: getEntityQuery(entity, 'phone'),
            } satisfies Entity.Person & Entity.Company;
        };

        const getPayerType = () => {
            const payer = searchParams.get('payer');

            switch (payer) {
                case 'sender':
                    return Entity.Kind.Sender;
                case 'recipient':
                    return Entity.Kind.Recipient;
                case 'customer':
                    return Entity.Kind.Customer;
                default:
                    return undefined;
            }
        };

        const defaultData: Partial<CalculateForm> = {
            fromType: AddressType.TERMINAL,
            toType: AddressType.TERMINAL,
            cargoType: Cargo.Type.SINGLE,
            ltlPaymentMethod: Payment.Type.CASHLESS_VAT,
            ftlPaymentMethod: Payment.Type.CASHLESS_VATLESS,
            cargoSingle: {
                dimension: 'length',
                weight: 100,
                volume: 0.5,
                max: 1,
                places: 1,
            },
            cargoMultiItems: [
                {
                    id: '1',
                    width: 0.5,
                    height: 0.5,
                    length: 0.5,
                    weight: 100,
                    places: 1,
                    volume: 0.5 ** 3,
                },
            ],
            ltlOptions: [],
            ftlOptions: [],
            payer: Entity.Kind.Customer,
        };

        const mergedData = {
            ...defaultData,
            ...data,
            ...Object.fromEntries(
                Object.entries({
                    ...getEntity('customer', data.customerType),
                    ...getEntity('sender', data.senderType),
                    ...getEntity('recipient', data.recipientType),
                    customerIsSender: getEntityQuery('customer', 'is_sender') ? true : undefined,
                    customerIsRecipient: getEntityQuery('customer', 'is_recipient') ? true : undefined,
                    payer: getPayerType(),
                }).filter(([_, value]) => value !== undefined && value !== null)
            ),
        };

        return {
            ...mergedData,
            ...(getEntityQuery('customer', 'is_sender')
                ? {
                      ...copyEntity(mergedData, 'customer', 'sender'),
                      customerIsSender: true,
                  }
                : {
                      customerIsSender: isEntityEmpty(mergedData, 'sender') ? false : isEntitySame(mergedData, ['sender', 'customer']),
                  }),
            ...(getEntityQuery('customer', 'is_recipient')
                ? {
                      ...copyEntity(mergedData, 'customer', 'recipient'),
                      customerIsRecipient: true,
                  }
                : {
                      customerIsRecipient: isEntityEmpty(mergedData, 'recipient') ? false : isEntitySame(mergedData, ['recipient', 'customer']),
                  }),
        };
    }, [searchParams]);

    return <FormContextProvider<CalculateForm> initialValue={initialState}>{children}</FormContextProvider>;
};
