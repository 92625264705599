import useModal from '@cyberia-studio/react-modal';
import cn from 'classnames';
import { FC } from 'react';

import { useFormContext } from '@/shared/modules/form/modules';
import { Checkbox, Input, NumberInput } from '@/shared/ui';
import { CalculateForm } from '@/types';

import S from './styles.module.scss';

export const Customs: FC = () => {
    const [modal] = useModal();
    const { data, reg } = useFormContext<CalculateForm>();

    return (
        <div className={S.customs}>
            {modal}
            <div className={S.custom}>
                <Input {...reg('ftlOptionDescription')} label="Характер груза" placeholder="Введите характер груза" />
            </div>

            <div className={cn(S.custom, S.custom_column, S.custom_offset, { [S.custom_collapsed]: !data.ftlOptionIsInsured })}>
                <Checkbox {...reg('ftlOptionIsInsured')}>{'Страхование груза'}</Checkbox>
                <NumberInput
                    className={cn(S.insurance, { [S.insurance__visible]: data.ftlOptionIsInsured })}
                    {...reg('ftlOptionInsurance')}
                    label="Объявленная стоимость, ₽"
                    placeholder="0"
                />
            </div>
        </div>
    );
};
