import cn from 'classnames';
import { FC } from 'react';

import { MobileMenu } from '@/components/partials';
import { BaseProps } from '@/types';

import S from './styles.module.scss';

export const CalculatorLayout: FC<BaseProps> = ({ children, className }) => (
    <>
        {/* <Header /> */}
        <MobileMenu />
        <div className={cn(S.content, className)}>{children}</div>
    </>
);
