import cn from 'classnames';
import { FC } from 'react';

import S from './styles.module.scss';
import { BlockProps } from './types';

export const BlockItem: FC<BlockProps> = ({ title, children, className, contentClassName, required }) => (
    <div className={cn(S.block_item, className)}>
        {title && <h2 className={cn(S.title, { [S.required]: required })}>{title}</h2>}
        <div className={cn(S.content, contentClassName)}>{children}</div>
    </div>
);
