import { Tools } from '@/services';

import { BaseField, Errors, FormState, IRuleMessage, RuleMessage, ValidateOptionAll, ValidateOptions } from '../useForm/types';

const ruleValue = <T>(rule: RuleMessage<T>): T => {
    if (typeof rule === 'object' && rule !== null && 'value' in rule) return (rule as IRuleMessage<T>).value;

    return rule;
};

const replacer = <S>(message: string, fieldName: string, rule?: RuleMessage<S>): string => {
    const name = Tools.string.uppercaseFirstLetter(
        fieldName
            .toString()
            .replaceAll(/_id(s)?/gi, '')
            .replaceAll('_', ' ')
    );

    let replaced = message.replaceAll('$field', name);

    if (rule) replaced = replaced.replaceAll('$value', JSON.stringify(ruleValue(rule)));

    return Tools.string.uppercaseFirstLetter(replaced);
};

export const validator = <Data extends object, OD>(state: FormState<Data>, options?: Partial<ValidateOptions<OD>>) => {
    if (!options) return { data: {}, errors: {} };

    const data: Partial<Data> = {};
    const errors: Partial<Record<keyof Data, Errors>> = {};

    const getError = <S>(name: keyof Data, option: ValidateOptionAll<Data, Data[keyof Data]>, rule?: RuleMessage<S>) => {
        if (typeof rule === 'object' && rule !== null && 'value' in rule) return (rule as IRuleMessage<S>).message;
        if (typeof rule === 'string') return rule;

        if (option && option.message) return option.message;

        return options[name]?.message ?? 'Validation error';
    };

    Object.entries(options).forEach(([key, value]) => {
        const name = key as keyof Data;
        const option = value as ValidateOptionAll<Data, Data[keyof Data]>;
        const field = state[name] ?? { errors: [], value: undefined };
        let hasError = false;

        if (!option) return;

        const setError = <S>(option: ValidateOptionAll<Data, Data[keyof Data]>, rule?: RuleMessage<S>, custom?: Errors) => {
            hasError = true;

            errors[name] = custom
                ? custom.map((error) => Tools.string.uppercaseFirstLetter(error))
                : [replacer(getError(name, option, rule), option.name ?? (name as string), rule)];
        };

        if (!field) {
            setError(option);

            return;
        }

        const ifRule = <T>(rule: RuleMessage<T>, fn: (value: NonNullable<T>) => boolean | Errors | void) => {
            if (rule === undefined) return;

            const value = ruleValue(rule);

            if (!value) return;

            const res = fn(value);

            if (typeof res === 'object') setError(option, rule, res);
            else if (res === false) setError(option, rule);
        };

        ifRule(option.required, (rule) => {
            if (rule && field.value === undefined) return false;
        });

        ifRule(option.requiredIfField, (rule) => {
            if (rule && state[rule]?.value && !field.value) return false;
        });

        ifRule(option.custom, (rule) => {
            return rule(
                field.value as Data[keyof Data],
                Object.fromEntries(Object.entries(state).map(([name, field]) => [name as keyof Data, (field as BaseField<unknown>).value])) as Data
            );
        });

        switch (option.type) {
            case 'string': {
                const val = field.value as string | undefined;

                ifRule(option.required, (rule) => rule && !!val);
                ifRule(option.min, (rule) => val !== undefined && val.length > rule);
                ifRule(option.max, (rule) => val !== undefined && val.length < rule);
                ifRule(option.regex, (rule) => val !== undefined && rule.test(val));

                break;
            }
            case 'number': {
                const val = field.value as number | undefined;

                ifRule(option.min, (rule) => val !== undefined && val > rule);
                ifRule(option.max, (rule) => val !== undefined && val < rule);
                break;
            }
            case 'boolean': {
                const val = field.value as boolean | undefined;

                ifRule(option.exact, (rule) => val === rule);
                break;
            }
        }

        if (hasError === false) data[name] = field.value;
    });

    return {
        data: data as Record<keyof OD, Data[keyof Data & keyof OD]> & Partial<Data>,
        errors,
    };
};
