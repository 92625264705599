import { useEffect, useRef } from 'react';

import { api } from '@/services';
import { useFormContext } from '@/shared/modules/form/modules';
import { CalculateForm } from '@/types';

import { useGeneralStore } from '../../general';
import { useLtlStore } from '../store';

export const useLtlOptions = () => {
    const { data, shouldUpdate } = useFormContext<CalculateForm>();
    const { setOptions, setOptionsDataLoading } = useLtlStore();
    const prevData = useRef<string | null>(null);
    const { currentTab } = useGeneralStore();

    useEffect(() => {
        if (currentTab !== 'ltl') return;

        const currentData = {
            address: data.fromCityAddress,
            city: data.fromCity?.id,
            terminal: data.fromTerminal,
        };

        const stringified = JSON.stringify(currentData);

        if (stringified === prevData.current) return;

        prevData.current = stringified;

        setOptionsDataLoading(true);

        api.info()
            .options({
                ...currentData,
                transitType: 'ltl',
            })
            .onSuccess(({ items }) => {
                setOptions(items);
            })
            .finally(() => {
                setOptionsDataLoading(false);
            });
    }, [shouldUpdate]);
};
