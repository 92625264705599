import { useOutside } from '@cyberia-studio/react-hooks';
import cn from 'classnames';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactInputMask from 'react-input-mask';

import { Icon } from '@/assets/icon';
import { ButtonWrapper, MaskInput } from '@/shared/ui';

import S from './styles.module.scss';
import { DatePickerProps, SelectedDate } from './types';

const weekdays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

const get2Digits = (string: string | number) => `0${string}`.slice(-2);

export const DatePicker = memo<DatePickerProps>(
    ({ name, onChange, pastSelect = true, placeholder, text: label, value, errors, required, reverse = false, setUndefinedValue = true }) => {
        const currentDate = new Date();
        const daysRef = useRef<{ [name: string]: HTMLSpanElement | null }>({});
        const blockRef = useRef<HTMLDivElement>(null);
        const pickersRef = useRef<HTMLDivElement>(null);
        const inputRef = useRef<ReactInputMask>(null);

        const [isFocused, setFocused] = useState(false);
        const [text, setText] = useState('');
        const [isOpen, setOpen] = useState(false);
        const [selectedMonth, setMonth] = useState<number>(currentDate.getMonth());
        const [selectedYear, setYear] = useState<number>(currentDate.getFullYear());

        const [selectedDate, setSelectedDate] = useState<SelectedDate>();

        const formatDate = (
            date: string | number | Date,
            setting?: {
                dmhOnly?: boolean;
                splitter?: string;
            }
        ) => {
            const newDate = new Date(date);

            const splitter = setting?.splitter ? setting.splitter : '.';

            const dmh = get2Digits(newDate.getDate()) + splitter + get2Digits(newDate.getMonth() + 1) + splitter + newDate.getFullYear().toString();

            return setting?.dmhOnly ? dmh : dmh + ' ' + get2Digits(newDate.getHours()) + ':' + get2Digits(newDate.getMinutes());
        };

        const changed = useCallback(
            (date: SelectedDate | undefined, cb = true) => {
                const dateSettings = {
                    dmhOnly: true,
                    splitter: '.',
                };

                setText(() => {
                    if (!date) return '';

                    return formatDate(new Date(date.year, date.month, date.day), dateSettings);
                });

                if (!date || !onChange || !cb) return;

                onChange(formatDate(new Date(date.year, date.month, date.day), dateSettings));
            },
            [onChange]
        );

        const handleOpen = () => setOpen((prev) => !prev);

        const handleMonthMinus = () => {
            setMonth((prevState) => {
                if (prevState - 1 < 0) {
                    setYear((prevYear) => prevYear - 1);
                    return 11;
                }

                return prevState - 1;
            });
        };

        const handleMonthPlus = () => {
            setMonth((prevState) => {
                if (prevState + 1 > 11) {
                    setYear((prevYear) => prevYear + 1);
                    return 0;
                }

                return prevState + 1;
            });
        };

        useEffect(() => {
            if (!value) {
                if (setUndefinedValue) {
                    setSelectedDate(undefined);
                    changed(undefined);
                }

                return;
            }

            const [day, month, year] = value.split('.');

            if (!day || !month || !year) return;

            const currentYear = currentDate.getFullYear().toString();
            const fullYear = currentYear.slice(0, currentYear.length - year.length) + year;

            const newDate = { day: Number(day), month: Number(month) - 1, year: Number(fullYear) };

            setSelectedDate(newDate);

            changed(newDate);
        }, [value]);

        const onDayClick = (day: number, month: number, year: number) => {
            if (pastSelect === false && Date.UTC(year, month, day) < Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) return;
            if (pastSelect === true && Date.UTC(year, month, day) > Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) return;

            const newDate = { day: day, month: month, year: year };

            setSelectedDate(newDate);

            changed(newDate);

            setOpen(false);
        };

        const datePickerContent = useMemo(() => {
            const month = selectedMonth;

            const firstDayDate = new Date(selectedYear, month, 1);
            const lastDayDate = new Date(selectedYear, month + 1, 0);
            const prevMonth = new Date(selectedYear, month, 0);
            // const nextMonth = new Date(selectedYear, month + 1, 1);
            let lastDayPastMonth = prevMonth.getDate() + 1;
            const firstMonthDay = firstDayDate.getDay();

            const days = Array.from({ length: lastDayDate.getDate() }, (_x, i) => i + 1);
            const offset = Array.from({ length: (firstMonthDay === 0 ? 7 : firstMonthDay) - 1 }, (_x, i) => i);
            const nextadd = Array.from({ length: 42 - days.length - offset.length });

            const year = firstDayDate.getFullYear();

            return (
                <div className={S.datepicker}>
                    <div className={S.datepicker__header}>
                        <span className={S['datepicker__header-month']} children={firstDayDate.toLocaleString('ru', { month: 'long' })} />
                        <span className={S['datepicker__header-year']} children={year} />
                    </div>
                    <div className={S.datepicker__body}>
                        <div className={S.datepicker__weekdays}>
                            {weekdays.map((day) => (
                                <span className={S.datepicker__weekday} key={`DatePickerDay_${day}`} children={day} />
                            ))}
                        </div>
                        <div className={S.datepicker__month}>
                            {offset
                                .map((_day) => {
                                    const calc = (lastDayPastMonth -= 1);
                                    return <span key={`PrevDays_${calc}_${month}`} />;
                                })
                                .reverse()}
                            {days.map((day) => (
                                <span
                                    key={`CurrDays_${day}_${month}`}
                                    className={cn(S.datepicker__day, S.datepicker__d, {
                                        [S.selected]:
                                            selectedDate && `${day}/${month}/${year}` === `${selectedDate.day}/${selectedDate.month}/${selectedDate.year}`,
                                        [S.past]: reverse
                                            ? Date.UTC(year, month, day) > Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
                                            : Date.UTC(year, month, day) < Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                                        [S.today]:
                                            Date.UTC(year, month, day) === Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                                    })}
                                    ref={(el) => (daysRef.current[`${day}/${month}/${year}`] = el)}
                                    children={day}
                                    onClick={() => onDayClick(day, month, year)}
                                />
                            ))}
                            {nextadd.map((_day, i) => {
                                const calc = i + 1;
                                return <span key={`NextDays_${calc}_${month}`} />;
                            })}
                        </div>
                    </div>
                </div>
            );
        }, [selectedMonth, selectedYear, selectedDate, isOpen]);

        // useEffect(() => {
        //     Object.values(daysRef.current).forEach((element) => element?.classList.remove('selected'));

        //     if (selectedDate) daysRef.current[`${selectedDate.day}/${selectedDate.month}/${selectedDate.year}`]?.classList.add('selected');
        // }, [selectedDate, selectedMonth, isOpen, selectedMonth]);

        useOutside(blockRef, () => setOpen(false));

        const handleInputChange = () => {
            const input = inputRef.current;

            if (!input) {
                setSelectedDate(undefined);
                changed(undefined);
            }

            const text = (input as unknown as { value: string }).value;

            if (!text.trim()) {
                setSelectedDate(undefined);
                changed(undefined);
                return;
            }

            const [day, month, year] = text.replaceAll('_', '').split('.');

            if (!day || !month || !year) return;

            let monthNumber = Number(month) || 0;

            if (monthNumber > 12) monthNumber = 12;

            const date = new Date(`${monthNumber}/${Number(day) || 0}/${Number(year) || 0}`);

            const newDate = { day: date.getDate(), month: date.getMonth(), year: date.getFullYear() };

            setSelectedDate(newDate);
            changed(newDate);
        };

        const handleBlur = () => {
            setFocused(false);
            handleInputChange();
        };

        const handleFocus = () => setFocused(true);

        const handleKey = (event: React.KeyboardEvent) => {
            if (event.code !== 'Enter' || !isFocused) return;

            setFocused(false);
            handleInputChange();
        };

        return (
            <div className={cn(S.date, { [S.open]: isOpen })} ref={blockRef}>
                <Icon.Calendar className={S.calendar} onClick={handleOpen} />
                <MaskInput
                    name={name}
                    placeholder={placeholder}
                    label={label}
                    value={text}
                    onClick={handleOpen}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKey}
                    mask="99.99.9999"
                    ref={inputRef}
                    required={required}
                    errors={errors}
                />
                <div className={S.datepickers} ref={pickersRef}>
                    <div className={S.datetravel}>
                        <ButtonWrapper>
                            <Icon.ChevronDown className={cn(S.icon, S.icon_left)} onClick={handleMonthMinus} />
                        </ButtonWrapper>
                        <ButtonWrapper>
                            <Icon.ChevronDown className={cn(S.icon, S.icon_right)} onClick={handleMonthPlus} />
                        </ButtonWrapper>
                    </div>
                    {datePickerContent}
                </div>
            </div>
        );
    }
);
