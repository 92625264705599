import { FC, useRef } from 'react';

import { CalculatorLayout } from '@/components/layouts';
import { Sidebar, Tabs, WidgetIndividual } from '@/components/partials';
import { LinkHelper } from '@/services';
import { useMedia } from '@/shared/lib/hooks';
import { useGeneralStore } from '@/shared/lib/stores';

import { CalculatorContext, Ftl, Ltl } from './parts';
import S from './styles.module.scss';

export const CalculatorPage: FC = () => {
    const { setTab, currentTab } = useGeneralStore();
    const isTablet = useMedia('tablet');

    const sidebarRef = useRef<HTMLDivElement>(null);

    return (
        <CalculatorContext>
            <CalculatorLayout className={S.calculator}>
                <div className={S.calculator__background}>
                    <div className={S.background__base}></div>
                    <div className={S.background__content}></div>
                </div>
                <div className={S.calculator__header}>
                    <h1>{isTablet ? 'Калькулятор' : 'Калькулятор грузоперевозки'}</h1>
                    {/* <Button
                        className={S.button}
                        color="transparent"
                        text="Рассчитать индивидуально"
                        onClick={setDialog(controls, <Dialogs.IndividualCalc />)}
                    /> */}
                </div>
                {isTablet && <WidgetIndividual widgetClassName={S.individual} />}
                <div className={S.calculator__content}>
                    <Tabs value={currentTab} onChange={setTab} tabClassName={S.tabs}>
                        {[
                            { name: 'Сборный груз (LTL)', path: LinkHelper.ltl, content: <Ltl /> },
                            { name: 'Отдельный авто (FTL)', path: LinkHelper.ftl, content: <Ftl /> },
                        ]}
                    </Tabs>
                    <Sidebar ref={sidebarRef} />
                </div>
            </CalculatorLayout>
        </CalculatorContext>
    );
};
