import cn from 'classnames';
import { FC, Fragment, useMemo } from 'react';

import { useFormContext } from '@/shared/modules/form/modules';
import { ButtonWrapper, PageUI } from '@/shared/ui';
import { Row } from '@/shared/ui/page-ui/parts';
import { CalculateForm, Entity as EntityType } from '@/types';

import { Company } from '../company';
import { Person } from '../person';
import S from './styles.module.scss';
import { EntityProps } from './types';

export const Entity: FC<EntityProps> = ({ children, className, name, title, onChange, inForm }) => {
    const { field, data, shouldUpdate } = useFormContext<CalculateForm>();

    const tabs = useMemo(
        () =>
            [
                {
                    tab: EntityType.Type.Person,
                    text: 'Физ. лицо',
                    content: <Person name={name} onChange={onChange} inForm={inForm} />,
                },
                {
                    tab: EntityType.Type.Company,
                    text: 'Юр. лицо',
                    content: <Company name={name} onChange={onChange} inForm={inForm} />,
                },
            ].map((tab) => {
                const isActive = tab.tab === data[`${name}Type`];

                const handleClick = () => {
                    field(`${name}Type`).value(tab.tab);
                    onChange();
                };

                return [
                    <ButtonWrapper className={cn(S.tab, { [S.tab__active]: isActive })} onClick={handleClick} key={`cargo-tab-${tab.tab}`}>
                        {tab.text}
                    </ButtonWrapper>,
                    isActive && <Fragment key={`cargo-tab-${tab.tab}-content`}>{tab.content}</Fragment>,
                ];
            }),
        [data.cargoType, shouldUpdate, name, onChange]
    );

    return (
        <PageUI.BlockItem
            className={cn(S.entity, className)}
            title={
                <div className={S.entity__heading}>
                    <div className={S.entity__title}>{title}</div>
                    <div className={S.tabs}>{tabs.map((tab) => tab[0])}</div>
                </div>
            }
        >
            <Row className={S.entity__content}>{tabs.map((tab) => tab[1])}</Row>
            {children}
        </PageUI.BlockItem>
    );
};
