import useModal from '@cyberia-studio/react-modal';

import { Preloader, Warning } from '@/components/partials';
import { Dialogs } from '@/components/partials';
import { setDialog } from '@/helpers';
import { useFtlStore } from '@/shared/lib/stores';
import { PageUI } from '@/shared/ui';

import { Vehicle } from '../vehicle';
import S from './styles.module.scss';

export const Vehicles: React.FC = () => {
    const { isVehiclesLoading, isVehiclesSpecial, vehicles, isLocationEmpty, isCompleted, error } = useFtlStore(
        ({ vehicles, isVehiclesSpecial, isVehiclesLoading, isLocationEmpty, isCompleted, error }) => ({
            vehicles,
            isVehiclesLoading,
            isLocationEmpty,
            isVehiclesSpecial,
            isCompleted,
            error,
        })
    );
    const [modal, controls] = useModal();

    return (
        <PageUI.BlockItem
            title={isLocationEmpty ? 'Для выбора автомобиля необходимо указать направление' : 'Выберите автомобиль'}
            className={S.vehicles}
            contentClassName={S.vehicles__content}
        >
            {isVehiclesLoading && <Preloader className={S.preloader} isLoading />}

            <PageUI.Row className={S.vehicles__list}>
                {vehicles.map((vehicle, index) => (
                    <Vehicle {...vehicle} key={`vehicle-${vehicle.id}`} is_demo={!isCompleted || isVehiclesSpecial} />
                ))}
            </PageUI.Row>
            {(isVehiclesSpecial || error) && (
                <Warning
                    messageText="Данное направление"
                    buttonText="расчитывается индивидуально"
                    onButtonClick={setDialog(controls, <Dialogs.IndividualCalc />)}
                />
            )}
            {modal}
        </PageUI.BlockItem>
    );
};
