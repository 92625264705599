import { FC, useMemo } from 'react';

import { YandexMaps } from '@/services';
import { useFormContext } from '@/shared/modules/form/modules';
import { AddressSearch } from '@/shared/ui';
import { YMetaDataProperty } from '@/shared/ui/address-search/types';
import { CalculateForm } from '@/types';

import { translate } from './translate';
import { PlaceProps } from './types';

export const Place: FC<PlaceProps> = ({ location, required }) => {
    const { reg, field, set } = useFormContext<CalculateForm>();
    const [ymaps, isYmapsLoaded] = YandexMaps.useApi();

    const [dataName, dataCityName, str] = useMemo(
        () => [`ftl${location}Address` as const, `ftl${location}CityAddress` as const, translate[location]],
        [location]
    );

    const handleGeocode = (object: ymaps.IGeoObject<ymaps.IGeometry>) => {
        const components = Array.from((object.properties.get('metaDataProperty', {}) as YMetaDataProperty).GeocoderMetaData.Address.Components);

        const cityItemIndex =
            components.findIndex((item) => item.kind === 'locality') ??
            [...components].reverse().findIndex((item) => item.kind === 'province') ??
            [...components].reverse().findIndex((item) => item.kind === 'country');

        field(dataCityName).value(components.at(cityItemIndex)?.name);

        field(dataName).value(
            components
                .slice(cityItemIndex)
                .map((item) => item.name)
                .join(', ')
        );
    };

    const handleClear = () => {
        set((prev) => ({
            ...prev,
            [dataName]: undefined,
            [dataCityName]: undefined,
        }));
    };

    const handleInput = (address: string) => {
        if (!isYmapsLoaded) return;

        if (!address) {
            field(dataName).value(undefined);
            return;
        }

        ymaps.geocode(address, { kind: 'locality' }).then((res) => {
            const firstObject = res.geoObjects.toArray().at(0);

            if (!firstObject) return;

            handleGeocode(firstObject);
        });
    };

    return (
        <AddressSearch
            {...reg(dataName)}
            label={str.label}
            placeholder={str.placeholder}
            required={required}
            onEnter={handleInput}
            onBlur={handleInput}
            onGeocode={handleGeocode}
            onClear={handleClear}
            fillSelected={false}
        />
    );
};
