import { GetCalculateByBxIdResponse } from '@/services/api/methods/requests/ltl/types';
import { AddressType } from '@/types';

export const parseTerminal = ({ from, to }: GetCalculateByBxIdResponse['terminal']) => {
    return {
        fromTerminal: from?.id,
        toTerminal: to?.id,
        fromType: from?.id ? AddressType.TERMINAL : AddressType.ADDRESS,
        toType: to?.id ? AddressType.TERMINAL : AddressType.ADDRESS,
    };
};
