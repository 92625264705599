import { ValidateOptionAll } from '@/shared/modules/form/modules/useForm/types';
import { CalculateForm } from '@/types';

export const number: ValidateOptionAll<Partial<CalculateForm>, number | undefined> | undefined = {
    type: 'number',
};

export const boolean: ValidateOptionAll<Partial<CalculateForm>, boolean | undefined> = {
    type: 'boolean',
};

export const string: ValidateOptionAll<Partial<CalculateForm>, string | undefined> = {
    type: 'string',
};

export const custom: ValidateOptionAll<Partial<CalculateForm>, unknown> = {
    type: 'custom',
};
