import { useTrustedState } from '@cyberia-studio/react-hooks';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { useEffect, useRef } from 'react';

import { Tooltip } from '../tooltip';
import S from './styles.module.scss';
import { RadioAdditionalProps, RadioGroup, RadioGroupItemProps, RadioProps } from './types';

const RadioGroupItem: React.FC<RadioGroupItemProps> = ({
    children,
    name,
    additional,
    onClick,
    selected,
    tooltip,
    additionalBottom,
    additionalClassName,
    labelClassName,
    className,
}) => {
    const supressSelect = (event: React.MouseEvent) => event.preventDefault();

    return (
        <div
            className={cn(S.option, { [S.selected]: selected }, { [S.additional_bottom]: additionalBottom }, className)}
            onClick={onClick}
            onMouseDown={supressSelect}
        >
            {children}
            {name &&
                (additionalBottom ? (
                    <>
                        <div className={S.flex}>
                            <div className={S.option__radio} />
                            <span className={cn(S.option__text, labelClassName)}>{name}</span>
                            {tooltip && <Tooltip>{tooltip}</Tooltip>}
                        </div>
                        {additional && <span className={cn(S.option__text, S.additional, additionalClassName)}>{additional}</span>}
                    </>
                ) : (
                    <>
                        <div className={S.option__radio} />
                        <span className={cn(S.option__text, labelClassName)}>{name}</span>
                        {additional && <span className={cn(S.option__text, S.additional, additionalClassName)}>{additional}</span>}
                        {tooltip && <Tooltip>{tooltip}</Tooltip>}
                    </>
                ))}
        </div>
    );
};

export const Radios: RadioGroup = ({ children, className, disabled, onChange, value, allowEmpty, additionalClassName, labelClassName, optionClassName }) => {
    const blockRef = useRef<HTMLDivElement>(null);
    const [selected, setSelected, isTrusted] = useTrustedState<number | undefined>(value, !value);

    useEffect(() => {
        setSelected(value, false);
    }, [value]);

    useEffect(() => {
        if (onChange && isTrusted) onChange(selected);
    }, [selected]);

    const options: (RadioProps & RadioAdditionalProps)[] = useMemo(() => {
        const newOptions: (RadioProps & RadioAdditionalProps)[] = [];

        React.Children.forEach(children, (child) => {
            if (!React.isValidElement(child) || !child.props) return;

            if (child.props.selected) handleSelect(child.props.id);

            newOptions.push({ additionalClassName, labelClassName, className: optionClassName, ...child.props, onClick: () => handleSelect(child.props.id) });
        });

        return newOptions;
    }, [children]);

    const handleSelect = (option: number) => (allowEmpty ? (option === selected ? setSelected(undefined) : setSelected(option)) : setSelected(option));

    return (
        <div className={cn(S.radios, { [S.disabled]: disabled }, className)} ref={blockRef}>
            <div className={S.radios__options}>
                {options.map((option) => (
                    <React.Fragment key={`option-${option.id}`}>
                        <RadioGroupItem {...option} selected={option.id === selected} />
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

Radios.Item = () => null;
