import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';

import { calculatorLoader, CalculatorPage } from '@/components/pages';
import { LinkHelper } from '@/services';
import GlobalLayout from '@/shared/layouts/Global';

import Sentry from './sentry';

const createBrowserRouterWithSentry = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = createBrowserRouterWithSentry([
    {
        path: LinkHelper.calculator,
        element: <GlobalLayout />,
        children: [
            {
                index: true,
                loader: calculatorLoader,
                element: <CalculatorPage />,
            },
        ],
    },
    {
        path: '/*',
        element: <Navigate to={LinkHelper.calculator} />,
    },
    // Sentry's react-router-v6 integration misses some route types, but supports them by some reason
] satisfies RouteObject[]);

export default router;
