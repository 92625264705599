import { create } from 'zustand';

import { resolveValue } from '@/shared/modules/form/modules/functions';

import { GeneralStore, GeneralStoreSet } from './types';

export const useGeneralStore = create<GeneralStore & GeneralStoreSet>((set) => ({
    currentTab: undefined,
    setTab: (value) => set(({ currentTab }) => ({ currentTab: resolveValue(currentTab, value) })),
    optionPriceTypes: [],
    setOptionPriceTypes: (value) => set(({ optionPriceTypes }) => ({ optionPriceTypes: resolveValue(optionPriceTypes, value) })),
    isMenuOpen: false,
    toggleMenu: () => set((state) => ({ isMenuOpen: !state.isMenuOpen })),
}));
