import { BaseField, FormState, ValidateOptions } from '../useForm/types';

type ToStateFormat = <Data extends object>(data: Data | undefined, validateOptions?: ValidateOptions<Data>) => FormState<Data>;

export const toStateFormat: ToStateFormat = (data, validateOptions) => {
    if (!data) return {} as FormState<Data>;

    type Data = typeof data;

    return Object.fromEntries(
        Object.entries(data).map(([name, value]) => {
            const additional: Partial<BaseField<unknown>> = {
                required: undefined,
            };

            const validateField = validateOptions?.[name as keyof Data];

            if (validateField && validateField.name) additional.label = validateField.name;

            return [name, { value, errors: undefined, ...additional } satisfies BaseField<unknown>];
        })
    ) as FormState<Data>;
};
