import { Addresses, Cargo, Cities, Entity, Payment, Tabs } from '../interfaces';

type RenameByT<T, U> = {
    [K in keyof U as K extends keyof T ? (T[K] extends string ? T[K] : never) : K]: K extends keyof U ? U[K] : never;
};

export type Location = 'from' | 'to';

export enum AddressType {
    ADDRESS = 1,
    TERMINAL = 2,
}

export type Bounds = [[number, number], [number, number]];

interface Address {
    terminal: number;
    address: string;
    cityAddress: string;
    city: Cities.City;
    type: AddressType;
    bounds: Bounds;
}

export type LTLCalculate = {
    route: Addresses;
    cargo: Cargo.Data;
    options: {
        cargo_options: Cargo.Options;
        general_options: number[];
    };
    payment: Payment.Type;
    client: {
        tin?: string;
    };
    tariff?: number;
};

export type FTLCalculate = {
    route: Record<Location, Pick<Address, 'address'>>;
    car_id: number;
    payment_type: number;
    options: {
        cargo_options: {
            insurance: number | null;
            description: string;
        };
        general_options: number[];
    };
    client: {
        tin?: string;
    };
};

export interface FTLAddress {
    ftlAddress: string;
    ftlCityAddress: string;
}

export interface PaymentForm {
    paymentTin: string;
    paymentMethod: Payment.Type;
}

interface Options {
    // Options ids
    options: number[];
    // Custom options
    optionDescription: string;
    optionIsOversized: boolean;
    optionIsFragile: boolean;
    optionIsInsured: boolean;
    optionInsurance: number;
}

export type ByTabs = RenameByT<
    {
        terminal: `${Location}Terminal`;
        address: `${Location}Address`;
        cityAddress: `${Location}CityAddress`;
        city: `${Location}City`;
        type: `${Location}Type`;
        bounds: `${Location}Bounds`;
    },
    Address
> &
    // Addresses
    RenameByT<{ ftlAddress: `${Tabs.Types}${Location}Address`; ftlCityAddress: `${Tabs.Types}${Location}CityAddress` }, FTLAddress> &
    // Form of payment
    RenameByT<{ paymentTin: `${Tabs.Types}PaymentTin`; paymentMethod: `${Tabs.Types}PaymentMethod` }, PaymentForm> &
    // Options
    RenameByT<
        {
            optionIsOversized: 'ltlOptionIsOversized';
            optionIsFragile: 'ltlOptionIsFragile';
            optionIsInsured: 'ltlOptionIsInsured';
        },
        Pick<Options, 'optionIsFragile' | 'optionIsInsured' | 'optionIsOversized'>
    > &
    RenameByT<
        {
            options: `${Tabs.Types}Options`;
            optionDescription: `${Tabs.Types}OptionDescription`;
            optionIsInsured: `${Tabs.Types}OptionIsInsured`;
            optionInsurance: `${Tabs.Types}OptionInsurance`;
        },
        Pick<Options, 'options' | 'optionDescription' | 'optionIsInsured' | 'optionInsurance'>
    >;

export type CalculateForm = ByTabs & {
    /* LTL */
    // Cargos
    cargoType: Cargo.Type;
    cargoSingle: Partial<Cargo.Single>;
    cargoMultiItems: Cargo.MultiExtended[];
    // Tariff (Sidebar directions)
    tariff: number;

    /* FTL */
    // Vehicles
    vehicleId: number;

    /* Registration form */
    // Date & time
    sendDate: string;
    sendTime: string;
    // Entities
    customerIsSender: boolean;
    customerIsRecipient: boolean;
    payer: Entity.Kind;
    // Customer
    customerType: Entity.Type;
    customerCompanyPhone: Entity.Company['phone'];
    customerCompanyName: Entity.Company['name'];
    customerCompanyOrganization: Entity.Company['organization'];
    customerCompanyEmail: Entity.Company['email'];
    customerCompanyTin: Entity.Company['tin'];

    customerPersonPhone: Entity.Person['phone'];
    customerPersonName: Entity.Person['name'];
    customerPersonIssuedBy: Entity.Person['issued_by'];
    customerPersonPassportDate: Entity.Person['passport_date'];
    customerPersonPassportRegistrationAddress: Entity.Person['passport_registration_address'];
    customerPersonPassportSerial: Entity.Person['passport_serial'];
    // Sender
    senderType: Entity.Type;
    senderCompanyPhone: Entity.Company['phone'];
    senderCompanyName: Entity.Company['name'];
    senderCompanyOrganization: Entity.Company['organization'];
    senderCompanyEmail: Entity.Company['email'];
    senderCompanyTin: Entity.Company['tin'];

    senderPersonPhone: Entity.Person['phone'];
    senderPersonName: Entity.Person['name'];
    senderPersonIssuedBy: Entity.Person['issued_by'];
    senderPersonPassportDate: Entity.Person['passport_date'];
    senderPersonPassportRegistrationAddress: Entity.Person['passport_registration_address'];
    senderPersonPassportSerial: Entity.Person['passport_serial'];
    // Receiver
    recipientType: Entity.Type;
    recipientCompanyPhone: Entity.Company['phone'];
    recipientCompanyName: Entity.Company['name'];
    recipientCompanyOrganization: Entity.Company['organization'];
    recipientCompanyEmail: Entity.Company['email'];
    recipientCompanyTin: Entity.Company['tin'];

    recipientPersonPhone: Entity.Person['phone'];
    recipientPersonName: Entity.Person['name'];
    recipientPersonIssuedBy: Entity.Person['issued_by'];
    recipientPersonPassportDate: Entity.Person['passport_date'];
    recipientPersonPassportRegistrationAddress: Entity.Person['passport_registration_address'];
    recipientPersonPassportSerial: Entity.Person['passport_serial'];
};
