import { Preloader } from '@/components/partials';
import { useFtlCalculate, useFtlOptions, useFtlStore, useFtlVehicles } from '@/shared/lib/stores';
import { useFormContext } from '@/shared/modules/form/modules';
import { PageUI } from '@/shared/ui';
import { CalculateForm } from '@/types';

import { PaymentForm, Places, Registration } from '../general';
import { Options } from '../general';
import { Customs, Place, Vehicles } from './parts';

export const Ftl: React.FC = () => {
    useFtlCalculate();
    useFtlOptions();
    useFtlVehicles();

    const { set } = useFormContext<CalculateForm>();
    const { isOrderDataLoading: isOrderDataFetching } = useFtlStore();

    const handleSwap = () => {
        set((prev) => ({
            // other form data
            ...prev,
            ftlfromAddress: prev.ftltoAddress,
            ftlfromCityAddress: prev.ftltoCityAddress,
            ftltoAddress: prev.ftlfromAddress,
            ftltoCityAddress: prev.ftlfromCityAddress,
        }));
    };

    return (
        <>
            <PageUI.Block>
                <Preloader isLoading={isOrderDataFetching} />
                {/* Addresses */}
                <Places from={<Place location="from" required />} to={<Place location="to" required />} onSwap={handleSwap} />
                {/* Vehicles  */}
                <Vehicles />
                {/* Form of payment */}
                <PaymentForm tab={'ftl'} title="Скидка корпоративным клиентам" />
                {/* Additional options */}
                <Options tab={'ftl'}>
                    <Customs />
                </Options>
            </PageUI.Block>
            <Registration tabId={'ftl'} />
        </>
    );
};
