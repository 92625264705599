import { Icon, SVG } from './template';

export const Calculator = Icon(({ size = 70, width = size, height = size, ...props }, ref) => (
    <SVG {...props} width={width} height={height} ref={ref}>
        <path
            d="M29.1667 64.1663H40.8333C55.4167 64.1663 61.25 58.333 61.25 43.7497V26.2497C61.25 11.6663 55.4167 5.83301 40.8333 5.83301H29.1667C14.5833 5.83301 8.75 11.6663 8.75 26.2497V43.7497C8.75 58.333 14.5833 64.1663 29.1667 64.1663Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M48.125 22.1084V25.0251C48.125 27.4167 46.1708 29.4001 43.75 29.4001H26.25C23.8583 29.4001 21.875 27.4459 21.875 25.0251V22.1084C21.875 19.7167 23.8292 17.7334 26.25 17.7334H43.75C46.1708 17.7334 48.125 19.6876 48.125 22.1084Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M23.7303 40.8333H23.764" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M34.9862 40.8333H35.0199" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M46.2416 40.8333H46.2753" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.7303 51.0413H23.764" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M34.9862 51.0413H35.0199" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M46.2416 51.0413H46.2753" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SVG>
));
