import { Icon, SVG } from './template';

export const Widget = Icon(({ width = 40, height = 48, ...props }, ref) => (
    <SVG {...props} width={width} height={height} ref={ref}>
        <path
            d="M20 1.21726e-05C8.96471 1.21726e-05 0 8.99161 0 20.06C0 31.1284 8.96471 40.12 20 40.12H21.1765V47.2C32.6118 41.6776 40 30.68 40 20.06C40 8.99161 31.0353 1.21726e-05 20 1.21726e-05Z"
            fill="#2B303D"
        />
        <path
            d="M16.4 13.4157C16.4 11.2368 18.1985 10.0374 20.8901 10.1394C22.8326 10.2067 24.3771 11.7932 24.3931 13.7376C24.487 15.5557 23.6257 17.2911 22.121 18.3153C20.8421 19.1349 20.1661 20.6315 20.3965 22.1333"
            stroke="white"
            strokeWidth="3.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20 26.1333C21.1046 26.1333 22 27.0288 22 28.1333C22 29.2379 21.1046 30.1333 20 30.1333C18.8954 30.1333 18 29.2379 18 28.1333C18 27.0288 18.8954 26.1333 20 26.1333Z"
            fill="white"
        />
    </SVG>
));
