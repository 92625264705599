import cn from 'classnames';
import Lottie from 'lottie-react';
import { memo } from 'react';

import loader from '@/assets/gifs/loader.json';

import S from './styles.module.scss';
import { PreloaderProps } from './types';

export const Preloader = memo<PreloaderProps>(({ isLoading, className }) => (
    <div className={cn(S.preloader, { [S.active]: isLoading }, className)}>
        <Lottie animationData={loader} loop />
    </div>
));
