import cn from 'classnames';
import { memo, ReactNode, useMemo } from 'react';

import { Deliveries } from '@/types';

import S from './styles.module.scss';

export const Option = memo<Omit<Deliveries.Option, 'id'>>(({ name, price, full_price, type }) => {
    const cPrice: ReactNode = useMemo(() => {
        if (type !== Deliveries.OptionType.Discount) return <span className={S.price}>{price}&nbsp;₽</span>;

        return (
            <span className={S.discount}>
                <span className={cn(S.discount__price, S.price)}>{price}&nbsp;₽</span>
            </span>
        );
    }, [full_price, price, type]);

    return (
        <p className={S.option}>
            <span>{name}</span>
            {cPrice}
        </p>
    );
});
