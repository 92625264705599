import { GetCalculateByBxIdResponse } from '@/services/api/methods/requests/ltl/types';
import { CalculateForm, Cargo } from '@/types';

export const parseCargo = ({
    cargo: cargoItems,
    cargo_type: cargoType,
}: Pick<GetCalculateByBxIdResponse, 'cargo_type' | 'cargo'>): Pick<CalculateForm, 'cargoSingle' | 'cargoMultiItems' | 'cargoType'> => {
    const isMulti = +cargoType === Cargo.Type.MULTI || cargoItems.length > 1;

    const getSingle = (): CalculateForm['cargoSingle'] => {
        if (isMulti) {
            return {};
        }

        const cargo = cargoItems.at(0);

        if (!cargo) {
            return {};
        }

        const getDimension = (): Cargo.SingleDimension => {
            const maxType = cargo.max_type as Cargo.SingleDimension;
            const dimensions: Cargo.SingleDimension[] = ['height', 'length', 'width'];

            if (!dimensions.includes(maxType)) {
                return 'length';
            }

            return maxType;
        };

        return {
            dimension: getDimension(),
            max: +cargo.max,
            places: +cargo.places,
            volume: +cargo.original_volume,
            weight: +cargo.original_weight,
        };
    };

    const getMultiItems = (): CalculateForm['cargoMultiItems'] => {
        if (!isMulti) {
            return [
                {
                    id: '1',
                },
            ];
        }

        return cargoItems.map((cargo, index) => ({
            id: `cargo-id-${index}`,
            height: +cargo.original_height,
            length: +cargo.original_length,
            places: +cargo.places,
            volume: +cargo.original_volume,
            weight: +cargo.original_weight,
            width: +cargo.original_width,
        }));
    };

    return {
        cargoSingle: getSingle(),
        cargoMultiItems: getMultiItems(),
        cargoType: isMulti ? Cargo.Type.MULTI : Cargo.Type.SINGLE,
    };
};
