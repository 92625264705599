import { Dictionary } from './dictionary';
import { Info } from './info';
import { Requests } from './requests';

// export { Info, Requests };

const info = () => new Info();
const requests = () => new Requests();
const dictionary = () => new Dictionary();

export { dictionary, info, requests };
