import cn from 'classnames';
import { FC } from 'react';

import { ButtonWrapper } from '@/shared/ui';

import S from './styles.module.scss';
import { DeliveryProps } from './types';

export const Delivery: FC<DeliveryProps> = ({ icon, id, name, onSelect, isSelected, time, total }) => {
    const handleSelect = () => onSelect(id);

    return (
        <ButtonWrapper className={cn(S.delivery, { [S.selected]: isSelected })} onClick={handleSelect}>
            <div className={S.info}>
                <img src={icon} alt={name} />
                <p className={S.text}>
                    <span className={S.text__name}>{name}</span>
                    <span className={S.text__price}>{total} ₽</span>
                </p>
            </div>
            <span className={S.time}>{time}</span>
        </ButtonWrapper>
    );
};
