import useModal from '@cyberia-studio/react-modal';
import cn from 'classnames';
import { FC, useMemo } from 'react';

import { Dialogs } from '@/components/partials';
import { setDialog } from '@/helpers';
import { YandexMaps } from '@/services';
import { useLtlStore } from '@/shared/lib/stores';
import { useFormContext } from '@/shared/modules/form/modules';
import { AddressSearch, Radios } from '@/shared/ui';
import { YMetaDataProperty } from '@/shared/ui/address-search/types';
import { AddressType, CalculateForm } from '@/types';

import S from './styles.module.scss';
import { translate } from './translate';
import { Bounds, PlaceProps } from './types';

export const Place: FC<PlaceProps> = ({ location, required }) => {
    const [modal, controls] = useModal();
    const { city } = useLtlStore();

    const { reg, field, set, data, shouldUpdate } = useFormContext<CalculateForm>();
    const [ymaps, isYmapsLoaded] = YandexMaps.useApi();

    const str = useMemo(() => translate[location], [location]);

    const bounds = field(`${location}Bounds`).value();

    const handleAddressGeocode = (object: ymaps.IGeoObject<ymaps.IGeometry>) => {
        const components = (object.properties.get('metaDataProperty', {}) as YMetaDataProperty).GeocoderMetaData.Address.Components;

        const cityItemIndex = components.findIndex((item) => item.kind === 'locality');

        field(`${location}Address`).value(
            components
                .slice(cityItemIndex + 1)
                .map((item) => item.name)
                .join(', ')
        );
    };

    const info = useMemo(() => {
        if (data[`${location}Type`] === AddressType.ADDRESS)
            return (
                <div className={S.info}>
                    <AddressSearch
                        {...reg(`${location}Address`)}
                        onClear={() => field(`${location}Address`).value(undefined)}
                        onGeocode={handleAddressGeocode}
                        label={str.addressInput}
                        placeholder={str.addressPlaceholder}
                        required={required}
                        bounds={bounds}
                        fillSelected={false}
                        startWith={data[`${location}CityAddress`]}
                    />
                </div>
            );

        const terminal = data[`${location}Terminal`];

        if (terminal) {
            const { address, storage } = city.terminal.byId(terminal) ?? {};

            return (
                <div className={cn(S.info, S.info__terminal)}>
                    <h3>{str.terminalInput}</h3>
                    <p>
                        {address}
                        {!!storage && `, склад ${storage}`}
                    </p>
                    <span onClick={setDialog(controls, <Dialogs.PlaceSelect direction={location} />)}>Выбрать отделение</span>
                </div>
            );
        }

        if (data[`${location}CityAddress`])
            return (
                <div className={cn(S.info, S.info__not_found)}>
                    <p>В указанном населенном пункте осуществляется только адресная доставка.</p>
                </div>
            );
    }, [shouldUpdate, bounds, handleAddressGeocode, data]);

    const handleGeocode = (object: ymaps.IGeoObject<ymaps.IGeometry>) => {
        const bounds = object.properties.get('boundedBy', {}) as Bounds;

        const components = Array.from((object.properties.get('metaDataProperty', {}) as YMetaDataProperty).GeocoderMetaData.Address.Components);

        const cityItemIndex =
            components.findIndex((item) => item.kind === 'locality') ??
            [...components].reverse().findIndex((item) => item.kind === 'province') ??
            [...components].reverse().findIndex((item) => item.kind === 'country');

        const fullAddress = components.slice(cityItemIndex).map((item) => item.name);

        const locality = {
            city: fullAddress.at(0),
            address: fullAddress.slice(1).join(', '),
        };

        if (!locality.city) {
            set((prev) => ({
                ...prev,
                [`${location}Address`]: undefined,
                [`${location}CityAddress`]: undefined,
                [`${location}City`]: undefined,
                [`${location}Type`]: AddressType.TERMINAL,
                [`${location}Bounds`]: undefined,
            }));

            return;
        }

        const foundCity = city.byName(locality.city);

        if (!foundCity) {
            set((prev) => ({
                ...prev,
                [`${location}Address`]: locality.address,
                [`${location}CityAddress`]: locality.city,
                [`${location}City`]: undefined,
                [`${location}Type`]: AddressType.ADDRESS,
                [`${location}Bounds`]: bounds,
            }));

            return { address: locality.address, city: undefined, cityName: undefined };
        }

        const foundTerminal = city.terminal.default(foundCity.id);

        if (!foundTerminal) {
            set((prev) => ({
                ...prev,
                [`${location}Address`]: locality.address,
                [`${location}CityAddress`]: locality.city,
                [`${location}City`]: foundCity,
                [`${location}Type`]: AddressType.ADDRESS,
                [`${location}Bounds`]: bounds,
            }));

            return;
        }

        set((prev) => {
            if (prev[`${location}Type`] === AddressType.ADDRESS && prev[`${location}City`]?.id === foundCity.id) {
                return {
                    [`${location}Address`]: locality.address,
                    [`${location}CityAddress`]: locality.city,
                    [`${location}Bounds`]: bounds,
                    ...prev,
                };
            }

            return {
                ...prev,
                [`${location}Address`]: locality.address,
                [`${location}CityAddress`]: locality.city,
                [`${location}City`]: foundCity,
                [`${location}Terminal`]: foundTerminal.id,
                [`${location}Type`]: AddressType.TERMINAL,
                [`${location}Bounds`]: bounds,
            };
        });
    };

    const handleClear = () => {
        set((prev) => ({
            ...prev,
            [`${location}Address`]: undefined,
            [`${location}CityAddress`]: undefined,
            [`${location}City`]: undefined,
            [`${location}Terminal`]: undefined,
            [`${location}Bounds`]: undefined,
            [`${location}Type`]: AddressType.ADDRESS,
        }));
    };

    const handleInput = (address: string) => {
        if (!isYmapsLoaded) return;

        if (!address) {
            field(`${location}CityAddress`).value(undefined);
            field(`${location}City`).value(undefined);
            field(`${location}Bounds`).value(undefined);
            return;
        }

        ymaps.geocode(address, { kind: 'locality' }).then((res) => {
            const firstObject = res.geoObjects.toArray().at(0);

            if (!firstObject) return;

            handleGeocode(firstObject);
        });
    };

    return (
        <div className={cn(S.place, { [S.place__disabled]: !data[`${location}CityAddress`] })}>
            {modal}
            <AddressSearch
                {...reg(`${location}CityAddress`)}
                label={str.label}
                placeholder={str.placeholder}
                required={required}
                onEnter={handleInput}
                onBlur={handleInput}
                onGeocode={handleGeocode}
                onClear={handleClear}
                fillSelected={false}
            />
            <div className={S.content}>
                <Radios className={S.radios} disabled={!data[`${location}CityAddress`]} {...reg(`${location}Type`)} allowEmpty={false}>
                    <Radios.Item id={AddressType.TERMINAL} name={str.terminal} />
                    <Radios.Item id={AddressType.ADDRESS} name={str.addressSelect} />
                </Radios>
                {info}
            </div>
        </div>
    );
};
