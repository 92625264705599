import { useOutside, useRefEffect, useRefReady } from '@cyberia-studio/react-hooks';
import { useModalContext } from '@cyberia-studio/react-modal';
import cn from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import ymaps from 'yandex-maps';

import { Icon } from '@/assets/icon';
import { useLtlStore } from '@/shared/lib/stores';
import { useFormContext } from '@/shared/modules/form/modules';
import { Button, Input } from '@/shared/ui';
import { CalculateForm, Coordinates, Terminals } from '@/types';

import { Map } from '../map';
import { MapRef } from '../types';
import S from './styles.module.scss';
import { TerminalProps } from './types';

const getCoordinates = (c: Coordinates): [number, number] => [Number(c.lat), Number(c.lon)];

export const Terminal: FC<TerminalProps> = ({ location }) => {
    const { close } = useModalContext();
    const { field, data } = useFormContext<CalculateForm>();
    const { city, cities } = useLtlStore(({ city, cities }) => ({
        city,
        cities,
    }));
    const scrollRef = useRef<HTMLDivElement>(null);
    const focusRef = useRef<HTMLInputElement>(null);
    const [selectedId, setSelectedId] = useState<number | undefined>();
    const [search, setSearch] = useState('');
    const [isExpanded, setExpand] = useState(false);

    const handleSelect = (id?: number) => {
        if (!id) {
            setSelectedId(undefined);
            return;
        }

        setSelectedId(city.terminal.byId(id)?.id);
    };

    const handleSearch = (search: string): void => setSearch(search.toLocaleLowerCase());
    const handleClick = () => handleSelect();
    const handleSelectTerminal = () => {
        field(`${location}Terminal`).value(selectedId);
        close();
    };
    const currentCityId = data[`${location}City`]?.id;
    const terminals = useMemo(
        () =>
            currentCityId
                ? city.byId(currentCityId)?.terminals ?? []
                : cities.reduce<Terminals.TerminalExtended[]>((list, city) => [...list, ...city.terminals], []),
        [cities, currentCityId]
    );

    const searchResults = useMemo(() => {
        if (!currentCityId) return [];

        if (!search) return city.byId(currentCityId)?.terminals ?? [];

        return city.byId(currentCityId)?.terminals.filter(({ address }) => address.toLocaleLowerCase().includes(search)) ?? [];
    }, [search, currentCityId]);

    const mapRef = useRefReady<MapRef>(({ map, api }) => {
        const currentCityCoordinates = currentCityId && city.byId(currentCityId)?.coordinates;

        if (currentCityCoordinates) {
            map.setCenter(getCoordinates(currentCityCoordinates), 10);
        } else {
            api.geolocation.get().then((res) => {
                map.setCenter(res.geoObjects.position, 10);
            });
        }

        if (!terminals) return;

        const handleZoom = (event: ymaps.MapEvent) => {
            const zoom = event.get('newZoom');

            if (zoom === event.get('oldZoom')) return;

            map.geoObjects.each((marker) => {
                const options = marker.options as typeof marker.options & { set: (n: string, v: string | object | number[]) => void };

                options.set('iconOffset', [(-zoom + 21) * -0.7, (-zoom + 21) * -1.3]);
            });
        };

        const layout = api.templateLayoutFactory.createClass(
            [
                '<div>',
                '<svg width="24" height="24" viewBox="0 0 24 24" style="position: absolute; cursor: pointer" fill="none" xmlns="http://www.w3.org/2000/svg">',
                '<path d="M14.675 16.531C14.6107 16.554 14.5518 16.5899 14.502 16.6365C14.4521 16.6831 14.4123 16.7394 14.385 16.802L12 22.252L9.615 16.802C9.58761 16.7396 9.54776 16.6835 9.49789 16.637C9.44802 16.5906 9.38918 16.5549 9.325 16.532C7.76939 15.9784 6.42285 14.9576 5.46965 13.6093C4.51645 12.2611 4.00315 10.6512 4 9C4 4.589 7.589 1 12 1C16.411 1 20 4.589 20 9C20 12.373 17.86 15.4 14.675 16.531Z" fill="$[properties.iconContent]"/>',
                '<path d="M12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13Z" fill="white" />',
                '</svg>',
                '</div',
            ].join(' ')
        );

        terminals.forEach(({ coordinates, id }) => {
            const marker = new api.Placemark(
                getCoordinates(coordinates),
                {
                    iconContent: '#F83600',
                },
                {
                    iconLayout: layout,
                    iconImageSize: [48, 48],
                    zIndex: 100,
                    iconShape: {
                        type: 'Rectangle',
                        coordinates: [
                            [0, 0],
                            [24, 24],
                        ],
                    } as ymaps.IGeometryJson,
                }
            );

            map.geoObjects.add(marker);

            marker.events.add('click', () => handleSelect(id));
        });

        map.events.add('click', handleClick);
        map.events.add('boundschange', handleZoom);

        return () => {
            map.geoObjects.removeAll();
            map.events.remove('click', handleClick);
            map.events.remove('boundschange', handleZoom as () => void);
        };
    }, []);

    const handleScroll = () => setExpand(true);
    const handleBlur = () => setExpand(false);

    useOutside(scrollRef, () => setExpand(false), [isExpanded]);

    useEffect(() => {
        mapRef.current?.map.container.fitToViewport();
    }, [isExpanded, selectedId]);

    useRefEffect(
        (input) => {
            if (isExpanded) input.focus();
        },
        focusRef,
        [isExpanded]
    );

    const terminalInformation = useMemo(() => {
        if (!selectedId)
            return searchResults.map(({ id, address, storage }) => {
                return (
                    <div className={S.place} key={`ps-terminal-${id}`} onClick={() => handleSelect(id)}>
                        <Icon.Pin className={S.place__icon} />
                        <p className={S.place__content}>
                            {address}
                            {!!storage && `, склад ${storage}`}
                        </p>
                    </div>
                );
            });

        const terminal = city.terminal.byId(selectedId);

        if (!terminal) return;

        return (
            <div className={S.selected}>
                <div className={S.selected__container}>
                    <div className={S.selected__name}>
                        <Icon.Pin className={S.icon} />
                        <p>
                            {terminal.address}
                            {!!terminal.storage && `, склад ${terminal.storage}`}
                        </p>
                    </div>
                    {!!terminal.contacts.length && (
                        <div className={S.selected__phone}>
                            <Icon.Phone className={S.icon} />
                            <div className={S.list}>
                                {terminal.contacts.map(({ id, content }) => (
                                    <p key={`terminal-contact-${id}`}>
                                        {content.name}: <a href={`tel:+${content.phone}`}>{content.phone}</a>
                                    </p>
                                ))}
                            </div>
                        </div>
                    )}
                    {!!terminal.schedules.length && (
                        <div className={S.selected__schedule}>
                            <Icon.Calendar className={S.icon} />
                            <div className={S.list}>
                                {terminal.schedules.map(({ id, content }) => (
                                    <p key={`terminal-schedules-${id}`}>
                                        {content.split(' : ').map((item) => (
                                            <span key={`terminal-schedules-${id}-item-${item}`}>{item}</span>
                                        ))}
                                    </p>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className={S.selected__buttons}>
                    <Button className={S.selected__select} onClick={handleSelectTerminal}>
                        Выбрать отделение
                    </Button>
                    <button className={S.selected__back} onClick={() => handleSelect(undefined)}>
                        Выбрать другое отделение
                    </button>
                </div>
            </div>
        );
    }, [selectedId, searchResults]);

    return (
        <>
            <div className={cn(S.terminal, { [S['terminal--expanded']]: isExpanded || selectedId })}>
                <div className={S.container}>
                    <input className={S.input_hidden} ref={focusRef} onBlur={handleBlur} type="hidden" />
                    {!selectedId && <Input className={S.search} placeholder="Поиск" onChange={handleSearch} value={search} />}
                    <Icon.Close className={S.close} onClick={() => close()} />
                    <div className={S.container__scroll} onScroll={handleScroll} ref={scrollRef}>
                        {terminals.length === 0 ? <p className={S.not_found}>Отделений в указанном городе нет.</p> : terminalInformation}
                    </div>
                </div>
            </div>
            <Map ref={mapRef.set} />
        </>
    );
};
