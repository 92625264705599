import { useEffect, useState } from 'react';

const useTooltipPosition = (element: HTMLDivElement | null, position: 'right' | 'bottom') => {
    const [elementPosition, setElementPosition] = useState(element?.getBoundingClientRect());
    const [positionX, setPositionX] = useState<number>(0);
    const [positionY, setPositionY] = useState<number>(0);
    const [relativeX, setRelativeX] = useState<'left' | 'right'>('right');

    useEffect(() => {
        const handleUpdate = () => {
            setElementPosition((prev) => element?.getBoundingClientRect() || prev);
        };

        document.addEventListener('scroll', handleUpdate);
        document.addEventListener('resize', handleUpdate);
        document.addEventListener('click', handleUpdate);
        return () => {
            document.removeEventListener('scroll', handleUpdate);
            document.removeEventListener('resize', handleUpdate);
            document.removeEventListener('click', handleUpdate);
        };
    }, [element]);

    useEffect(() => {
        if (!element) return;
        setElementPosition(element.getBoundingClientRect());
    }, [element]);

    useEffect(() => {
        if (!elementPosition || !element) return;

        const leftSpace = elementPosition.left;
        const rightSpace = window.innerWidth - elementPosition.right;

        setRelativeX(leftSpace > rightSpace ? 'left' : 'right');

        if (position === 'right') {
            setPositionX(leftSpace < rightSpace ? elementPosition.left + window.scrollX : elementPosition.right + window.scrollX - 16);
            setPositionY(elementPosition.top + window.scrollY);
        } else {
            setPositionX(elementPosition.left - elementPosition.width / 2 + window.scrollX - 1);
            setPositionY(elementPosition.bottom + window.scrollY + 15);
        }
    }, [elementPosition]);

    return { positionX, positionY, relativeX };
};

export default useTooltipPosition;
