import { useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { api } from '@/services';
import { useFormContext } from '@/shared/modules/form/modules';
import { CalculateForm } from '@/types';

import { useGeneralStore } from '../../general';
import { useLtlStore } from '../store';
import { transformData } from '../transform';

export const useLtlCalculate = () => {
    const { data, shouldUpdate } = useFormContext<CalculateForm>();
    const { setCompleted, setSpecial, set, setLoading, options } = useLtlStore();
    const prevData = useRef<string | null>(null);
    const { currentTab } = useGeneralStore();

    useEffect(() => {
        if (currentTab !== 'ltl') return;

        const validatedData = transformData({ ...data, tariff: undefined }, options);

        if (!validatedData) {
            setCompleted(false);
            setLoading(false);

            prevData.current = '';

            return;
        }

        const stringified = JSON.stringify(validatedData);

        if (stringified === prevData.current) {
            return;
        }

        prevData.current = stringified;

        setLoading(true);

        api.requests()
            .ltl()
            .calculate(validatedData)
            .onSuccess(({ items }) => {
                set(items.map((item) => ({ ...item, entries: item.entries.map((entry) => ({ ...entry, key: uuid() })) })));
                setSpecial(items.length === 0);
                setCompleted(items.length !== 0);
                setLoading(false);
            })
            .onErrors(() => {
                setLoading(false);
                setCompleted(false);
            });
    }, [shouldUpdate]);
};
