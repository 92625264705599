import { CalculateForm, Entity, Tabs } from '@/types';

import { GeneralData, GeneralOptions, GeneralResult, RegisterData, RegisterResult } from './types';

export const getDataNameByTab = <Tab extends Tabs.Types, Name extends string>(tab: Tab, name: Name): `${Tab}${Name}` => `${tab}${name}`;

export const transformGeneralData = (data: GeneralData, { currentTab, optionCategories }: GeneralOptions): GeneralResult => {
    const generalOptions = (data[getDataNameByTab(currentTab, 'Options')] || []).filter((id) =>
        optionCategories.some(({ options }) => options.some((option) => option.id === id))
    );

    return {
        generalOptions,
    };
};

const getEntity = <T extends keyof Entity.Entities>(data: RegisterData<T>, kind: T): Entity.Entities[T] => {
    return {
        data:
            (data[`${kind}Type`] as Entity.Type) === Entity.Type.Person
                ? {
                      phone: data[`${kind}PersonPhone`],
                      name: data[`${kind}PersonName`],
                      issued_by: data[`${kind}PersonIssuedBy`],
                      passport_date: data[`${kind}PersonPassportDate`],
                      passport_registration_address: data[`${kind}PersonPassportRegistrationAddress`],
                      passport_serial: data[`${kind}PersonPassportSerial`],
                  }
                : {
                      phone: data[`${kind}CompanyPhone`],
                      name: data[`${kind}CompanyName`],
                      email: data[`${kind}CompanyEmail`],
                      tin: data[`${kind}CompanyTin`],
                      organization: data[`${kind}CompanyOrganization`],
                  },
        type: data[`${kind}Type`] || Entity.Type.Person || Entity.Type.Person,
    };
};

export const transformDataRegister = (data: Partial<CalculateForm>): RegisterResult => {
    const getSendDate = (date: string | undefined) => {
        if (!date) {
            return;
        }

        const [day, month, year] = date.split('.');

        return `${day}.${month}.${year}`;
    };

    return {
        registration: {
            send: {
                date: getSendDate(data.sendDate),
                time: data.sendTime,
            },
            entities: {
                customer: getEntity(data, 'customer'),
                recipient: getEntity(data, 'recipient'),
                sender: getEntity(data, 'sender'),
                payer: data.payer || Entity.Kind.Customer,
            },
        },
        tariff: data.tariff || 0,
    };
};
