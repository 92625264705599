import { Vehicles } from '@/types';

export const demoVehicles: Vehicles.Vehicle[] = [
    {
        id: -1,
        name: 'Газель, 1.5 тонн',
        type: 6,
        max_weight: 1500,
        max_volume: 12,
        max_pallets: 2,
        max_length: 3,
        max_width: 2,
        max_height: 2,
        max_distance: 1000,
        hour_pay: 450,
        before_limit_unvat_pay: 1000,
        after_limit_unvat_pay: 1,
        icon: 'https://test.backend.terminal.cyberia.studio/car_icons/easy_truck.svg',
        is_demo: true,
    },
    {
        id: -2,
        name: 'Грузовой, 3 тонны',
        type: 6,
        max_weight: 3000,
        max_volume: 16,
        max_pallets: 4,
        max_length: 4,
        max_width: 2,
        max_height: 2,
        max_distance: 1000,
        hour_pay: 450,
        before_limit_unvat_pay: 1000,
        after_limit_unvat_pay: 1,
        icon: 'https://test.backend.terminal.cyberia.studio/car_icons/truck.svg',
        is_demo: true,
    },
    {
        id: -3,
        name: 'Грузовой, 5 тонн',
        type: 6,
        max_weight: 5000,
        max_volume: 30,
        max_pallets: 6,
        max_length: 5,
        max_width: 3,
        max_height: 2,
        max_distance: 1000,
        hour_pay: 450,
        before_limit_unvat_pay: 1000,
        after_limit_unvat_pay: 1,
        icon: 'https://test.backend.terminal.cyberia.studio/car_icons/truck.svg',
        is_demo: true,
    },
    {
        id: -4,
        name: 'Грузовой, 10 тонн',
        type: 6,
        max_weight: 10000,
        max_volume: 54,
        max_pallets: 15,
        max_length: 6,
        max_width: 3,
        max_height: 3,
        max_distance: 1000,
        hour_pay: 450,
        before_limit_unvat_pay: 1000,
        after_limit_unvat_pay: 1,
        icon: 'https://test.backend.terminal.cyberia.studio/car_icons/truck.svg',
        is_demo: true,
    },
    {
        id: -5,
        name: 'Грузовой, 20 тонн',
        type: 6,
        max_weight: 20000,
        max_volume: 10,
        max_pallets: 50,
        max_length: 12,
        max_width: 5,
        max_height: 3,
        max_distance: 1000,
        hour_pay: 450.0,
        before_limit_unvat_pay: 1000,
        after_limit_unvat_pay: 1,
        icon: 'https://test.backend.terminal.cyberia.studio/car_icons/truck.svg',
        is_demo: true,
    },
    {
        id: -6,
        name: 'Трал',
        type: 6,
        max_weight: 100000,
        max_volume: 600,
        max_pallets: 100,
        max_length: 20,
        max_width: 6,
        max_height: 5,
        max_distance: 1000,
        hour_pay: 450,
        before_limit_unvat_pay: 1000,
        after_limit_unvat_pay: 1,
        icon: 'https://test.backend.terminal.cyberia.studio/car_icons/tral.svg',
        is_demo: true,
    },
];
