import { Base } from '../../base';
import { OptionPriceTypeResponse } from './types';

export class Dictionary extends Base {
    protected basePath = 'dictionary';

    public optionPriceType = () => {
        this.set.path('optionPriceType');

        return this.request.get<OptionPriceTypeResponse>();
    };
}
