import env from '@fipnooone/react-env';

import Api from '@/shared/modules/api';

export class Service extends Api.Call {
    constructor(config?: Api.Types.Config) {
        super(config);

        if (config?.basePath) this.config.basePath = env('BACKEND_URL') + '/' + config?.basePath;
    }
}
