export interface Options {
    is_fragile: boolean;
    is_oversized: boolean;
    insurance: number | null;
    description: string;
}

export enum OptionSizeType {
    OVERSIZED = 1,
}
