/* eslint-disable no-debugger */
import { ModalControls } from '@cyberia-studio/react-modal';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Dialogs } from '@/components/partials';
import { api } from '@/services';
import { useFormContext } from '@/shared/modules/form/modules';
import { CalculateForm } from '@/types';

import { transformDataRegister } from '../../general';
import { useLtlStore } from '../store';
import { transformData } from '../transform';
import { validateOptions } from './validate';

export const useLtlRegister = (controls: ModalControls): [boolean, () => void] => {
    const { ifValid } = useFormContext<CalculateForm>();
    const [isLoading, setLoading] = useState(false);
    const { options } = useLtlStore();
    const [searchParams] = useSearchParams();
    const bx24_id = searchParams.get('bx24_id') || undefined;

    const register = () => {
        ifValid((validated) => {
            const mainData = transformData(validated, options);

            if (!mainData) return;

            const transformed = {
                ...mainData,
                ...transformDataRegister(validated),
                bx24_id,
            };

            if (!transformed) return;

            setLoading(true);

            api.requests()
                .ltl()
                .register(transformed)
                .onSuccess(({ message }) => {
                    setLoading(false);

                    controls.set(<Dialogs.OrderResult text={message} />);
                })
                .onErrors(() => {
                    setLoading(false);

                    controls.set(<Dialogs.OrderResult type="error" text="Повторите попытку позднее." />);
                });
        }, validateOptions).onErrors((errors) => {
            const inputName = Object.keys(errors).at(0);

            if (!inputName) return;

            const tab = document.getElementById('ltl');

            tab &&
                tab.querySelector(`[data-input-name="${inputName}"]`)?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
        });
    };

    return [isLoading, register];
};
