import React from 'react';
import { FC } from 'react';

import { forwardRef, memo } from '@/shared/lib/generic';

import { IconSVGProps } from '../types';

export type Props = IconSVGProps & {
    secondaryClassName?: string;
};

export const Icon = <T extends FC<P>, P extends Props>(component: T) => memo(forwardRef<SVGSVGElement, P>(component));

export const SVG = forwardRef<SVGSVGElement, IconSVGProps>(({ viewBox, ...props }, ref) => (
    <svg {...props} viewBox={viewBox ?? `0 0 ${props.width} ${props.height}`} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} />
));
