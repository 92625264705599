import React from 'react';

import { useForm } from '../useForm';
import { Returns } from '../useForm/types';
import { FormContextProps, FormContextProviderProps } from './types';

const FormReactContext = React.createContext<Returns<object> | null>(null);

export const FormContextProvider = <Data,>({ children, initialValue, options }: FormContextProviderProps<Data>) => {
    const form = useForm(initialValue, options);

    return <FormReactContext.Provider value={form}>{children}</FormReactContext.Provider>;
};

export const useFormContext = <Data,>() => {
    const form = React.useContext(FormReactContext) as Returns<Data> | null;

    if (!form) {
        throw new Error('FormContext must be used within a FormContextProvider');
    }

    return form;
};

export const FormContext = <Data,>({ children }: FormContextProps<Data>) => {
    const form = useFormContext<Data>();

    if (children instanceof Function) {
        return children(form);
    }

    return children;
};
