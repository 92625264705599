import cn from 'classnames';
import { forwardRef } from 'react';

import S from './styles.module.scss';
import { RowProps } from './types';

export const Row = forwardRef<HTMLDivElement, RowProps>(({ children, className, flex }, ref) => (
    <div ref={ref} className={cn(S.row, { [S.row_flex]: flex }, className)}>
        {children}
    </div>
));
